import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { forkJoin, Observable, of } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import {map} from 'rxjs/operators';
import { OzonExportTask, OzonNativeProduct, Product } from '@astrade/core';
import {OzonExportProductMessage} from '../../../../../../apps/functions/src/app/messages/ozon-export-product.message';
import {OzonCheckProductExportStatusMessage} from '../../../../../../apps/functions/src/app/messages/ozon-check-product-export-status.message';
import {OzonCheckProductExportStatusResult} from '../../../../../../apps/functions/src/app/services/ozon/ozon-check-product-export-status-result';

@Injectable({
  providedIn: 'root'
})
export class OzonExportProductService {

  private ozonExportProduct: (
    data: OzonExportProductMessage
  ) => Observable<string> = this.angularFireFunctions.httpsCallable('ozonExportProduct'); // JSON OzonExportTask

  private ozonExportCheckStatus: (
    data: OzonCheckProductExportStatusMessage
  ) => Observable<string> = this.angularFireFunctions.httpsCallable('ozonExportCheckStatus'); // JSON OzonCheckProductExportStatusResult

  constructor(
    private readonly angularFireFunctions: AngularFireFunctions,
    private readonly angularFirestore: AngularFirestore
  ) { }

  export(id: string): Observable<OzonExportTask> {
    return this.ozonExportProduct({
      id
    }).pipe(
      map(value => JSON.parse(value) as OzonExportTask)
    );
  }

  checkStatus(ozonTaskId: number): Observable<{ozonExportTask: OzonExportTask; ozonNativeProduct: OzonNativeProduct}> {
    return this.ozonExportCheckStatus({
      ozonTaskId
    }).pipe(
      map(value => JSON.parse(value) as OzonCheckProductExportStatusResult),
      map(value => {
        return {
          ozonExportTask: {
            ...value.ozonExportTask,
            statusTimestamp: new Date(value.ozonExportTask.statusTimestamp as unknown as string)
          },
          ozonNativeProduct: {
            ...value.ozonNativeProduct,
            createdAt: value?.ozonNativeProduct?.createdAt ? new Date(value.ozonNativeProduct.createdAt as unknown as string) : value?.ozonNativeProduct?.createdAt
          }
        }
      })
    );
  }

  getExportInfo(productId: string): Observable<{ozonExportTask: OzonExportTask; ozonNativeProduct: OzonNativeProduct}> {
    return this.angularFirestore.collection('gubka-products').doc(productId).get().pipe(
      map(document => {
        const product = document.data() as Product;
        return {
          ozonExportTask: product?.ozonExportTask,
          ozonNativeProduct: product?.ozonNativeProduct
        } as {ozonExportTask: OzonExportTask; ozonNativeProduct: OzonNativeProduct}
      })
    );
  }

  getAll(): Observable<{ozonExportTask: OzonExportTask; ozonNativeProduct: OzonNativeProduct}[]> {
    return this.angularFirestore.collection('gubka-products').get().pipe(
      map(querySnapshot => querySnapshot.docs?.map(doc => {
        const product = doc?.data() as Product;
        return {
          ozonExportTask: product.ozonExportTask,
          ozonNativeProduct: product.ozonNativeProduct
        } as {ozonExportTask: OzonExportTask; ozonNativeProduct: OzonNativeProduct}
      }))
    );
  }

  getByIds(ids: string[]): Observable<{ozonExportTask: OzonExportTask; ozonNativeProduct: OzonNativeProduct}[] | undefined> {
    if (!ids) {
      return of(undefined);
    }

    return forkJoin(ids.map(id => this.getExportInfo(id)))
  }
}
