import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'astrade-ozon-errors-cell-renderer',
  template: `
    <ul>
      <li *ngFor="let error of params.value">{{ error.level }}: {{error.code}}</li>
    </ul>
  `
})
export class OzonErrorsCellRendererComponent implements ICellRendererAngularComp {

  params: ICellRendererParams;

  constructor() { }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }
}
