import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { OzonAttribute } from '@astrade/core';

@Component({
  selector: 'astrade-ozon-product-property-field',
  template: `
    <ng-container *ngIf="!productProperty.dictionaryId else autocomplete">
      <ng-container [ngSwitch]="productProperty?.type | lowercase">
        <astrade-form-field-yes-no *ngSwitchCase="'boolean'" [label]="productProperty.name" [hint]="productProperty.description" [control]="control"></astrade-form-field-yes-no>
        <astrade-form-field-number *ngSwitchCase="'integer'" [label]="productProperty.name" [hint]="productProperty.description" [control]="control"></astrade-form-field-number>
        <astrade-form-field-number *ngSwitchCase="'decimal'" [label]="productProperty.name" [hint]="productProperty.description" [control]="control"></astrade-form-field-number>
        <astrade-form-field-text *ngSwitchCase="'string'" [label]="productProperty.name" [hint]="productProperty.description" [control]="control"></astrade-form-field-text>
        <astrade-form-field-textarea *ngSwitchCase="'multiline'" [label]="productProperty.name" [hint]="productProperty.description" [control]="control"></astrade-form-field-textarea>
        <astrade-form-field-text *ngSwitchCase="'imageurl'" [label]="productProperty.name" [hint]="productProperty.description" [control]="control"></astrade-form-field-text>
        <astrade-form-field-text *ngSwitchCase="'url'" [label]="productProperty.name" [hint]="productProperty.description" [control]="control"></astrade-form-field-text>
        <astrade-form-field-text *ngSwitchCase="'datetime'" [label]="productProperty.name" [hint]="productProperty.description" [control]="control"></astrade-form-field-text>
        <astrade-form-field-text *ngSwitchDefault [label]="productProperty.name" [hint]="productProperty.description" [control]="control"></astrade-form-field-text>
      </ng-container>
    </ng-container>
    <ng-template #autocomplete>
      <astrade-ozon-autocomplete-field [categoryId]="categoryId" [attributeId]="productProperty.id" [label]="productProperty.name" [hint]="productProperty.description" [control]="control"></astrade-ozon-autocomplete-field>
    </ng-template>
  `
})
export class OzonProductPropertyFieldComponent implements OnInit {

  @Input() control: FormControl;
  @Input() categoryId: number;
  @Input() productProperty: OzonAttribute;

  constructor() { }

  ngOnInit(): void {
  }

}
