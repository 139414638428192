import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { ProductPropertyMetadata } from '@astrade/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductPropertyService {

  constructor(private readonly firestore: AngularFirestore) { }

  getAll(): Observable<ProductPropertyMetadata[]> {
    return this.firestore.collection<ProductPropertyMetadata>('catalog-product-property').valueChanges();
  }

  get(id: string): Observable<ProductPropertyMetadata> {
    return this.firestore.collection<ProductPropertyMetadata>('catalog-product-property').doc<ProductPropertyMetadata>(id).get().pipe(
      map(doc => {
        return doc.data() as ProductPropertyMetadata;
      })
    );
  }
}
