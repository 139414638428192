import { OzonNativeProductErrorLevel } from './ozon-native-product-error-level';
import { OzonV2ProductInfoResponse_Error } from '@astrade/ozon-seller-api';

export class OzonNativeProductError {
  /**
   * Код ошибки Ozon.
   */
  code: string;

  /**
   * Код поля, в котором возникла ошибка.
   */
  field: string;

  /**
   * Тип ошибки:
   * - warning — товар создан, но есть ошибка в данных;
   * - error — товар не создан: проверьте данные и повторите запрос.
   */
  level: OzonNativeProductErrorLevel;

  public static fromOzonApi(error: OzonV2ProductInfoResponse_Error): OzonNativeProductError {
    return {
      code: error.code,
      field: error.field,
      level: error.level
    } as OzonNativeProductError;
  }
}
