import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {canActivate, redirectUnauthorizedTo} from '@angular/fire/auth-guard';
import {MainComponent} from './components/main/main.component';
import {DropshipProfileContainerComponent} from './components/dropship-profile-container/dropship-profile-container.component';

const routes: Routes = [
  { path: '', component: MainComponent, ...canActivate(() => redirectUnauthorizedTo(['login'])) },
  { path: 'login', loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule) },
  { path: 'profile', component: DropshipProfileContainerComponent, ...canActivate(() => redirectUnauthorizedTo(['login']))},
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {  }
