import { Injectable } from '@angular/core';
import { Category, CategoryWithProductProperties, ProductPropertyMetadata } from '@astrade/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { concatMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

/**
 * @deprecated replace with categories.service.
 */
@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private static productPropertiesCache: Map<string, ProductPropertyMetadata> = new Map<string, ProductPropertyMetadata>();

  constructor(private firestore: AngularFirestore) { }

  private async getProductProperty(id: string): Promise<ProductPropertyMetadata> {
    if (!CategoryService.productPropertiesCache.has(id)) {
      CategoryService.productPropertiesCache.set(id, await this.firestore.collection('catalog-product-property').doc(id).get().pipe(
        map(doc => doc.data() as ProductPropertyMetadata)
      ).toPromise());
    }

    return CategoryService.productPropertiesCache.get(id);
  }

  get(id: string): Observable<CategoryWithProductProperties> {
    return this.firestore.collection('gubka-catalog').doc(id).get().pipe(
      map(doc => {
        return doc.data() as Category;
      }),
      concatMap(async categoryInFirebase => {
        const promises: Promise<ProductPropertyMetadata>[] = [];
        for (const productPropertyId of categoryInFirebase.productPropertyIds ?? []) {
          promises.push(this.getProductProperty(productPropertyId));
        }

        const result: CategoryWithProductProperties = {
          ...categoryInFirebase,
          productProperties: await Promise.all(promises)
        };
        return result;
      })
    );
  }

  updateName(categoryId: string, name: string): Promise<void> {
    return this.firestore.collection('gubka-catalog').doc(categoryId).set({
      name
    }, {merge: true});
  }

  updateNameAndProductProperties(categoryId: string, name: string, productProperties: ProductPropertyMetadata[]): Promise<void> {
    return this.firestore.collection('gubka-catalog').doc(categoryId).set({
      name,
      productPropertyIds: productProperties?.map(productProperty => productProperty.id) ?? []
    }, {merge: true});
  }
}
