import { Injectable } from '@angular/core';
import { OzonDictionaryValue } from '@astrade/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/functions';
import {OzonDictionaryValuesSearchMessage} from '../../../../../../apps/functions/src/app/messages/ozon-dictionary-values-search.message';
import {OzonDictionaryValuesSearchResult} from '../../../../../core/src/lib/ozon/ozon-dictionary-values-search-result';

@Injectable({
  providedIn: 'root'
})
export class OzonDictionarySearchService {

  constructor(
    private readonly httpClient: HttpClient,
    private readonly angularFireFunctions: AngularFireFunctions
  ) { }

  private ozonDictionaryValuesSearch: (
    data: OzonDictionaryValuesSearchMessage
  ) => Observable<string> // JSON OzonDictionaryValuesSearchResult
    = this.angularFireFunctions.httpsCallable('ozonDictionaryValuesSearch');

  search(categoryId: number, attributeId: number, value: string): Observable<OzonDictionaryValue[]> {
    console.log('Search', categoryId, attributeId, value);
    return this.ozonDictionaryValuesSearch({
      categoryId: categoryId,
      attributeId: attributeId,
      limit: 99,
      search: value
    })
    .pipe(
      map(result => JSON.parse(result) as OzonDictionaryValuesSearchResult),
      map(result => {
        if (result.data) {
          const data = JSON.parse(result.data);
          if (data?.error) {
            console.log('Error:', data.error);
            return [];
          }

          const foundValues = data?.result as {id: number; value: string; external_id: number; dictionary_key: string; info: string; picture: string;}[] ?? [];
          return foundValues.map(foundValue => {
            return {
              id: foundValue.id,
              value: foundValue.value,
              info: foundValue.info,
              picture: foundValue.picture
            } as OzonDictionaryValue;
          })
        }
        return [];
      })
    )
  }
}
