import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {UiModule} from '@astrade/ui';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../../../console/src/environments/environment';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {ReactiveFormsModule} from '@angular/forms';
import {AgGridModule} from 'ag-grid-angular';
import {REGION} from '@angular/fire/functions';
import {DropshipSideMenuComponent} from './components/dropship-side-menu/dropship-side-menu.component';
import {MainComponent} from './components/main/main.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import { DropshipProfileComponent } from './components/dropship-profile/dropship-profile.component';
import { DropshipProfileContainerComponent } from './components/dropship-profile-container/dropship-profile-container.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    PageNotFoundComponent,
    DropshipSideMenuComponent,
    DropshipProfileComponent,
    DropshipProfileContainerComponent
  ],
  imports: [
    UiModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence({ synchronizeTabs: true }),
    ReactiveFormsModule,
    AgGridModule
  ],
  providers: [
    { provide: REGION, useValue: 'europe-west1'}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
