import { createAction, props } from '@ngrx/store';
import { Product } from '@astrade/core';

export const getAll = createAction('[Products] Get All');

export const getAllSuccess = createAction(
  '[Products] Get All Success',
  props<{products: Product[]}>()
);

export const getAllError = createAction('[Products] Get All Error');
