<div class="bg-purple">
<div class="stars">
  <div class="custom-navbar">
    <div class="brand-logo">
    </div>
    <div class="navbar-links">
      <ul>
        <li></li>
      </ul>
    </div>
  </div>
  <div class="central-body">
    <img class="image-404" src="/assets/404/404.svg" width="300px">
    <a href="/" class="btn-go-home">GO BACK HOME</a>
  </div>
  <div class="objects">
    <img class="object_rocket" src="/assets/404/rocket.svg" width="40px">
    <div class="earth-moon">
      <img class="object_earth" src="/assets/404/earth.svg" width="100px">
      <img class="object_moon" src="/assets/404/moon.svg" width="80px">
    </div>
    <div class="box_astronaut">
      <img class="object_astronaut" src="/assets/404/astronaut.svg" width="140px">
    </div>
  </div>
  <div class="glowing_stars">
    <div class="star"></div>
    <div class="star"></div>
    <div class="star"></div>
    <div class="star"></div>
    <div class="star"></div>
  </div>
</div>
</div>
