import { Injectable } from '@angular/core';
import { OzonCategory, OzonCategoryTree } from '@astrade/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, switchMap, tap } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';

@Injectable({
  providedIn: 'root'
})
export class OzonCategoryService {
  private categoryTree$ = new BehaviorSubject<OzonCategoryTree[]>(undefined);
  private categoriesFlat$ = new BehaviorSubject<OzonCategoryTree[]>(undefined);

  constructor(
    private readonly firestore: AngularFirestore,
    private readonly localStorage: StorageMap
  ) { }

  /**
   * Returns one Ozon category by ID as observable.
   * @param id
   */
  get(id: number): Observable<OzonCategory> {
    return this.firestore.collection('ozon-category-tree').doc(id.toString()).get().pipe(
      map(doc => {
        return doc.data() as OzonCategory
      })
    );
  }

  private getCategoriesFromServer(): Observable<OzonCategory[]> {
    return this.firestore.collection<OzonCategory>('ozon-category-tree')
    .get()
    .pipe(
      map(querySnapshot => querySnapshot.docs.map(document => document.data() as OzonCategory))
    );
  }

  private getCategoriesFromCache(): Observable<OzonCategory[]> {
    return this.localStorage.get('ozon-category-tree') as Observable<OzonCategory[]>;
  }

  private saveCategoriesToCache(categories: OzonCategory[]): void {
    this.localStorage.set('ozon-category-tree', categories).subscribe();
  }

  /**
   *
   * @param mode
   */
  getCategories(mode: 'tree' | 'flat' = 'flat'): Observable<OzonCategoryTree[]> {
    return new Observable<OzonCategoryTree[]>(observer => {
      if (this.categoriesFlat$.getValue() === undefined) {
        // Try to load from cache
        this.getCategoriesFromCache().pipe(
          switchMap((value: OzonCategory[] | undefined) => {
            // If cache is empty then load from server
            if (value === undefined) {
              return this.getCategoriesFromServer().pipe(
                tap(categories =>  this.saveCategoriesToCache(categories))
              );
            }

            return of(value);
          })
        )
        .pipe(
          map((categories: OzonCategory[]) => {
            const categoriesMap: {[key: number]: OzonCategoryTree} = {};
            categories.forEach(category => categoriesMap[category.id] = category);
            return categoriesMap;
          })
        ).subscribe(categoriesMap => {
          const resultTree: OzonCategoryTree[] = [];
          const resultFlat: OzonCategoryTree[] = [];
          Object.values(categoriesMap).forEach(category => {
            if (!category.parentId) {
              resultTree.push(this.fillCategory(undefined, category, categoriesMap));
            }

            resultFlat.push(category);
          });

          this.categoryTree$.next(resultTree);
          this.categoriesFlat$.next(resultFlat);
        });
      }

      return mode === 'tree' ? this.categoryTree$.subscribe(observer) : this.categoriesFlat$.subscribe(observer);
    });
  }

  private fillCategory(
    parent: OzonCategoryTree | undefined,
    category: OzonCategoryTree,
    categoriesMap: Record<string, OzonCategoryTree>
  ): OzonCategoryTree {
    if (parent) {
      category.parent = parent;
    }

    if (category.childrenIds) {
      category.children = [];
      category.childrenIds?.forEach(value => {
        const child = categoriesMap[value];
        category.children.push(child);
        this.fillCategory(category, child, categoriesMap);
      });
    }

    return category;
  }
}
