<div fxLayout="column" fxFlexFill>
  <mat-toolbar>
    <button type="button" mat-button (click)="onAddButtonClick()">
      <mat-icon>add</mat-icon>
    </button>
    <button type="button" mat-button (click)="onDeleteButtonClick()">
      <mat-icon>delete</mat-icon>
    </button>
  </mat-toolbar>
  <div fxFlex>
    <ag-grid-angular
      rowSelection="single"
      style="height: 100%"
      class="ag-theme-alpine"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [columnTypes]="columnTypes"
      [rowData]="rivalPrices$ | async"
      [getRowNodeId]="getRowNodeId"
      (gridReady)="onGridReady($event)"
      (rowDoubleClicked)="onRowDoubleClicked($event)"
      editType="fullRow"
      immutableData="true"
      suppressMaintainUnsortedOrder="true"
      suppressDragLeaveHidesColumns="true"
    >
    </ag-grid-angular>
  </div>
</div>
