import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FirebaseUser, IdTokenResult } from '@astrade/core';

@Component({
  selector: 'astrade-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  @Input() user: FirebaseUser;
  @Input() idTokenResult: IdTokenResult;

  @Output() loginClick = new EventEmitter<void>();
  @Output() logoutClick = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }
}
