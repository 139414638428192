import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GubkaCategoryFormGroup } from '../../form/controls/product-main-properties-form-group/gubka-category-form-group/gubka-category-form-group';

@Component({
  selector: 'astrade-category-form-field',
  template: `
    <mat-form-field *ngIf="categoryFormGroup" fxFlex>
      <mat-label>Категория Gubka</mat-label>
      <input type="hidden" [formControl]="categoryFormGroup.controls.id"/>
      <input matInput type="text" [formControl]="categoryFormGroup.controls.name" readonly/>
      <button type="button" [disabled]="!categoryFormGroup.controls.name.value" mat-icon-button matSuffix (click)="reset()"><mat-icon>cancel</mat-icon></button>
      <button type="button" mat-icon-button matSuffix (click)="openDialogClick.emit()"><mat-icon>open_in_new</mat-icon></button>
    </mat-form-field>
  `
})
export class CategoryFormFieldComponent implements OnInit {
  @Input() categoryFormGroup: GubkaCategoryFormGroup;

  @Output() openDialogClick = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  reset(): void {
    if (this.categoryFormGroup.controls.id.value !== '') {
      this.categoryFormGroup.controls.id.setValue('');
    }

    if (this.categoryFormGroup.controls.name.value !== '') {
      this.categoryFormGroup.controls.name.setValue('');
    }
  }
}
