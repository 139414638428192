import { OzonNativeProductSaleSchema } from './ozon-native-product-sale-schema';
import { OzonV2ProductInfoResponse_Result_Commission } from '@astrade/ozon-seller-api';

/**
 * Описание комиссий Озон для схемы продажи товаров.
 * Полная комиссия = amount + deliveryAmount + returnAmount (если был возврат).
 */
export class OzonNativeProductCommission {
  /**
   * Комиссия за продажу в %.
   * Процент от цены товара.
   */
  percent: number;

  /**
   * Минимальная сумма комиссии за продажу.
   * Если сумма комиссии, рассчитанная через % меньше, то применяется минимальная комиссия.
   */
  minAmount: number;

  /**
   * Рассчитанная конечная сумма комиссии за продажу.
   */
  amount: number;

  /**
   * Схема продажи товара на Ozon.
   */
  saleSchema: OzonNativeProductSaleSchema;

  /**
   * Сумма комиссии за доставку.
   */
  deliveryAmount: number;

  /**
   * Сумма комиссии за возврат.
   */
  returnAmount: number;

  public static fromOzonApi(commission: OzonV2ProductInfoResponse_Result_Commission): OzonNativeProductCommission {
    return {
      amount: commission.value,
      deliveryAmount: commission.delivery_amount,
      minAmount: commission.min_value,
      percent: commission.percent,
      returnAmount: commission.return_amount,
      saleSchema: commission.sale_schema
    } as OzonNativeProductCommission;
  }
}
