import {Component, Input} from '@angular/core';
import { FormFieldAbstractComponent } from '../form-field-abstract/form-field-abstract.component';

@Component({
  selector: 'astrade-form-field-number',
  template: `
    <mat-form-field fxFlex>
      <mat-label>{{ label }}</mat-label>
      <input *ngIf="onlyPositiveInteger" matInput type="number" min="0" step="1" pattern="[0-9]*"
             oninput="this.value = Math.ceil(this.value) || 0" [formControl]="control"/>
      <input *ngIf="!onlyPositiveInteger" matInput type="number" [formControl]="control"/>
      <button type="button" *ngIf="hint" [matTooltip]="hint" matTooltipClass="hint" mat-icon-button matSuffix>
        <mat-icon>help</mat-icon>
      </button>
    </mat-form-field>
  `
})
export class FormFieldNumberComponent extends FormFieldAbstractComponent {
  /**
   * Allow only integer numbers.
   */
  @Input() onlyPositiveInteger: boolean;
}
