import { Component, OnInit } from '@angular/core';
import { ProductPropertyService } from '../../../../../../apps/console/src/app/services/product-property.service';
import { CategoryService } from '../../../../../../apps/console/src/app/services/category.service';
import { ActivatedRoute } from '@angular/router';
import { CategoryWithProductProperties, ProductPropertyMetadata } from '@astrade/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'astrade-category-edit',
  templateUrl: './category-edit.component.html',
  styleUrls: ['./category-edit.component.scss']
})
export class CategoryEditComponent implements OnInit {

  category: CategoryWithProductProperties;

  availableProductPropertiesArray: ProductPropertyMetadata[];

  constructor(
    private readonly categoryService: CategoryService,
    private readonly productPropertyService: ProductPropertyService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly snackBar: MatSnackBar
  ) {  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(parameters => {
      const categoryId = parameters.categoryId;
      if (categoryId) {
        this.categoryService.get(categoryId).subscribe(category => {
          this.category = category;
        });
      }
    })

    this.productPropertyService.getAll().subscribe(value => {
      this.availableProductPropertiesArray = value;
    })
  }

  onSave(data: {id: string; name: string; productProperties: ProductPropertyMetadata[]}): void {
    this.categoryService.updateNameAndProductProperties(data.id, data.name, data.productProperties)
      .then(value => {
        this.snackBar.open(`Категория '${data?.name}' сохранена.`, '', {
          duration: 5000
        });
      })
      .catch(reason => {
        console.error(reason);
        this.snackBar.open(`Не удалось сохранить категорию '${data?.name}'. Ошибка: ${reason.toString()}.`, 'Закрыть')
      });
  }
}
