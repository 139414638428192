import { Injectable } from '@angular/core';
import {DictionaryValue} from 'commerceml-parser-core';
import {Observable} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFirestoreCollection} from '@angular/fire/firestore/collection/collection';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OneSDictionaryService {
  protected collectionName = 'astrade-1c-classifier';

  constructor(
    private readonly firestore: AngularFirestore
  ) { }

  getAllValues(classifierId: string, propertyId: string): Observable<DictionaryValue[]> {
    return this.collection(classifierId, propertyId).valueChanges();
  }

  search(classifierId: string, propertyId: string, searchTerm: string): Observable<DictionaryValue[]> {
    return this.getAllValues(classifierId, propertyId)
      .pipe(
        map(value => value.filter(
          dictionaryValue => dictionaryValue.value?.toLowerCase().includes(searchTerm?.toLowerCase())
        ))
      )
  }

  protected collection(classifierId: string, propertyId: string): AngularFirestoreCollection<DictionaryValue> {
    return this.firestore.collection(this.collectionName)
      .doc(classifierId)
      .collection('properties')
      .doc(propertyId)
      .collection<DictionaryValue>('dictionaryValues');
  }
}
