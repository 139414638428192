import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { ProductPropertyMetadata } from '@astrade/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdditionalPropertiesService {

  constructor(private firestore: AngularFirestore) { }

  getAll(): Observable<ProductPropertyMetadata[]> {
    return this.firestore.collection<ProductPropertyMetadata>('catalog-product-property', ref => ref.where(
      'catalogSectionId', '==', '772c4691-e5e8-4433-8121-94644328081a'
    ))
    .get()
    .pipe(
      map(value => value.docs.map(doc => doc.data() as unknown as ProductPropertyMetadata))
    );
  }
}
