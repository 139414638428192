import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { OzonCategory, OzonCategoryTree } from '@astrade/core';
import { OzonCategorySelectDialogComponent } from '../ozon-category-select-dialog/ozon-category-select-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { OzonCategoryFormGroup } from '../../form/controls/product-main-properties-form-group/ozon-category-form-group/ozon-category-form-group';
import { OzonCategoryService } from '../ozon-category.service';

@Component({
  selector: 'astrade-ozon-category-form-field-with-dialog',
  template: `
    <astrade-ozon-category-form-field
      [categoryFormGroup]="categoryFormGroup"
      (openDialogClick)="openDialog()"
    ></astrade-ozon-category-form-field>
  `
})
export class OzonCategoryFormFieldWithDialogComponent implements OnInit {

  @Input() categoryFormGroup: OzonCategoryFormGroup;
  @Input() categories$: Observable<OzonCategoryTree[]>;

  constructor(
    private readonly ozonCategoryService: OzonCategoryService,
    private readonly dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.categories$ = this.ozonCategoryService.getCategories('flat');
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(OzonCategorySelectDialogComponent, {
      width: '100%',
      height: 'auto',
      data: {
        categories$: this.categories$,
        categoryId: this.categoryFormGroup.controls.id.value
      }
    });

    dialogRef.afterClosed().subscribe((result: Partial<OzonCategory> | undefined) => {
      if (!result) {
        return;
      }

      const newCategoryId = result.id ?? '';
      if (this.categoryFormGroup.controls.id.value !== newCategoryId) {
        this.categoryFormGroup.controls.id.setValue(newCategoryId);
      }

      const newCategoryTitle = result.title ?? '';
      if (this.categoryFormGroup.controls.title.value !== newCategoryTitle) {
        this.categoryFormGroup.controls.title.setValue(newCategoryTitle);
      }
    });
  }
}
