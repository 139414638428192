import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RivalPrice } from '@astrade/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RivalPriceService } from '../../../../../services/rival-price.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateTime } from 'luxon';

@Component({
  selector: 'astrade-price-edit-dialog',
  template: `
    <mat-dialog-content style="height: 100vh">
      <astrade-form-field-text
        label="Название"
        [control]="form.controls.name"
      ></astrade-form-field-text>
      <astrade-form-field-text
        label="URL"
        [control]="form.controls.url"
      ></astrade-form-field-text>
      <astrade-form-field-number
        label="Цена"
        [control]="form.controls.price"
      ></astrade-form-field-number>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button type="button" mat-button (click)="onCancel()">Отмена</button>
      <button type="button" mat-button cdkFocusInitial (click)="onSave()">Сохранить</button>
    </mat-dialog-actions>
  `
})
export class PriceEditDialogComponent implements OnInit {

  form: FormGroup = new FormGroup({
    name: new FormControl(''),
    url: new FormControl(''),
    price: new FormControl('')
  });

  initialRivalPrice?: RivalPrice;

  constructor(
    private readonly dialogRef: MatDialogRef<PriceEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) rivalPrice: RivalPrice,
    private readonly rivalPriceService: RivalPriceService,
    private readonly snackBar: MatSnackBar
  ) {
    this.initialRivalPrice = rivalPrice;
    this.form.setValue({
      name: rivalPrice.name,
      url: rivalPrice.url,
      price: rivalPrice.price
    });
  }

  ngOnInit(): void {
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    const priceUpdated = this.initialRivalPrice.price !== this.form.value.price;
    const result: RivalPrice = {
      ...this.initialRivalPrice,
      ...this.form.value,
      updateTimestamp: (priceUpdated || !this.initialRivalPrice.updateTimestamp) ? DateTime.utc().toJSDate() : this.initialRivalPrice.updateTimestamp
    };

    this.rivalPriceService.saveRivalPrice(result)
      .then(() => {
        this.snackBar.open('Значение сохранено', '', {
          duration: 5000
        });
        this.dialogRef.close(result);
      })
      .catch(reason => {
        console.error('Save dictionary value', reason);
        this.snackBar.open('Ошибочка вышла... ' + reason?.toString() ?? '', 'Закрыть');
      });
  }
}
