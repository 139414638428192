import { Component } from '@angular/core';
import { FormFieldAbstractComponent } from '../form-field-abstract/form-field-abstract.component';

@Component({
  selector: 'astrade-form-field-text',
  template: `
    <mat-form-field fxFlex>
      <mat-label>{{ label }}</mat-label>
      <input matInput type="text" [formControl]="control"/>
      <button type="button" *ngIf="hint" [matTooltip]="hint" matTooltipClass="hint" mat-icon-button matSuffix><mat-icon>help</mat-icon></button>
    </mat-form-field>
  `
})
export class FormFieldTextComponent extends FormFieldAbstractComponent {

}
