import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireFunctions } from '@angular/fire/functions';
import { finalize } from 'rxjs/operators';
import {v4 as uuidv4} from 'uuid';

export interface ImageUploadProgress {
  status: 'uploading' | 'resizing' | 'done' | 'error';
  errorMessage?: string;
  uploadPercent?: Observable<number>;
  originalFilename?: string;
  mediumFilename?: string;
  smallFilename?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor(
    private readonly angularFireStorage: AngularFireStorage,
    private readonly angularFireFunctions: AngularFireFunctions
  ) { }

  private resizeUploadedPhoto: (data: {
    filename: string
  }) => Observable<any> = this.angularFireFunctions.httpsCallable('resizeUploadedPhoto');

  upload(file: File): Observable<ImageUploadProgress> {
    return new Observable<ImageUploadProgress>(subscriber => {
      // Workaround to change the bucket https://github.com/angular/angularfire/issues/1996
      (this.angularFireStorage as any).storage = this.angularFireStorage.storage.app.storage('gubka-photos');

      const destinationPath = uuidv4() + '.' + file.name.split('.').pop();
      const task = this.angularFireStorage.upload(destinationPath, file);
      subscriber.next({
        status: 'uploading',
        uploadPercent: task.percentageChanges(),
        originalFilename: destinationPath
      });

      task.snapshotChanges().pipe(
        finalize(() => {
          subscriber.next({
            status: 'resizing',
            originalFilename: destinationPath
          });

          this.resizeUploadedPhoto({
            filename: destinationPath
          }).subscribe(value => {
            console.log(value);
            subscriber.next({
              status: 'done',
              originalFilename: destinationPath,
              mediumFilename: value['560x560'],
              smallFilename: value['250x250']
            });
            subscriber.complete();
          });
        })
      ).subscribe(
        value => {},
        error => {
          subscriber.next({
            status: 'error',
            errorMessage: error.message
          });
          subscriber.complete();
        }
      );
    });
  }
}
