import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { OzonNativeProductCommission, Product } from '@astrade/core';
import { map, switchMap, tap } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';
import { RivalPriceService } from '../../../services/rival-price.service';
import { PriceManagerService } from './price-manager.service';
import { ProductPriceManagerPropertiesFormGroup } from '../../controls/product-price-manager-properties-form-group';
import { PriceTypeForOzon } from '@astrade/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'astrade-price-management',
  templateUrl: './price-management.component.html',
  styleUrls: ['./price-management.component.scss']
})
export class PriceManagementComponent implements OnInit, OnDestroy {

  @Input() priceManagerPropertiesFormGroup: ProductPriceManagerPropertiesFormGroup;
  @Input() product$: Observable<Product>;

  @Output() emitStatus = new EventEmitter<'ozonPriceIsUpdating' | 'ozonPriceUpdateDone'>();

  productId: string;

  wholesalePriceFormControl = new FormControl('', Validators.required);
  gubkaPriceFormControl = new FormControl('');
  ozonPriceFormControl = new FormControl('');
  ourMinPriceFormControl = new FormControl('');
  parserMinPriceFormControl = new FormControl('');
  selectedPriceTypeForOzonFormControl = new FormControl('', Validators.required);
  vatFormControl = new FormControl('', Validators.required);

  componentState$: Observable<{
    wholesalePrice?: number;
    gubkaPrice?: number;
    fboStocks?: number;
    fboCommissions?: OzonNativeProductCommission;
    priceIndex?: number;
    recommendedOzonPrice?: number;
    ozonPrice?: number;
    ourMinPrice?: number;
    parserMinPrice?: number;
    selectedPriceTypeForOzon?: PriceTypeForOzon;
    vat?: number;
  }> = undefined;

  updatingOzonPrice: boolean = false;

  private subscription: Subscription;

  constructor(
    private readonly priceManagerService: PriceManagerService,
    private readonly rivalPriceService: RivalPriceService,
    private readonly snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.priceManagerService.getAllProperties().subscribe(properties => {
      this.priceManagerPropertiesFormGroup.generateControlsFromPriceManagerPropertyValues(properties);
      this.wholesalePriceFormControl = this.priceManagerPropertiesFormGroup.get('93eca26f-a872-493d-9edc-6fa0a8aa3539') as FormControl;
      this.gubkaPriceFormControl = this.priceManagerPropertiesFormGroup.get('b301306a-4568-4da2-b74a-0f7d090fffbc') as FormControl;
      this.ozonPriceFormControl = this.priceManagerPropertiesFormGroup.get('8635be5a-7f6e-42e8-b782-d94ce01b63ce') as FormControl;
      this.ourMinPriceFormControl = this.priceManagerPropertiesFormGroup.get('1c50d7e2-b53b-4f4e-bacb-ebfae8820462') as FormControl;
      this.selectedPriceTypeForOzonFormControl = this.priceManagerPropertiesFormGroup.get('6ce050b6-7e7c-4948-9be2-7ca9016b8669') as FormControl;
      this.vatFormControl = this.priceManagerPropertiesFormGroup.get('a358b842-8930-42b4-b1a3-253b7667ab9e') as FormControl;
    });

    this.componentState$ = this.product$.pipe(
      tap(product => this.productId = product?.id),
      switchMap(product => {
        return this.rivalPriceService.getMinRivalPriceByProductId(product?.id).pipe(
          map(minPrice => {
            return {
              wholesalePrice: product?.properties['93eca26f-a872-493d-9edc-6fa0a8aa3539']?.value as number,
              gubkaPrice: product?.properties['b301306a-4568-4da2-b74a-0f7d090fffbc']?.value as number,
              fboStocks: product?.ozonNativeProduct?.stocks?.present ?? 0,
              fboCommissions: product?.ozonNativeProduct?.commissions?.find(commission => commission.saleSchema === 'fbo'),
              priceIndex: product?.ozonNativeProduct?.priceIndex,
              recommendedOzonPrice: product?.ozonNativeProduct?.recommendedPrice,
              ozonPrice: product?.properties['8635be5a-7f6e-42e8-b782-d94ce01b63ce']?.value as number,
              ourMinPrice: product?.properties['1c50d7e2-b53b-4f4e-bacb-ebfae8820462']?.value as number,
              parserMinPrice: minPrice,
              selectedPriceTypeForOzon: product?.properties['6ce050b6-7e7c-4948-9be2-7ca9016b8669']?.value as PriceTypeForOzon,
              vat: product?.properties['a358b842-8930-42b4-b1a3-253b7667ab9e']?.value as number
            }
          })
        )
      })
    );

    this.subscription = this.componentState$.subscribe(componentState => {
      this.wholesalePriceFormControl.setValue(componentState.wholesalePrice ?? '');
      this.gubkaPriceFormControl.setValue(componentState.gubkaPrice ?? '');
      this.ozonPriceFormControl.setValue(componentState.ozonPrice ?? '');
      this.ourMinPriceFormControl.setValue(componentState.ourMinPrice ?? '');
      this.parserMinPriceFormControl.setValue(componentState.parserMinPrice ?? '');
      this.selectedPriceTypeForOzonFormControl.setValue(componentState.selectedPriceTypeForOzon ?? 'manual');
      this.vatFormControl.setValue(componentState.vat ?? '');
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  getProductId(): Observable<string> {
    return this.product$?.pipe(map(product => product?.id));
  }

  getActualSellPrice(): number {
    switch (this.selectedPriceTypeForOzonFormControl.value as PriceTypeForOzon) {
      case 'manual':
      default:
        return this.ozonPriceFormControl.value ? this.ozonPriceFormControl.value : 0;

      case 'ourMin':
        return this.ourMinPriceFormControl.value ? this.ourMinPriceFormControl.value : 0;

      case 'parserMin':
        return this.parserMinPriceFormControl.value ? this.parserMinPriceFormControl.value : 0;
    }
  }

  async onOzonUpdatePriceButtonClick(): Promise<void> {
    this.updatingOzonPrice = true;
    this.emitStatus.emit('ozonPriceIsUpdating');
    this.priceManagerService.updateOzonPrice(this.productId).subscribe(result => {
      this.updatingOzonPrice = false;
      this.emitStatus.emit('ozonPriceUpdateDone');

      if (!result.updated) {
        console.log('Price update error', JSON.stringify(result.errors));
        const message = result.errors?.reduce<string>((previousValue, currentValue) => {
          if (previousValue !== '') {
            previousValue += ', ';
          }

          return previousValue + `${currentValue.code} - ${currentValue.message}`;
        }, '');

        this.snackBar.open('Ошибочка вышла... ' + message ?? '', 'Закрыть');
      }

      console.log('Price updated.');
      this.snackBar.open('Цена Ozon обновлена.', '', {
        duration: 5000
      });
    }, reason => {
      this.updatingOzonPrice = false;
      this.emitStatus.emit('ozonPriceUpdateDone');
      this.snackBar.open('Ошибочка вышла... ' + reason?.toString() ?? '', 'Закрыть');
    });
  }
}
