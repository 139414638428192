import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'astrade-ozon-dictionary-cell-renderer',
  template: `
    {{ params.value?.value }}
  `
})
export class OzonDictionaryCellRendererComponent implements ICellRendererAngularComp {

  params: ICellRendererParams;

  constructor() { }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }
}
