import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'astrade-yes-no-cell-renderer',
  template: `
    {{ params.value | yesNo}}
  `
})
export class YesNoCellRendererComponent implements ICellRendererAngularComp {

  params: ICellRendererParams;

  constructor() { }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }
}
