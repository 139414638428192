/**
 * Generic core error codes.
 */
export const enum CoreErrorCode {
  ADMIN_ONLY_OPERATION = 'admin-restricted-operation',
  /**
   * customData: {
   *   argument: string; // Argument name.
   *   message: string;  // Additional message.
   * }
   */
  ARGUMENT_ERROR = 'argument-error',
  /**
   * customData: {
   *   message: string; // Additional message.
   * }
   */
  INTERNAL_ERROR = 'internal-error',
  INVALID_MESSAGE_PAYLOAD = 'invalid-message-payload',
  NETWORK_REQUEST_FAILED = 'network-request-failed',
  OPERATION_NOT_ALLOWED = 'operation-not-allowed',
  OPERATION_NOT_SUPPORTED = 'operation-not-supported-in-this-environment',
  TIMEOUT = 'timeout',
}

/**
 * Error map to define messages for corresponding error codes.
 */
export type ErrorMessagesMap<ERROR_CODE extends string> = {
  readonly [K in ERROR_CODE]: string;
};

/**
 * Additional error data to be used in the message template.
 * Template placeholders are replaced with this data values.
 */
export interface CustomErrorData extends Record<string, unknown> {}

/**
 * Core error class.
 *
 * Based on code from:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#Custom_Error_Types
 */
export class CoreError extends Error {
  readonly name = 'CoreError';

  /**
   * @param code
   * @param message
   * @param customData
   */
  constructor(
    readonly code: string,
    message: string,
    customData?: CustomErrorData
  ) {
    super(message);

    // Fix For ES5
    // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, CoreError.prototype);

    // Maintains proper stack trace for where our error was thrown.
    // Only available on V8.
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorFactory.prototype.create);
    }
  }
}

export class ErrorFactory {
  constructor(
    private readonly service: string,
    private readonly serviceName: string,
    private readonly errorMessagesMap: ErrorMessagesMap<CoreErrorCode>
  ) {}

  create(
    code: CoreErrorCode,
    customData: CustomErrorData
  ): CoreError {
    const fullCode = `${this.service}/${code}`;
    const template = this.errorMessagesMap[code];
    const message = template ? replaceTemplate(template, customData) : 'Error';

    return new CoreError(
      fullCode,
      `${this.serviceName}: ${message} (${fullCode}).`,
      customData
    )
  }
}

export const _DEFAULT_CORE_ERROR_FACTORY = new ErrorFactory(
  'core',
  'Core',
  {
    [CoreErrorCode.ARGUMENT_ERROR]: 'Illegal argument {$argument}: {$message}',
    [CoreErrorCode.INTERNAL_ERROR]: 'Internal Error: {$message}'
  } as ErrorMessagesMap<CoreErrorCode>
);

function replaceTemplate(template: string, customData: CustomErrorData): string {
  return template.replace(/\{\$([^}]+)}/g, (_, placeholder) => {
    const value = (customData && customData[placeholder]) ?? `<${placeholder}?>`;
    return String(value);
  });
}
