/**
 * Format for luxon DateTime to serialize timestamp to JSON string.
 * For parsing use DateTime.fromISO('2021-02-15T16:51:01', {zone: 'local'})
 * Example: 2021-02-15T16:51:01
 */
export const JSON_DATETIME_FORMAT = 'yyyy-MM-dd\'T\'HH:mm:ss';

/**
 * Format for luxon DateTime to serialize timestamp with timezone to JSON string.
 * For parsing use DateTime.fromISO('2021-02-15T16:51:01+03:00', {setZone: true})
 * Example: 2021-02-15T16:51:01+03:00
 */
export const JSON_DATETIME_WITH_TIMEZONE_FORMAT = 'yyyy-MM-dd\'T\'HH:mm:ssZZ'
