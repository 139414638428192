<h2>Список пользователей</h2>

<mat-form-field>
  <mat-label>Поиск</mat-label>
  <input matInput type="text" [formControl]="searchQuery"/>
</mat-form-field>

<ag-grid-angular
  rowSelection="single"
  style="width: 1500px; height: 500px;"
  class="ag-theme-alpine"
  [columnDefs]="columnDefs"
  [defaultColDef]="defaultColDef"
  [columnTypes]="columnTypes"
  [rowData]="users"
  (gridReady)="onGridReady($event)"
  [getRowNodeId]="getRowNodeId"
  [quickFilterText]="searchQuery.valueChanges | async"
  editType="fullRow"
  (rowValueChanged)="onRowValueChanged($event)"
  immutableData="true"
  suppressMaintainUnsortedOrder="true"
  suppressDragLeaveHidesColumns="true"
></ag-grid-angular>
