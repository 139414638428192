import { OzonNativeProductSourceType } from './ozon-native-product-source-type';
import { OzonV2ProductInfoResponse_Source } from '@astrade/ozon-seller-api';

/**
 * Информация о SKU Ozon.
 */
export class OzonNativeProductSource {
  /**
   * Номер SKU Озон.
   */
  sku: number;

  /**
   * Тип источника продажи SKU.
   */
  type: OzonNativeProductSourceType;

  /**
   * Индикатор: Включен или нет.
   */
  enabled: boolean;

  public static fromOzonApi(source: OzonV2ProductInfoResponse_Source): OzonNativeProductSource {
    return {
      enabled: source.is_enabled,
      sku: source.sku,
      type: source.source
    } as OzonNativeProductSource;
  }
}
