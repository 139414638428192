<h1>Форма редактирования категории</h1>
<form [formGroup]="form">
  <input type="hidden" formControlName="id">
  <mat-form-field>
    <mat-label>Название</mat-label>
    <input matInput type="text" formControlName="name">
  </mat-form-field>
  <button type="button" mat-button (click)="onSave()">Сохранить</button>
</form>

<div class="example-container">
  <h2>Выбранные свойства</h2>
  <div cdkDropList #selectedProductProperties="cdkDropList"
       [cdkDropListData]="selectedProductPropertiesArray"
       [cdkDropListConnectedTo]="[availableProductProperties]"
       class="example-list"
       (cdkDropListDropped)="drop($event)">
    <div class="example-box" *ngFor="let property of selectedProductPropertiesArray" cdkDrag>{{ property?.name }}</div>
  </div>
</div>

<div class="example-container">
  <h2>Доступные свойства</h2>
  <div cdkDropList #availableProductProperties="cdkDropList"
       [cdkDropListData]="availableProductPropertiesArray"
       [cdkDropListConnectedTo]="[selectedProductProperties]"
       class="example-list"
       (cdkDropListDropped)="drop($event)">
    <div class="example-box" *ngFor="let property of availableProductPropertiesArray" cdkDrag>{{ property?.name }}</div>
  </div>
</div>
