import { ParserOzonProductExtractedInfo } from '@astrade/core';

/**
 * Set of static methods for parsing Ozon pages and API responses.
 */
export class ParserOzon {
  /**
   * Parses Ozon product page content.
   * @param content
   */
  public static parseProductPage(content: string): ParserOzonProductExtractedInfo {
    let result: ParserOzonProductExtractedInfo;
    const regExpExecArray = /<div id="state-webPrice-500883-default-1" data-state='(.*?)'><\/div>/g.exec(content);
    if (regExpExecArray && regExpExecArray.length > 1) {
      // {"isAvailable":true,"price":"647 ₽","originalPrice":"799 ₽","mode":"credit","payment":"65 ₽","paymentTerm":"12 мес","hintLink":"https:\u002F\u002Fwww.ozon.ru\u002Fsection\u002Fcredit\u002F","hintText":"Приблизительный расчет. Платеж зависит от срока и типа кредитного продукта"}
      const state = JSON.parse(regExpExecArray[1]);
      result = {
        available: state.isAvailable,
        price: Number((state.price as string).replace(/\s/g, '').replace('₽', '')),
        productNotFound: false
      };

      if (state.originalPrice) {
        result.originalPrice = Number((state.originalPrice as string).replace(/\s/g, '').replace('₽', ''));
      }
    } else {
      result = {
        productNotFound: true
      };
    }

    return result;
  }

  public static parseProductDetails(details: string): unknown {
    // TODO
    return {};
  }
}
