import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ProductPropertyMetadata } from '@astrade/core';

@Component({
  selector: 'astrade-product-property-field',
  templateUrl: './product-property-field.component.html'
})
export class ProductPropertyFieldComponent implements OnInit {
  @Input() control: FormControl;
  @Input() productProperty: ProductPropertyMetadata;

  constructor() { }

  ngOnInit(): void {
  }
}
