import {Component, Input, ViewChild} from '@angular/core';
import {FormFieldAbstractComponent} from '../form-field-abstract/form-field-abstract.component';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {BehaviorSubject} from 'rxjs';
import {DictionaryValue} from 'commerceml-parser-core';
import {OneSDictionaryService} from './one-s-dictionary.service';
import {debounceTime, distinctUntilChanged, filter, switchMap} from 'rxjs/operators';

@Component({
  selector: 'astrade-form-field-one-s-autocomplete',
  template: `
    <mat-form-field fxFlex>
      <mat-label>{{label}}</mat-label>
      <input type="text" matInput [formControl]="control" [matAutocomplete]="auto"/>
      <button type="button" [disabled]="!control.value" mat-icon-button matSuffix (click)="reset()"><mat-icon>cancel</mat-icon></button>
      <button type="button" *ngIf="(options$ | async).length > 0" mat-icon-button matSuffix (click)="openDropDownList()"><mat-icon>arrow_drop_down</mat-icon></button>
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="renderValue">
        <mat-option *ngFor="let option of options$ | async" [value]="option">
          <span>{{option.value}}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  `
})
export class FormFieldOneSAutocompleteComponent extends FormFieldAbstractComponent {
  @Input() oneSClassifierId: string;
  @Input() oneSPropertyId: string;

  @ViewChild(MatAutocompleteTrigger, {read: MatAutocompleteTrigger}) inputAutoComplete: MatAutocompleteTrigger;

  options$ = new BehaviorSubject<DictionaryValue[]>([]);

  constructor(
    private readonly oneSDictionaryService: OneSDictionaryService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.oneSClassifierId && this.oneSPropertyId) {
      this.oneSDictionaryService.getAllValues(this.oneSClassifierId, this.oneSPropertyId).subscribe(value => {
        this.options$.next(value);
      })
    }

    this.control.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      filter(value => typeof value === 'string' || value === ''),
      switchMap(searchTerm => {
        if (((searchTerm as string)?.length ?? 0) > 0) {
          return this.oneSDictionaryService.search(this.oneSClassifierId, this.oneSPropertyId, searchTerm);
        }

        return this.oneSDictionaryService.getAllValues(this.oneSClassifierId, this.oneSPropertyId);
      })
    ).subscribe(value => {
      this.options$.next(value);
    });
  }

  renderValue(value: any): string {
    return value?.value ?? '';
  }

  reset(): void {
    this.control.setValue('');
  }

  openDropDownList(): void {
    this.inputAutoComplete.openPanel();
  }
}
