import { OzonNativeProductStatus } from './ozon-native-product-status';
import { OzonNativeProductError } from './ozon-native-product-error';
import { OzonNativeProductCommission } from './ozon-native-product-commission';
import { OzonNativeProductVisibilityDetails } from './ozon-native-product-visibility-details';
import { OzonNativeProductSource } from './ozon-native-product-source';
import { OzonNativeProductStocks } from './ozon-native-product-stocks';
import { OzonV2ProductInfoResponse_Result } from '@astrade/ozon-seller-api';
import { DateTime } from 'luxon';

/**
 * Информация о товаре в системе Озон.
 */
export class OzonNativeProduct {
  /**
   * Ozon идентификатор товара.
   */
  id: number;

  /**
   * Идентификатор товара в системе продавца.
   * Наш артикул товара.
   */
  offerId: string;

  /**
   * Название товара. До 500 символов.
   */
  name: string;

  /**
   * Штрихкод товара в системе Ozon.
   * Может отличаться от реального штрихкода товара, если назначен внутренний ШК Ozon.
   * Такое бывает, если оригинальный ШК уже занят каким-то другим товаром другого поставщика.
   */
  barcode: string;

  /**
   * Идентификатор Ozon категории товара.
   */
  categoryId: number;

  /**
   * Массив url для изображений товара в CDN Ozon.
   */
  images: string[];

  /**
   * Ставка НДС для товара:
   * - 0 — не облагается НДС;
   * - 10 — 10%;
   * - 20 — 20%.
   */
  vat: number;

  /**
   * Статус добваления товара с систему Ozon.
   */
  status: OzonNativeProductStatus;

  /**
   * 	Дата и время создания товара.
   */
  createdAt: Date;

  /**
   * Информация об ошибках валидации товара.
   */
  errors: OzonNativeProductError[];

  /**
   * 	Цена на товар с учетом всех акций.
   * 	Это значение будет указано на витрине Ozon.
   */
  marketingPrice: number;

  /**
   * Минимальная цена на аналогичный товара на Ozon.
   */
  minOzonPrice: number;

  /**
   * Цена до учета скидок, на карточке товара отображается зачеркнутой.
   */
  oldPrice: number;

  /**
   * Информация о цене товара.
   */
  price: number;

  /**
   * Цена для клиентов с подпиской Ozon Premium.
   */
  premiumPrice: number;

  /**
   * Ценовой индекс.
   */
  priceIndex: number;

  /**
   * Цена на товар, рекомендованная системой на основании схожих предложений.
   */
  recommendedPrice: number;

  /**
   * Комиссии Ozon для разных схем продаж.
   */
  commissions?: OzonNativeProductCommission[];

  /**
   * Товар виден в публичном каталоге Ozon, "Готов к продаже".
   */
  visible: boolean;

  /**
   * Параметры видимости товара.
   */
  visibilityDetails: OzonNativeProductVisibilityDetails;

  /**
   * Информация о SKU Ozon и источниках продажи.
   */
  sources: OzonNativeProductSource[];

  /**
   * Информация о количестве товара.
   */
  stocks: OzonNativeProductStocks;

  public static fromOzonApi(productInfo: OzonV2ProductInfoResponse_Result): OzonNativeProduct {
    return {
      barcode: productInfo.barcode,
      categoryId: productInfo.category_id,
      commissions: productInfo.commissions?.map(commission => OzonNativeProductCommission.fromOzonApi(commission)) ?? [],
      createdAt: DateTime.fromISO(productInfo.created_at).toJSDate(),
      errors: productInfo.errors?.map(error => OzonNativeProductError.fromOzonApi(error)) ?? [],
      id: productInfo.id,
      images: [...productInfo.images],
      marketingPrice: Number(productInfo.marketing_price) ?? 0,
      minOzonPrice: Number(productInfo.min_ozon_price) ?? 0,
      name: productInfo.name,
      offerId: productInfo.offer_id,
      oldPrice: Number(productInfo.old_price),
      premiumPrice: Number(productInfo.premium_price),
      price: Number(productInfo.price),
      priceIndex: Number(productInfo.price_index),
      recommendedPrice: Number(productInfo.recommended_price),
      sources: productInfo.sources?.map(source => OzonNativeProductSource.fromOzonApi(source)) ?? [],
      status: productInfo.state,
      stocks: {
        coming: productInfo.stocks?.coming ?? 0,
        present: productInfo.stocks?.present ?? 0,
        reserved: productInfo.stocks?.reserved ?? 0
      },
      vat: (Number(productInfo.vat) ?? 0) * 100,
      visibilityDetails: {
        active: productInfo.visibility_details?.active_product ?? false,
        hasPrice: productInfo.visibility_details?.has_price ?? false,
        hasStock: productInfo.visibility_details?.has_stock ?? false
      },
      visible: productInfo.visible
    } as OzonNativeProduct;
  }
}
