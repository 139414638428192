import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OzonCategoryFormGroup } from '../../form/controls/product-main-properties-form-group/ozon-category-form-group/ozon-category-form-group';

@Component({
  selector: 'astrade-ozon-category-form-field',
  template: `
    <mat-form-field fxFlex>
      <mat-label>Категория Ozon</mat-label>
      <input type="hidden" [formControl]="categoryFormGroup.controls.id"/>
      <input matInput type="text" [formControl]="categoryFormGroup.controls.title" readonly/>
      <button type="button" [disabled]="!categoryFormGroup.controls.title.value" mat-icon-button matSuffix (click)="reset($event)"><mat-icon>cancel</mat-icon></button>
      <button type="button" mat-icon-button matSuffix (click)="openDialogClick.emit()"><mat-icon>open_in_new</mat-icon></button>
    </mat-form-field>
  `
})
export class OzonCategoryFormFieldComponent implements OnInit {
  @Input() categoryFormGroup: OzonCategoryFormGroup;

  @Output() openDialogClick = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  reset(event): void {
    console.log('ozon-category-form-field.reset()', event);
    if (this.categoryFormGroup.controls.id.value !== '') {
      this.categoryFormGroup.controls.id.setValue('');
    }

    if (this.categoryFormGroup.controls.title.value !== '') {
      this.categoryFormGroup.controls.title.setValue('');
    }
  }
}
