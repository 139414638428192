import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Dictionary, DictionaryValue, Product } from '@astrade/core';
import { Observable, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { ColDef, GridApi, GridReadyEvent, RowValueChangedEvent } from 'ag-grid-community';
import { DictionaryService } from '../form/fields/form-field-autocomplete/dictionary.service';
import { OzonDictionaryCellRendererComponent } from '../ag-grid/ozon-dictionary-cell-renderer/ozon-dictionary-cell-renderer.component';
import { OzonDictionaryCellEditorComponent } from '../ag-grid/ozon-dictionary-cell-editor/ozon-dictionary-cell-editor.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  IOneSDictionaryCellEditorParams,
  OneSDictionaryCellEditorComponent
} from '../ag-grid/one-s-dictionary-cell-editor/one-sdictionary-cell-editor.component';
import {OneSDictionaryCellRendererComponent} from '../ag-grid/one-s-dictionary-cell-renderer/one-s-dictionary-cell-renderer.component';

@Component({
  selector: 'astrade-dictionary-list',
  template: `
    <div fxLayout="column" fxFlexFill class="container" style="padding-top: 20px">
      <h2 style="padding-left: 20px">Справочник: {{(info$ | async)?.name}}</h2>
      <div fxLayout="row">
        <mat-form-field fxFlexOffset="1">
          <mat-label>Поиск</mat-label>
          <input matInput type="text" [formControl]="searchTerm"/>
        </mat-form-field>
      </div>
      <div fxFlex>
        <ag-grid-angular
          rowSelection="single"
          style="height: 100%"
          class="ag-theme-alpine"
          [columnDefs]="initialColumnDefs"
          [defaultColDef]="defaultColDef"
          [columnTypes]="columnTypes"
          [rowData]="values$ | async"
          [getRowNodeId]="getRowNodeId"
          [quickFilterText]="searchTerm.valueChanges | async"
          [modules]="modules"
          [statusBar]="statusBar"
          (gridReady)="onGridReady($event)"
          (rowValueChanged)="onRowValueChanged($event)"
          editType="fullRow"
          immutableData="true"
          suppressMaintainUnsortedOrder="true"
          suppressDragLeaveHidesColumns="true"
        ></ag-grid-angular>
      </div>
    </div>
  `
})
export class DictionaryListComponent implements OnInit, OnDestroy {
  @Input('dictionaryId') set setDictionaryId(dictionaryId: string) {
    this.dictionaryId = dictionaryId;
    this.info$ = this.dictionaryService.getInfo(dictionaryId);
    this.values$ = this.dictionaryService.getAllValues(dictionaryId);

    this.subscriptions.push(this.info$.subscribe(value => {
      const colDef = [...this.initialColumnDefs];
      if (value.ozonAttributeId && value.ozonCategoryId) {
        colDef.push({
          headerName: 'Значение Ozon',
          field: 'ozonDictionaryValue',
          type: 'ozonDictionaryValue',
          initialWidth: 300,
          cellEditorParams: {
            ozonCategoryId: value.ozonCategoryId,
            ozonAttributeId: value.ozonAttributeId
          }
        });
      }

      if (value.oneSClassifierId && value.oneSPropertyId) {
        colDef.push({
          headerName: 'Значение 1С',
          field: 'oneSDictionaryValue',
          type: 'oneSDictionaryValue',
          initialWidth: 300,
          cellEditorParams: {
            oneSClassifierId: value.oneSClassifierId,
            oneSPropertyId: value.oneSPropertyId
          } as IOneSDictionaryCellEditorParams
        });
      }

      this?.gridApi.setColumnDefs(colDef);
    }));
  };

  @Input() info$: Observable<Dictionary>;
  @Input() values$: Observable<DictionaryValue[]>;

  dictionaryId: string;

  searchTerm = new FormControl('');

  modules = AllModules;

  defaultColDef: ColDef = {
    sortable: true,
    filter: 'agSetColumnFilter',
    resizable: true,
    editable: true
  };

  columnTypes: Record<string, ColDef> = {
    nonEditable: {
      editable: false
    },
    ozonDictionaryValue: {
      cellRendererFramework: OzonDictionaryCellRendererComponent,
      cellEditorFramework: OzonDictionaryCellEditorComponent
    },
    oneSDictionaryValue: {
      cellRendererFramework: OneSDictionaryCellRendererComponent,
      cellEditorFramework: OneSDictionaryCellEditorComponent
    }
  }

  initialColumnDefs: ColDef[] = [{
    headerName: 'ID',
    field: 'id',
    type: 'nonEditable',
    initialWidth: 320
  },{
    headerName: 'Значение',
    field: 'name',
    initialWidth: 400
  }];

  statusBar = {
    statusPanels: [
      {
        statusPanel: 'agTotalAndFilteredRowCountComponent',
        align: 'left',
      },
      {
        statusPanel: 'agTotalRowCountComponent',
        align: 'center',
      },
      { statusPanel: 'agFilteredRowCountComponent' },
      { statusPanel: 'agSelectedRowCountComponent' },
      { statusPanel: 'agAggregationComponent' },
    ],
  };

  private gridApi: GridApi;

  private subscriptions: Subscription[] = [];

  constructor(
    private readonly dictionaryService: DictionaryService,
    private readonly snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
  }

  getRowNodeId(data: Product): string {
    return data.id as string;
  }

  onRowValueChanged(event: RowValueChangedEvent): void {
    this.dictionaryService.save(this.dictionaryId, event.data)
      .then(() => {
        console.log('Dictionary value saved');
        this.snackBar.open('Значение сохранено', '', {
          duration: 5000
        });
      })
      .catch(reason => {
        console.error('Save dictionary value', reason);
        this.snackBar.open('Ошибочка вышла... ' + reason?.toString() ?? '', 'Закрыть');
      });
  }
}
