import { Component } from '@angular/core';
import { FormFieldAbstractComponent } from '../form-field-abstract/form-field-abstract.component';

@Component({
  selector: 'astrade-form-field-yes-no',
  template: `
    <mat-form-field fxFlex>
      <mat-label>{{ label }}</mat-label>
      <input matInput type="text" [matAutocomplete]="auto" [formControl]="control"/>
      <button type="button" *ngIf="hint" [matTooltip]="hint" matTooltipClass="hint" mat-icon-button matSuffix><mat-icon>help</mat-icon></button>
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option [value]="true">
          Да
        </mat-option>
        <mat-option [value]="false">
          Нет
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  `
})
export class FormFieldYesNoComponent extends FormFieldAbstractComponent {
  displayFn = (value: any): string => {
    return value ? 'Да' : 'Нет';
  }
}
