import { Component, OnInit } from '@angular/core';
import { OzonDictionaryValue } from '@astrade/core';

@Component({
  selector: 'astrade-ozon-autocomplete-dialog',
  template: `
    <mat-dialog-content style="height: 100vh">
        <astrade-ozon-dictionary-values-list (selectionChange)="onSelectionChange($event)"></astrade-ozon-dictionary-values-list>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button type="button" mat-button [mat-dialog-close]="cancel()">Отмена</button>
      <button type="button" mat-button [mat-dialog-close]="select()" cdkFocusInitial>Выбрать</button>
    </mat-dialog-actions>
  `
})
export class OzonAutocompleteDialogComponent implements OnInit {

  selectedDictionaryValue: OzonDictionaryValue;

  constructor() { }

  ngOnInit(): void {
  }

  onSelectionChange(dictionaryValue: OzonDictionaryValue): void {
    this.selectedDictionaryValue = dictionaryValue;
  }

  cancel(): void {
    return;
  }

  select(): {id: number; value: string;} {
    return {
      id: this.selectedDictionaryValue.id,
      value: this.selectedDictionaryValue.value
    }
  }
}
