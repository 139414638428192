import { AbstractControlOptions, AsyncValidatorFn, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { Category, Product, ProductPropertyValue, ProductPropertyValueType } from '@astrade/core';
import { GubkaCategoryFormGroup } from './gubka-category-form-group/gubka-category-form-group';
import { OzonCategoryFormGroup } from './ozon-category-form-group/ozon-category-form-group';
import { ProductMainPropertiesFormGroupControls } from './product-main-properties-form-group-controls';

export class ProductMainPropertiesFormGroup extends FormGroup {
  controls: ProductMainPropertiesFormGroupControls;

  constructor(
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    const controls: ProductMainPropertiesFormGroupControls = {
      id: new FormControl(''),
      barcode: new FormControl(''),
      article: new FormControl(''),
      fullName: new FormControl(''),
      name: new FormControl(''),
      dontExportToWholesalePrice: new FormControl(false),
      archive: new FormControl(false),
      gubkaCategory: new GubkaCategoryFormGroup(),
      ozonCategory: new OzonCategoryFormGroup(),
      ozonBarcode: new FormControl('')
    };
    super(controls, validatorOrOpts, asyncValidator);
  }

  setValuesFromProduct(product: Product): void {
    if (!product) {
      return;
    }

    this.controls.id.setValue(product.id);
    this.controls.barcode.setValue(product.barcode);
    this.controls.article.setValue(product.article);
    this.controls.fullName.setValue(product.fullName);
    this.controls.name.setValue(product.name);
    this.controls.dontExportToWholesalePrice.setValue(false); // TODO: Add property
    this.controls.archive.setValue(false); // TODO: Add property
    this.controls.gubkaCategory.setValuesFromCategory(product?.properties['e1ceea2a-ed82-4809-933c-6282f28ee341']?.value as Partial<Category>);
    this.controls.ozonCategory.setValuesFromOzonCategory(product?.properties['5113dd6c-4483-42e4-8401-328797f527be']?.value as {id: number; name: string});
    this.controls.ozonBarcode.setValue(product?.properties['ce42d5d0-4b08-40db-a2f5-a1d69f3c0363']?.value as string ?? '');
    this.controls.ozonCategory.markAllAsTouched();
  }

  applyValuesToProduct(product: Product): void {
    product.barcode = (this.controls.barcode.value as string).trim();
    product.article = (this.controls.article.value as string).trim();
    product.fullName = (this.controls.fullName.value as string).trim();
    product.name = (this.controls.name.value as string).trim();
    this.setProductPropertyValue(product, 'e1ceea2a-ed82-4809-933c-6282f28ee341', {
      id: this.controls.gubkaCategory.controls.id.value,
      name: (this.controls.gubkaCategory.controls.name.value as string).trim()
    });
    this.setProductPropertyValue(product, '5113dd6c-4483-42e4-8401-328797f527be', {
      id: Number(this.controls.ozonCategory.controls.id.value),
      name: (this.controls.ozonCategory.controls.title.value as string).trim()
    });
    this.setProductPropertyValue(product, 'ce42d5d0-4b08-40db-a2f5-a1d69f3c0363', (this.controls.ozonBarcode.value as string ?? '').trim());
  }

  protected setProductPropertyValue(product: Product, propertyId: string, value: ProductPropertyValueType): void {
    if (!product.properties) {
      product.properties = {};
    }

    if (!product.properties[propertyId]) {
      product.properties[propertyId] = {
        propertyMetadata: undefined,
        value: {}
      } as ProductPropertyValue;
    }

    product.properties[propertyId].value = value;
  }
}
