import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../../../apps/console/src/app/state/reducers';
import { Product } from '@astrade/core';
import { Observable } from 'rxjs';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { FormControl } from '@angular/forms';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import {
  EditButtonCellRendererComponent,
  IEditButtonCellRendererParams
} from '../../ag-grid/edit-button-cell-renderer/edit-button-cell-renderer.component';
import * as productActions from '../../../../../../apps/console/src/app/state/products.actions';
import { AG_GRID_COLUMN_TYPES } from '../../ag-grid/ag-grid-column-types';

@Component({
  selector: 'astrade-prices-list',
  template: `
    <div fxLayout="column" fxFlexFill class="container">
      <div fxLayout="row">
        <mat-form-field fxFlexOffset="1">
          <mat-label>Поиск</mat-label>
          <input matInput type="text" [formControl]="searchQuery"/>
        </mat-form-field>
      </div>
      <mat-toolbar></mat-toolbar>
      <div fxFlex>
        <ag-grid-angular
          rowSelection="single"
          style="height: 100%"
          class="ag-theme-alpine"
          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [columnTypes]="columnTypes"
          [rowData]="products$ | async"
          [getRowNodeId]="getRowNodeId"
          [quickFilterText]="searchQuery.valueChanges | async"
          [modules]="modules"
          [statusBar]="statusBar"
          (gridReady)="onGridReady($event)"
          editType="fullRow"
          immutableData="true"
          suppressMaintainUnsortedOrder="true"
          suppressDragLeaveHidesColumns="true"
        ></ag-grid-angular>
      </div>
    </div>
  `
})
export class PricesListComponent implements OnInit {

  @Output() editButtonClick = new EventEmitter<Product>();

  products$: Observable<Product[] | undefined> = this.store.select('products');

  modules = AllModules;

  /**
   * Quick search term.
   */
  searchQuery = new FormControl('');

  defaultColDef: ColDef = {
    sortable: true,
    filter: 'agSetColumnFilter',
    resizable: true
  };

  columnTypes = AG_GRID_COLUMN_TYPES;

  private defaultColumnDefs: ColDef[] = [{
    width: 80,
    cellRendererFramework: EditButtonCellRendererComponent,
    cellRendererParams: {
      onClick: rowNode => this.editButtonClick.emit(rowNode.data)
    } as IEditButtonCellRendererParams
  }, {
    headerName: 'Наименование ритейл',
    field: 'name',
    initialWidth: 450,
    editable: true
  }, {
    headerName: 'Штрихкод',
    field: 'barcode',
    type: 'nonEditable',
    initialWidth: 140,
  }, {
    headerName: 'Артикул',
    field: 'article',
    type: 'nonEditable',
    initialWidth: 140,
    sort: 'asc',
    hide: true
  }, {
    headerName: 'Категория gubka',
    field: 'properties.e1ceea2a-ed82-4809-933c-6282f28ee341.value.name',
    initialWidth: 190,
    hide: true
  }, {
    headerName: 'Категория ozon',
    field: 'properties.5113dd6c-4483-42e4-8401-328797f527be.value.name',
    initialWidth: 190,
    hide: true
  }, {
    headerName: 'Полное наименование',
    field: 'fullName',
    initialWidth: 450,
    editable: false,
    hide: true
  }, {
    headerName: 'Цена Опт',
    field: 'properties.93eca26f-a872-493d-9edc-6fa0a8aa3539.value',
    initialWidth: 120
  }, {
    headerName: 'Обновление оптовой цены',
    field: 'properties.cccc2a43-5cf4-482e-948f-0e31694603fc.value',
    initialWidth: 160,
    type: 'timestamp'
  }, {
    headerName: 'Есть у поставщика',
    field: 'properties.259fd97e-89fa-4c50-828d-4ccacb442915.value',
    initialWidth: 120,
    type: 'yesNo'
  }, {
    headerName: 'Цена для gubka.shop',
    field: 'properties.b301306a-4568-4da2-b74a-0f7d090fffbc.value',
    initialWidth: 150
  }, {
    headerName: 'Цена для Ozon',
    field: 'properties.8635be5a-7f6e-42e8-b782-d94ce01b63ce.value',
    initialWidth: 150
  }, {
    headerName: 'Наша min Цена',
    field: 'properties.1c50d7e2-b53b-4f4e-bacb-ebfae8820462.value',
    initialWidth: 160
  }, {
    headerName: 'Min цена парсера',
    field: '',
    initialWidth: 160
  }, {
    headerName: 'Выбранная цена для Ozon',
    field: 'properties.6ce050b6-7e7c-4948-9be2-7ca9016b8669.value',
    initialWidth: 140
  }, {
    headerName: 'Комиссия Ozon FBO',
    valueGetter: params => {
      const commission =(params.data as Product)?.ozonNativeProduct?.commissions?.find(value => value.saleSchema === 'fbo');
      if (!commission) {
        return '';
      }

      return `${commission.amount} (${commission.percent}%/min ${commission.minAmount}) + ${commission.deliveryAmount} = ${commission.amount + commission.deliveryAmount}`;
    },
    initialWidth: 230,
    editable: false
  }, {
    headerName: 'Комиссия Ozon FBO возврат',
    valueGetter: params => {
      const commission = (params.data as Product)?.ozonNativeProduct?.commissions?.find(value => value.saleSchema === 'fbo');
      if (!commission) {
        return '';
      }

      return commission.returnAmount;
    },
    initialWidth: 210,
    editable: false
  }, {
    headerName: 'ABC',
    field: 'properties.a44f49f5-65b4-4b46-bdab-5a7d70398ff0.value',
    initialWidth: 120,
    filter: 'agNumberColumnFilter'
  }, {
    headerName: 'На складе Ozon',
    field: 'ozonNativeProduct.stocks.present',
    initialWidth: 230,
  }, {
    headerName: 'Ожидается поставка на склад Ozon',
    field: 'ozonNativeProduct.stocks.coming',
    initialWidth: 230
  }, {
    headerName: 'Зарезервировано на складе Ozon',
    field: 'ozonNativeProduct.stocks.reserved',
    initialWidth: 230
  }, {
    headerName: 'Категория ozon',
    field: 'properties.5113dd6c-4483-42e4-8401-328797f527be.value.name',
    editable: true,
    initialWidth: 250
  }, {
    headerName: 'Статус выгрузки в Ozon',
    field: 'ozonExportTask.status',
    type: 'ozonExportTaskStatus',
    initialWidth: 170
  }, {
    headerName: 'Статус товара Ozon',
    field: 'ozonNativeProduct.status',
    type: 'ozonNativeProductStatus',
    initialWidth: 170
  }, {
    headerName: 'Отображается на Ozon',
    field: 'ozonNativeProduct.visible',
    type: 'yesNo',
    initialWidth: 150,
    editable: false
  }, {
    headerName: 'Ошибки Ozon',
    field: 'ozonNativeProduct.errors',
    type: 'ozonExportErrors',
    initialWidth: 400,
    editable: false
  }];

  /**
   * Grid column definitions.
   */
  columnDefs: ColDef[] = [];

  statusBar = {
    statusPanels: [
      {
        statusPanel: 'agTotalAndFilteredRowCountComponent',
        align: 'left',
      },
      {
        statusPanel: 'agTotalRowCountComponent',
        align: 'center',
      },
      { statusPanel: 'agFilteredRowCountComponent' },
      { statusPanel: 'agSelectedRowCountComponent' },
      { statusPanel: 'agAggregationComponent' },
    ],
  };

  /**
   * ag-Grid API.
   */
  private gridApi: GridApi;

  constructor(
    private readonly store: Store<State>
  ) { }

  ngOnInit(): void {
    this.store.dispatch(productActions.getAll());
  }

  /**
   * ag-Grid ready event handler.
   * @param event
   */
  onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    this.columnDefs = [...this.defaultColumnDefs];
    this.gridApi.setColumnDefs(this.columnDefs);
  }

  /**
   * Returns row node ID from the product.
   * @param data Product.
   */
  getRowNodeId(data: Product): string {
    return data.id as string;
  }
}
