<ng-container *ngIf="!productProperty.dictionaryId else autocomplete">
  <ng-container [ngSwitch]="productProperty?.type">
    <astrade-form-field-yes-no *ngSwitchCase="'boolean'" [label]="productProperty?.name" [control]="control"></astrade-form-field-yes-no>
    <ng-container *ngSwitchCase="'number'">
      <astrade-form-field-vat *ngIf="productProperty?.formControlType == 'vat' else numberField" [label]="productProperty?.name" [control]="control"></astrade-form-field-vat>
      <ng-template #numberField>
        <astrade-form-field-number [label]="productProperty?.name" [control]="control"></astrade-form-field-number>
      </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="'string'">
      <astrade-form-field-textarea *ngIf="productProperty?.formControlType == 'textarea' else textField" [label]="productProperty?.name" [control]="control"></astrade-form-field-textarea>
      <ng-template #textField>
        <astrade-form-field-text [label]="productProperty?.name" [control]="control"></astrade-form-field-text>
      </ng-template>
    </ng-container>
    <astrade-form-field-text *ngSwitchDefault [label]="productProperty?.name" [control]="control"></astrade-form-field-text>
  </ng-container>
</ng-container>
<ng-template #autocomplete>
  <astrade-form-field-autocomplete [dictionaryId]="productProperty?.dictionaryId" [label]="productProperty?.name" [control]="control"></astrade-form-field-autocomplete>
</ng-template>
