import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { SupplierPriceListImportTask } from '@astrade/core';
import { AngularFirestoreCollection } from '@angular/fire/firestore/collection/collection';
import firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class SupplierPriceListImportTaskService {
  protected readonly collectionName = 'supplier-price-list-import-task';

  constructor(
    private readonly firestore: AngularFirestore
  ) { }

  public getAll(): Observable<SupplierPriceListImportTask[]> {
    return this.collection().valueChanges();
  }

  public add(id: string): Promise<void> {
    return this.collection().doc(id).set({
      id,
      status: 'new',
      // TODO: Fix hack with type casting
      createdAt: firebase.firestore.FieldValue.serverTimestamp() as unknown as Date,
      statusUpdatedAt: firebase.firestore.FieldValue.serverTimestamp() as unknown as Date,
      source: 'manual'
    }, {merge: true});
  }

  protected collection(): AngularFirestoreCollection<SupplierPriceListImportTask> {
    return this
      .firestore
      .collection<SupplierPriceListImportTask>(this.collectionName);
  }
}
