import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Profile} from '../dropship-profile/profile';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(
    private readonly firestore: AngularFirestore
  ) {}

  public get(userId: string): Observable<Profile> {
    return this.firestore.collection('profiles').doc(userId).get().pipe(
      map(doc => {
        return doc.data() as Profile;
      })
    );
  }

  public async save(userId: string, profile: Profile): Promise<void> {
    console.log('Profile to be saved:', profile);
    console.log('userId:', userId);
    return this.firestore.collection('profiles').doc(userId).set(profile, {merge: true});
  }
}
