import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yesNo'
})
export class YesNoPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (value === true || value === 'true') {
      return 'Да';
    } else if (value === false || value === 'false') {
      return 'Нет';
    } else {
      return value;
    }
  }
}
