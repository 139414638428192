import {
  AbstractControlOptions,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Product, ProductPropertyMetadata, ProductPropertyValue } from '@astrade/core';

export class ProductPriceManagerPropertiesFormGroup extends FormGroup {
  priceManagerProperties: ProductPropertyMetadata[];

  constructor(
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super({}, validatorOrOpts, asyncValidator);
  }

  generateControlsFromPriceManagerPropertyValues(priceManagerProperties: ProductPropertyMetadata[]): void {
    this.priceManagerProperties = priceManagerProperties;
    this.priceManagerProperties?.forEach(additionalProperty => {
      const validators = additionalProperty.isRequired ? Validators.required : [];
      this.addControl(additionalProperty.id, new FormControl('', validators));
    });
  }

  setValuesFromProduct(product: Product): void {
    this.priceManagerProperties?.forEach(property => {
      const value = product?.properties[property.id]?.value ?? '';
      this.get(property.id).setValue(value, {emitEvent: false});
    });

    this.markAllAsTouched();
  }

  applyValuesToProductProperties(priceManagerValues: Record<string, ProductPropertyValue>): void {
    if (!this.priceManagerProperties) {
      return;
    }

    for (const [id, formControl] of Object.entries(this.controls)) {
      if (!priceManagerValues[id]) {
        let propertyMetadata = this.priceManagerProperties.find(property => property.id === id);
        priceManagerValues[id] = {
          propertyMetadata,
          value: ''
        }
      }

      priceManagerValues[id].value = formControl.value;
    }

    return;
  }
}
