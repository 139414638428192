import { Component } from '@angular/core';
import {ICellEditorParams} from 'ag-grid-community';
import {FormControl} from '@angular/forms';
import {ICellEditorAngularComp} from 'ag-grid-angular';

export interface IOneSDictionaryCellEditorParams extends ICellEditorParams {
  oneSClassifierId: string;
  oneSPropertyId: string;
}

@Component({
  selector: 'astrade-one-s-dictionary-cell-editor',
  template: `
    <astrade-form-field-one-s-autocomplete
      [label]=""
      [control]="control"
      [oneSClassifierId]="params.oneSClassifierId"
      [oneSPropertyId]="params.oneSPropertyId"
    ></astrade-form-field-one-s-autocomplete>
  `
})
export class OneSDictionaryCellEditorComponent implements ICellEditorAngularComp {

  params: IOneSDictionaryCellEditorParams;

  control = new FormControl('');

  constructor() { }

  agInit(params: IOneSDictionaryCellEditorParams): void {
    this.params = params;
    this.control.setValue(params.value);
  }

  getValue(): any {
    return this.control.value;
  }
}
