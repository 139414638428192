import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { OzonDictionaryValue } from '@astrade/core';

@Injectable({
  providedIn: 'root'
})
export class OzonDictionaryService {

  constructor(private firestore: AngularFirestore) { }

  getInitialValues(categoryId: number, attributeId: number): Observable<OzonDictionaryValue[]> {
    return this.firestore
    .collection('ozon-dictionary')
    .doc(`${categoryId}-${attributeId}`)
    .collection<OzonDictionaryValue>('values', ref => ref.limit(50))
    .valueChanges();
  }
}
