import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'astrade-form-field-abstract',
  template: `
    <div>form-field-abstract component works!</div>
  `
})
export class FormFieldAbstractComponent implements OnInit {
  @Input() label: string;
  @Input() hint: string;
  @Input() control: AbstractControl;

  constructor() { }

  ngOnInit(): void {
  }

}
