import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'astrade-checkbox-cell-renderer',
  template: `
    <div style="display: flex; justify-content: center">
      <mat-checkbox [checked]="params.value" disabled="true"></mat-checkbox>
    </div>
  `
})
export class CheckboxCellRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  constructor() { }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }
}
