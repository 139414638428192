import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ozonExportStatus'
})
export class OzonExportStatusPipe implements PipeTransform {
  private translations: Record<string, string> = {
    'EXPORT_STARTED': 'Выгрузка начата',
    'pending': 'Ожидает подтверждения',
    'processing': 'Обрабатывается',
    'moderating': 'На модерации',
    'processed': 'Обработан',
    'failed_moderation': 'Ошибка модерации',
    'failed_validation': 'Ошибка валидации',
    'failed': 'Ошибка',
    'imported': 'Импортирован'
  };

  transform(value: unknown, ...args: unknown[]): string {
    return this.translations[value as string] ?? value as string;
  }
}
