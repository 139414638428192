import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ICellEditorParams } from 'ag-grid-community';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'astrade-checkbox-cell-editor',
  template: `
    <div style="display: flex; justify-content: center">
      <mat-checkbox [formControl]="checkbox"></mat-checkbox>
    </div>
  `
})
export class CheckboxCellEditorComponent implements ICellEditorAngularComp {

  checkbox = new FormControl();

  private params: ICellEditorParams;

  constructor() { }

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.checkbox.setValue(params.value, {emitEvent: false});
  }

  getValue(): boolean {
    return this.checkbox.value;
  }
}
