import { createAction, props } from '@ngrx/store';
import { CategoryTree } from '@astrade/core';

export const getAll = createAction('[Categories] Get All');

export const getAllSuccess = createAction(
  '[Categories] Get All Success',
  props<{categories: CategoryTree[]}>()
);

export const getAllError = createAction('[Categories] Get All Error');
