<div class="example-container" cdkDropListGroup>
  <div cdkDropList [cdkDropListEnterPredicate]="dropListEnterPredicate" (cdkDropListDropped)="dropListDropped($event)"></div>
  <div cdkDropList *ngFor="let photo of photos; let i = index; trackBy: trackByPhotoUrl" [cdkDropListEnterPredicate]="dropListEnterPredicate" (cdkDropListDropped)="dropListDropped($event)">
    <div cdkDrag class="example-box" (cdkDragMoved)="dragMoved($event)">
      <img [src]="photo | photoSmall" alt="" width="100%" height="100%"/>
      <button type="button" mat-raised-button mat-icon-button (click)="delete(i)"><mat-icon>delete</mat-icon></button>
    </div>
  </div>
  <div class="example-box box-add" (click)="add()">
    <input #fileUpload type="file" multiple="multiple" accept="image/jpeg, image/png" (change)="onFileUploadChange($event)">
    <mat-icon *ngIf="!uploadStatus || uploadStatus === 'done' || uploadStatus === 'error'">add_circle</mat-icon>
    <div *ngIf="uploadStatus === 'uploading'" style="height: auto; width: 100%">
      <mat-progress-bar mode="determinate" [value]="uploadPercent | async"></mat-progress-bar>
      <div style="font-size: 10pt">Загрузка...</div>
    </div>
    <div *ngIf="uploadStatus === 'resizing'">
      <div style="font-size: 10pt">Уменьшение размера...</div>
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="uploadStatus === 'error'">
      <div style="font-size: 10pt">Ошибка</div>
    </div>
  </div>
</div>
