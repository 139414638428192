import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'astrade-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() title = '';

  @Output() menuClick = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }
}
