import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { CategoryTree, Product, ProductPropertyMetadata } from '@astrade/core';
import { FormControl } from '@angular/forms';
import {
  EditButtonCellRendererComponent,
  IEditButtonCellRendererParams
} from '../../ag-grid/edit-button-cell-renderer/edit-button-cell-renderer.component';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../../../../../apps/console/src/app/state/reducers';
import * as productActions from '../../../../../../apps/console/src/app/state/products.actions';
import * as categoryActions from '../../../../../../apps/console/src/app/state/categories.actions';
import { GubkaCategoryFormGroup } from '../../form/controls/product-main-properties-form-group/gubka-category-form-group/gubka-category-form-group';
import { OzonExportProductService } from '../../ozon/ozon-export-product/ozon-export-product.service';
import { AG_GRID_COLUMN_TYPES } from '../../ag-grid/ag-grid-column-types';

@Component({
  selector: 'astrade-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss']
})
export class ProductsListComponent implements OnInit, OnDestroy {
  @Input('productProperties') set setProductProperties(productProperties: ProductPropertyMetadata[]) {
    this.productProperties = productProperties;
    this.columnDefs = [...this.defaultColumnDefs];
    const skipAttributes: string[] = [
      'e1ceea2a-ed82-4809-933c-6282f28ee341', // gubka category
      '5113dd6c-4483-42e4-8401-328797f527be' // ozon category
    ];
    productProperties?.forEach(productProperty => {
      // Skip gubka category
      if (skipAttributes.includes(productProperty.id)) {
        return;
      }

      const columnDefinition: ColDef = {
        field: `properties.${productProperty.id}.value`,
        headerName: productProperty.name,
        filter: productProperty.type === 'number' ? 'agNumberColumnFilter' : 'agSetColumnFilter'
      };

      if (productProperty.dictionaryId !== '') {
        columnDefinition.type = 'astradeDictionary'
      }

      this.columnDefs.push(columnDefinition);
    });

    this.gridApi?.setColumnDefs(this.columnDefs);
  }

  @Output() editButtonClick = new EventEmitter<Product>();

  products$: Observable<Product[] | undefined> = this.store.select('products');

  categories$: Observable<CategoryTree[] | undefined> = this.store.select('categories');

  productProperties: ProductPropertyMetadata[];

  modules = AllModules;

  /**
   * Quick search term.
   */
  searchQuery = new FormControl('');

  /**
   * Категория gubka.shop для быстрой фильтрации.
   */
  gubkaCategory = new GubkaCategoryFormGroup();

  /**
   * Категория ozon.ru
   */
  ozonCategoryId = new FormControl(0);
  ozonCategoryName = new FormControl('');

  defaultColDef: ColDef = {
    sortable: true,
    filter: 'agSetColumnFilter',
    resizable: true
  };

  columnTypes = AG_GRID_COLUMN_TYPES;

  private defaultColumnDefs: ColDef[] = [{
    width: 80,
    cellRendererFramework: EditButtonCellRendererComponent,
    cellRendererParams: {
      onClick: rowNode => this.editButtonClick.emit(rowNode.data)
    } as IEditButtonCellRendererParams
  }, {
    headerName: 'Категория gubka',
    field: 'properties.e1ceea2a-ed82-4809-933c-6282f28ee341.value.name',
    editable: true,
    initialWidth: 250
  }, {
    headerName: 'Категория ozon',
    field: 'properties.5113dd6c-4483-42e4-8401-328797f527be.value.name',
    editable: true,
    initialWidth: 250
  }, {
    headerName: 'Артикул',
    field: 'article',
    type: 'nonEditable',
    initialWidth: 110,
    sort: 'asc'
  }, {
    headerName: 'Штрихкод',
    field: 'barcode',
    type: 'nonEditable',
    initialWidth: 140,
  }, {
    headerName: 'Полное наименование',
    field: 'fullName',
    initialWidth: 450,
    editable: false
  }, {
    headerName: 'Наименование ритейл',
    field: 'name',
    initialWidth: 450,
    editable: true
  }, {
    headerName: 'Статус выгрузки в Ozon',
    field: 'ozonExportTask.status',
    type: 'ozonExportTaskStatus',
    initialWidth: 170
  }, {
    headerName: 'Статус товара Ozon',
    field: 'ozonNativeProduct.status',
    type: 'ozonNativeProductStatus',
    initialWidth: 170
  }, {
    headerName: 'Отображается на Ozon',
    field: 'ozonNativeProduct.visible',
    type: 'yesNo',
    initialWidth: 150,
    editable: false
  }, {
    headerName: 'Ошибки Ozon',
    field: 'ozonNativeProduct.errors',
    type: 'ozonExportErrors',
    initialWidth: 400,
    editable: false
  }, {
    headerName: 'На складе Ozon',
    field: 'ozonNativeProduct.stocks.present',
    initialWidth: 230,
  }, {
    headerName: 'Ожидается поставка на склад Ozon',
    field: 'ozonNativeProduct.stocks.coming',
    initialWidth: 230
  }, {
    headerName: 'Зарезервировано на складе Ozon',
    field: 'ozonNativeProduct.stocks.reserved',
    initialWidth: 230
  }, {
    headerName: 'Комиссия Ozon FBO',
    valueGetter: params => {
      const commission =(params.data as Product)?.ozonNativeProduct?.commissions?.find(value => value.saleSchema === 'fbo');
      if (!commission) {
        return '';
      }

      return `${commission.amount} (${commission.percent}%/min ${commission.minAmount}) + ${commission.deliveryAmount} = ${commission.amount + commission.deliveryAmount}`;
    },
    initialWidth: 210,
    editable: false
  }, {
    headerName: 'Комиссия Ozon FBS',
    valueGetter: params => {
      const commission =(params.data as Product)?.ozonNativeProduct?.commissions?.find(value => value.saleSchema === 'fbs');
      if (!commission) {
        return '';
      }

      return `${commission.amount} (${commission.percent}%/min ${commission.minAmount}) + ${commission.deliveryAmount} = ${commission.amount + commission.deliveryAmount}`;
    },
    initialWidth: 210,
    editable: false
  }, {
    headerName: 'Комиссия Ozon RFBS',
    valueGetter: params => {
      const commission =(params.data as Product)?.ozonNativeProduct?.commissions?.find(value => value.saleSchema === 'rfbs');
      if (!commission) {
        return '';
      }

      return `${commission.amount} (${commission.percent}%/min ${commission.minAmount}) + ${commission.deliveryAmount} = ${commission.amount + commission.deliveryAmount}`;
    },
    initialWidth: 210,
    editable: false
  }, {
    headerName: 'Комиссия Ozon FBO возврат',
    valueGetter: params => {
      const commission =(params.data as Product)?.ozonNativeProduct?.commissions?.find(value => value.saleSchema === 'fbo');
      if (!commission) {
        return '';
      }

      return commission.returnAmount;
    },
    initialWidth: 210,
    editable: false
  }, {
    headerName: 'Комиссия Ozon FBS возврат',
    valueGetter: params => {
      const commission =(params.data as Product)?.ozonNativeProduct?.commissions?.find(value => value.saleSchema === 'fbs');
      if (!commission) {
        return '';
      }

      return commission.returnAmount;
    },
    initialWidth: 210,
    editable: false
  }, {
    headerName: 'Комиссия Ozon RFBS возврат',
    valueGetter: params => {
      const commission =(params.data as Product)?.ozonNativeProduct?.commissions?.find(value => value.saleSchema === 'rfbs');
      if (!commission) {
        return '';
      }

      return commission.returnAmount;
    },
    initialWidth: 210,
    editable: false
  }];

  /**
   * Grid column definitions.
   */
  columnDefs: ColDef[] = [];

  statusBar = {
    statusPanels: [
      {
        statusPanel: 'agTotalAndFilteredRowCountComponent',
        align: 'left',
      },
      {
        statusPanel: 'agTotalRowCountComponent',
        align: 'center',
      },
      { statusPanel: 'agFilteredRowCountComponent' },
      { statusPanel: 'agSelectedRowCountComponent' },
      { statusPanel: 'agAggregationComponent' },
    ],
  };

  /**
   * ag-Grid API.
   */
  private gridApi: GridApi;

  constructor(
    private readonly store: Store<State>,
    private readonly ozonExportProductService: OzonExportProductService
  ) { }

  ngOnInit(): void {
    this.store.dispatch(productActions.getAll());
    this.store.dispatch(categoryActions.getAll());
    //this.productsListStore.getProducts();
  }

  ngOnDestroy(): void {

  }

  /**
   * ag-Grid ready event handler.
   * @param event
   */
  onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    this.gridApi.setColumnDefs(this.columnDefs);
    this.gubkaCategory.controls.name.valueChanges.subscribe((categoryName: string) => {
      if (categoryName) {
        this.gridApi.setFilterModel({
          'properties.e1ceea2a-ed82-4809-933c-6282f28ee341.value': {
            values: [categoryName]
          }
        });
      } else {
        this.gridApi.setFilterModel({
          'properties.e1ceea2a-ed82-4809-933c-6282f28ee341.value': {
            values: []
          }
        });
      }
    });
  }

  /**
   * Returns row node ID from the product.
   * @param data Product.
   */
  getRowNodeId(data: Product): string {
    return data.id as string;
  }
}
