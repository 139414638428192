import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'astrade-timestamp-cell-renderer',
  template: `{{ params.value | timestampToDate | date:'yyyy.MM.dd HH:mm:ss' }}`
})
export class TimestampCellRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  constructor() { }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }
}
