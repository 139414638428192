import { AbstractControlOptions, AsyncValidatorFn, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { GubkaCategoryFormGroupControls } from './gubka-category-form-group-controls';
import { Category } from '@astrade/core';

export class GubkaCategoryFormGroup extends FormGroup {
  controls: GubkaCategoryFormGroupControls;
  constructor(
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    const controls: GubkaCategoryFormGroupControls = {
      id: new FormControl(''),
      name: new FormControl('')
    };
    super(controls, validatorOrOpts, asyncValidator);
  }

  setValuesFromCategory(category: Partial<Category>) {
    this.controls.id.setValue(category?.id ?? '');
    this.controls.name.setValue(category?.name ?? '');
  }
}
