import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Module } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ColDef, GridApi, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-community';
import { OzonCategoryTree } from '@astrade/core';
import { OzonCategory } from '@astrade/core';

@Component({
  selector: 'astrade-ozon-categories-list',
  template: `
    <div style="height: 100%" fxLayout="column">
      <div fxFlex>
        <ag-grid-angular
          rowSelection="single"
          class="ag-theme-alpine"
          [modules]="modules"
          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [autoGroupColumnDef]="autoGroupColumnDef"
          [treeData]="true"
          [animateRows]="true"
          [getDataPath]="getDataPath"
          [getRowNodeId]="getRowNodeId"
          [quickFilterText]="searchTerm$ | async"
          (gridReady)="onGridReady($event)"
          (selectionChanged)="onSelectionChanged($event)"
        ></ag-grid-angular>
      </div>
    </div>
  `,
  styleUrls: ['./ozon-categories-list.component.scss']
})
export class OzonCategoriesListComponent implements OnInit, OnDestroy {

  @Input() categories$: Observable<OzonCategoryTree[]>;
  @Input() searchTerm$: Observable<string>;
  @Input() originalSelectedCategoryId: number;

  @Output() selectionChange = new EventEmitter<OzonCategoryTree>();
  @Output() rowEditButtonClick = new EventEmitter<OzonCategoryTree>();

  selectedCategoryId = '';

  modules: Module[] = [ClientSideRowModelModule, RowGroupingModule];

  columnDefs: ColDef[] = [];

  defaultColDef = {
    flex: 1,
    resizable: true
  };

  autoGroupColumnDef = {
    headerName: 'Название',
    minWidth: 300,
    editable: false,
    rowDrag: false,
    cellRendererParams: { suppressCount: true }
  };

  private categoriesSubscription: Subscription;

  private gridApi: GridApi;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.categoriesSubscription) {
      this.categoriesSubscription.unsubscribe();
      this.categoriesSubscription = undefined;
    }
  }

  onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    this.gridApi.setColumnDefs(this.columnDefs);
    this.categoriesSubscription = this.categories$.subscribe(categories => {
      if (categories !== undefined) {
        this.gridApi.setRowData(categories);
        this.selectExpandAndScrollToCategoryById(this.originalSelectedCategoryId);
      }
    });
  }

  private selectExpandAndScrollToCategoryById(id: number): void {
    if (!id) {
      return;
    }

    const shouldSelectNode = this.gridApi.getRowNode(id.toString());
    if (!shouldSelectNode) {
      return;
    }

    this.gridApi.selectNode(shouldSelectNode);
    shouldSelectNode.setExpanded(true);
    shouldSelectNode.parent?.setExpanded(true);
    shouldSelectNode.parent?.parent?.setExpanded(true);
    this.gridApi.ensureNodeVisible(shouldSelectNode);
  }

  getDataPath(category: OzonCategoryTree): string[] {
    const result: string[] = [];
    do {
      result.unshift(category.title);
      category = category.parent;
    } while (category);

    return result;
  }

  getRowNodeId(data: OzonCategory): number {
    return data.id;
  }

  onSelectionChanged(event: SelectionChangedEvent): void {
    const selectedCategory = event.api?.getSelectedRows()[0];
    this.selectedCategoryId = selectedCategory.id;
    this.selectionChange.emit(selectedCategory);
  }
}
