import { Component } from '@angular/core';
import {ICellRendererParams} from 'ag-grid-community';
import {ICellRendererAngularComp} from 'ag-grid-angular';

@Component({
  selector: 'astrade-one-s-dictionary-cell-renderer',
  template: `{{params.value?.value}}`
})
export class OneSDictionaryCellRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  constructor() { }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
