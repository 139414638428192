import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Category, CategoryTree } from '@astrade/core';
import {Module} from '@ag-grid-community/core';
import {RowGroupingModule} from '@ag-grid-enterprise/row-grouping';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import { ColDef, GridApi, GridReadyEvent, RowNode, SelectionChangedEvent } from 'ag-grid-community';
import {
  EditButtonCellRendererComponent,
  IEditButtonCellRendererParams
} from '../../ag-grid/edit-button-cell-renderer/edit-button-cell-renderer.component';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'astrade-categories-list',
  template: `
    <div style="height: 100%" fxLayout="column">
      <div>
        <h2 mat-dialog-title>Категории товаров gubka.shop</h2>
      </div>
      <div fxFlex>
        <ag-grid-angular
          rowSelection="single"
          class="ag-theme-alpine"
          [modules]="modules"
          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [autoGroupColumnDef]="autoGroupColumnDef"
          [treeData]="true"
          [animateRows]="true"
          [getDataPath]="getDataPath"
          [getRowNodeId]="getRowNodeId"
          (selectionChanged)="onSelectionChanged($event)"
          (gridReady)="onGridReady($event)"
        ></ag-grid-angular>
      </div>
    </div>
  `,
  styleUrls: ['./categories-list.component.scss']
})
export class CategoriesListComponent implements OnInit, OnDestroy {

  @Input() categories$: Observable<CategoryTree[]>;

  @Output() selectionChange = new EventEmitter<CategoryTree>();
  @Output() rowEditButtonClick = new EventEmitter<CategoryTree>();

  selectedCategoryId = '';

  modules: Module[] = [ClientSideRowModelModule, RowGroupingModule];

  columnDefs: ColDef[] = [{
    cellRendererFramework: EditButtonCellRendererComponent,
    cellRendererParams: {
      onClick: rowNode => this.rowEditButtonClick.emit(rowNode.data)
    } as IEditButtonCellRendererParams
  }];

  defaultColDef = {
    flex: 1,
    resizable: true
  };

  autoGroupColumnDef = {
    headerName: 'Название',
    minWidth: 300,
    editable: false,
    rowDrag: true,
    cellRendererParams: { suppressCount: true }
  };

  /**
   * ag-Grid API.
   */
  private gridApi: GridApi;

  categoriesSubscription: Subscription;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.categoriesSubscription) {
      this.categoriesSubscription.unsubscribe();
      this.categoriesSubscription = undefined;
    }
  }

  getDataPath(category: CategoryTree): string[] {
    const result: string[] = [];
    do {
      result.unshift(category.name);
      category = category.parent;
    } while (category);

    return result;
  }

  getRowNodeId(data: Category): string {
    return data.id;
  }

  onSelectionChanged(event: SelectionChangedEvent): void {
    const selectedCategory = event.api?.getSelectedRows()[0];
    this.selectedCategoryId = selectedCategory.id;
    this.selectionChange.emit(selectedCategory);
  }

  /**
   * ag-Grid ready event handler.
   * @param event
   */
  onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    this.gridApi.setColumnDefs(this.columnDefs);
    this.categoriesSubscription = this.categories$.subscribe(categories => {
      if (categories !== undefined) {
        this.gridApi.setRowData(categories);
      }
    });
  }

  private isSelectionParentOfTarget(selectedNode: RowNode, targetNode: RowNode): boolean {
    for (const child of selectedNode.childrenAfterGroup) {
      if (targetNode && child.key === targetNode.key) {
        return true;
      }
    }

    return false;
  }

  private arePathsEqual(path1: string[], path2: string[]): boolean {
    if (path1.length !== path2.length) {
      return false;
    }

    for (let i = 0; i < path1.length; i++) {
      if (path2[i] !== path1[i]) {
        return false;
      }
    }

    return true;
  }

  private moveToParent(newParent: Category, node: RowNode, allUpdatedNodes: RowNode[]): void {
    // TODO: Implement
  }

  onRowDragEnd(event): void {
    const folderToDropInto = event.overNode;
    if (!folderToDropInto) {
      return;
    }

    const movingData = event.node.data;
    const newParent = folderToDropInto.data ? folderToDropInto.data.parent : undefined;

    if (this.arePathsEqual(newParent, movingData.filePath)) {
      console.log('Paths are equal');
      return;
    }

    if (this.isSelectionParentOfTarget(event.node, folderToDropInto)) {
      console.log('invalid move');
      return;
    }
    const updatedRows = [];
    this.moveToParent(newParent, event.node, updatedRows);
    event.api.applyTransaction({ update: updatedRows });
    event.api.clearFocusedCell();
  }
}
