import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { RivalPrice } from '@astrade/core';
import { map, reduce } from 'rxjs/operators';
import { videointelligence_v1 } from 'googleapis';

@Injectable({
  providedIn: 'root'
})
export class RivalPriceService {

  constructor(
    private readonly angularFirestore: AngularFirestore
  ) { }

  /**
   * Saves rival price.
   * @param rivalPrice
   */
  saveRivalPrice(rivalPrice: RivalPrice): Promise<void> {
    return this.angularFirestore
      .collection('rival-prices')
      .doc(rivalPrice.id)
      .set(rivalPrice, {merge: true});
  }

  /**
   * Loads rival price.
   * @param id
   */
  getRivalPrice(id: string): Observable<RivalPrice> {
    return this.angularFirestore
      .collection<RivalPrice>('rival-prices')
      .doc(id)
      .get()
      .pipe(
        map(document => document.data())
      );
  }

  /**
   * Loads all rival prices for the specified product.
   * @param productId
   */
  getRivalPricesByProductId(productId: string): Observable<RivalPrice[]> {
    if (!productId) {
      return of([]);
    }

    return this.angularFirestore
      .collection<RivalPrice>('rival-prices', ref => ref.where(
        'productId',
        '==',
          productId
      ))
      .valueChanges()
  }

  getMinRivalPriceByProductId(productId: string): Observable<number> {
    return this.getRivalPricesByProductId(productId).pipe(
      map(rivalPrices => rivalPrices.reduce<number>((previousValue, currentValue) => {
        // Skip prices for out of stock and not found products
        if (!currentValue.available || currentValue.notFound) {
          return previousValue;
        }

        if (previousValue === 0) {
          return currentValue.price;
        }

        if (previousValue > currentValue.price) {
          return  currentValue.price;
        } else {
          return previousValue;
        }
      }, 0))
    );
  }

  async deleteById(id: string): Promise<void> {
    return this.deleteByIds([id]);
  }

  async deleteByIds(ids: string[]): Promise<void> {
    const promises: Promise<void>[] = [];
    ids.forEach(value => {
      promises.push(
        this.angularFirestore
          .collection('rival-prices')
          .doc(value)
          .delete()
      );
    });

    await Promise.all(promises);
  }
}
