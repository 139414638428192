import {
  AbstractControlOptions,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { OzonCategoryFormGroupControls } from './ozon-category-form-group-controls';

export class OzonCategoryFormGroup extends FormGroup {
  controls: OzonCategoryFormGroupControls;

  constructor(
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    const controls: OzonCategoryFormGroupControls = {
      id: new FormControl(0, Validators.required),
      title: new FormControl('', Validators.required)
    };
    super(controls, validatorOrOpts, asyncValidator);
  }

  setValuesFromOzonCategory(category: {id: number; name: string;}) {
    console.log('ozon-category-form-group.setValuesFromOzonCategory()', category);
    this.controls.id.setValue(category?.id ?? 0);
    this.controls.title.setValue(category?.name ?? '');
  }
}
