import firebase from 'firebase';

/**
 * Converts Firebase timestamp value to JS Date.
 * If value is not instance of Timestamp then returns undefined.
 *
 * Browser version.
 *
 * @param value Firebase Timestamp.
 */
export function firebaseTimestampToDate(
  value: firebase.firestore.Timestamp
): Date | undefined {
  if (value instanceof firebase.firestore.Timestamp) {
    return (value as firebase.firestore.Timestamp).toDate();
  }

  return undefined;
}
