import { AbstractControlOptions, AsyncValidatorFn, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { ProductPropertyMetadata, ProductPropertyValue } from '@astrade/core';

export class GubkaPropertiesFormGroup extends FormGroup {
  gubkaCategoryProperties: ProductPropertyMetadata[];

  constructor(
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super({}, validatorOrOpts, asyncValidator);
  }

  generateControlsFromCategoryProperties(gubkaCategoryProperties: ProductPropertyMetadata[]): void {
    this.gubkaCategoryProperties = gubkaCategoryProperties;
    gubkaCategoryProperties?.forEach(categoryProperty => {
      this.addControl(categoryProperty.id, new FormControl(''));
    });
  }

  setValuesFromProductPropertyValues(productPropertyValues: Record<string, ProductPropertyValue>): void {
    Object.entries(productPropertyValues).forEach(([key, productPropertyValue]) => {
      this.get(key)?.setValue(productPropertyValue.value);
    });
  }

  applyValuesToProductProperties(productPropertyValues: Record<string, ProductPropertyValue>): void {
    if (!this.gubkaCategoryProperties) {
      return;
    }

    for (const [id, formControl] of Object.entries(this.controls)) {
      if (!productPropertyValues[id]) {
        let propertyMetadata = this.gubkaCategoryProperties.find(property => property.id === id);
        productPropertyValues[id] = {
          propertyMetadata,
          value: formControl.value
        }
      } else {
        productPropertyValues[id].value = formControl.value;
      }
    }
  }
}
