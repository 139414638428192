import {
  AbstractControlOptions,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Product, ProductPropertyMetadata, ProductPropertyValue } from '@astrade/core';

export class ProductAdditionalPropertiesFormGroup extends FormGroup {
  additionalProperties: ProductPropertyMetadata[];

  constructor(
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super({}, validatorOrOpts, asyncValidator);
  }

  generateControlsFromAdditionalPropertyValues(additionalProperties: ProductPropertyMetadata[]): void {
    this.additionalProperties = additionalProperties;
    this.additionalProperties?.forEach(additionalProperty => {
      const validators = additionalProperty.isRequired ? Validators.required : [];
      this.addControl(additionalProperty.id, new FormControl('', validators));
    });
  }

  setValuesFromProduct(product: Product): void {
    this.additionalProperties?.forEach(property => {
      const value = product?.properties[property.id]?.value ?? '';
      this.get(property.id).setValue(value, {emitEvent: false});
    });

    this.markAllAsTouched();
  }

  applyValuesToProductProperties(additionalValues: Record<string, ProductPropertyValue>): void {
    if (!this.additionalProperties) {
      return;
    }

    for (const [id, formControl] of Object.entries(this.controls)) {
      if (!additionalValues[id]) {
        let propertyMetadata = this.additionalProperties.find(property => property.id === id);
        additionalValues[id] = {
          propertyMetadata,
          value: ''
        }
      }

      additionalValues[id].value = formControl.value;
    }
  }
}
