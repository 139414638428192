import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { FormControl } from '@angular/forms';

export interface IOzonDictionaryCellEditorParams extends ICellEditorParams {
  ozonCategoryId: number;
  ozonAttributeId: number;
}

@Component({
  selector: 'astrade-ozon-dictionary-cell-editor',
  template: `
    <astrade-ozon-autocomplete-field
      [label]=""
      [control]="control"
      [categoryId]="params.ozonCategoryId"
      [attributeId]="params.ozonAttributeId"
    ></astrade-ozon-autocomplete-field>
  `
})
export class OzonDictionaryCellEditorComponent implements ICellEditorAngularComp {

  params: IOzonDictionaryCellEditorParams;

  control = new FormControl('');

  constructor() { }

  agInit(params: IOzonDictionaryCellEditorParams): void {
    this.params = params;
    this.control.setValue(params.value);
  }

  getValue(): boolean {
    return this.control.value;
  }
}
