import { Component } from '@angular/core';
import {FirebaseUser, IdTokenResult} from '@astrade/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import firebase from 'firebase';
import GoogleAuthProvider = firebase.auth.GoogleAuthProvider;

@Component({
  selector: 'astrade-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'AStrade Dropship';

  user?: FirebaseUser;
  idTokenResult?: IdTokenResult;

  constructor(
    private readonly auth: AngularFireAuth,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar
  ) {  }

  ngOnInit(): void {
    this.auth.user.subscribe(user => {
      this.user = user;
      if (user) {
        user.getIdTokenResult().then(value => {
          this.idTokenResult = value;
        });
      } else {
        this.idTokenResult = undefined;
      }
    });
  }

  login(): void {
    this.auth.signInWithPopup(new GoogleAuthProvider())
    .then((userCredential) => {
      this.snackBar.open(`${userCredential?.user?.displayName ?? 'Уважаемый гость'}, добро пожаловать!`, '', {
        duration: 5000
      });
      this.router.navigate(['']);
    })
    .catch((reason) => {
      this.snackBar.open('Ошибочка вышла... ' + reason?.toString() ?? '', 'Закрыть');
    });
  }

  logout(): void {
    this.auth.signOut().then(value => {
      this.router.navigate(['']).then(value => {});
    }).catch(reason => console.error(reason));
  }
}
