import { Component, ViewChild } from '@angular/core';
import { FormFieldAbstractComponent } from '../form-field-abstract/form-field-abstract.component';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { of } from 'rxjs';

@Component({
  selector: 'astrade-form-field-price-type-for-ozon',
  template: `
    <mat-form-field fxFlex>
      <mat-label>{{label}}</mat-label>
      <input type="text" matInput [formControl]="control" [matAutocomplete]="auto" />
      <button type="button" [disabled]="control.value === ''" mat-icon-button matSuffix (click)="reset()"><mat-icon>cancel</mat-icon></button>
      <button type="button" *ngIf="(options$ | async).length > 0" mat-icon-button matSuffix (click)="openDropDownList()"><mat-icon>arrow_drop_down</mat-icon></button>
      <button type="button" *ngIf="hint" [matTooltip]="hint" matTooltipClass="hint" mat-icon-button matSuffix><mat-icon>help</mat-icon></button>
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="renderValue">
        <mat-option *ngFor="let option of options$ | async" [value]="option.value">
          <span>{{option.name}}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  `
})
export class FormFieldPriceTypeForOzonComponent extends FormFieldAbstractComponent {

  @ViewChild(MatAutocompleteTrigger, {read: MatAutocompleteTrigger}) inputAutoComplete: MatAutocompleteTrigger;

  private static options = [{
    name: 'Вручную (Цена для Ozon)',
    value: 'manual'
  }, {
    name: 'Наша минимальная',
    value: 'ourMin'
  }, {
    name: 'Парсер минимальная',
    value: 'parserMin'
  }];

  options$ = of(FormFieldPriceTypeForOzonComponent.options);

  constructor() {
    super();
  }

  reset(): void {
    this.control.setValue('');
  }

  openDropDownList(): void {
    this.inputAutoComplete.openPanel();
  }

  renderValue(value: string): string {
    return FormFieldPriceTypeForOzonComponent.options.find(option => option.value == value)?.name ?? value;
  }
}
