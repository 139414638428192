import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Product, SupplierPriceListImportTask } from '@astrade/core';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { FormControl } from '@angular/forms';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import {
  EditButtonCellRendererComponent,
  IEditButtonCellRendererParams
} from '../../ag-grid/edit-button-cell-renderer/edit-button-cell-renderer.component';
import { Store } from '@ngrx/store';
import { State } from '../../../../../../apps/console/src/app/state/reducers';
import { Observable } from 'rxjs';
import * as supplierPriceListImportTasksActions from '../../../../../../apps/console/src/app/state/supplier-price-list-import-tasks.actions';
import { MatDialog } from '@angular/material/dialog';
import { SupplierPriceListUploadDialogComponent } from '../supplier-price-list-upload-dialog/supplier-price-list-upload-dialog.component';
import {AG_GRID_COLUMN_TYPES} from '../../ag-grid/ag-grid-column-types';

@Component({
  selector: 'astrade-supplier-price-import-tasks-list',
  template: `
    <div fxLayout="column" fxFlexFill class="container">
      <div fxLayout="row">
        <mat-form-field fxFlexOffset="1">
          <mat-label>Поиск</mat-label>
          <input matInput type="text" [formControl]="searchQuery"/>
        </mat-form-field>
      </div>
      <mat-toolbar>
        <button type="button" mat-button (click)="onAddSupplierPriceList()">
          Добавить прайс
        </button>
      </mat-toolbar>
      <div fxFlex>
        <ag-grid-angular
          rowSelection="single"
          style="height: 100%"
          class="ag-theme-alpine"
          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [columnTypes]="columnTypes"
          [rowData]="supplierPriceListImportTasks$ | async"
          [getRowNodeId]="getRowNodeId"
          [quickFilterText]="searchQuery.valueChanges | async"
          [modules]="modules"
          [statusBar]="statusBar"
          (gridReady)="onGridReady($event)"
          editType="fullRow"
          immutableData="true"
          suppressMaintainUnsortedOrder="true"
          suppressDragLeaveHidesColumns="true"
        ></ag-grid-angular>
      </div>
    </div>
  `
})
export class SupplierPriceListImportTasksListComponent implements OnInit {

  @Output() editButtonClick = new EventEmitter<Product>();

  supplierPriceListImportTasks$: Observable<unknown[] | undefined> = this.store.select('supplierPriceListImportTasks');

  modules = AllModules;

  /**
   * Quick search term.
   */
  searchQuery = new FormControl('');

  defaultColDef: ColDef = {
    sortable: true,
    filter: 'agSetColumnFilter',
    resizable: true
  };

  columnTypes = AG_GRID_COLUMN_TYPES;

  private defaultColumnDefs: ColDef[] = [{
    width: 80,
    cellRendererFramework: EditButtonCellRendererComponent,
    cellRendererParams: {
      onClick: rowNode => this.editButtonClick.emit(rowNode.data)
    } as IEditButtonCellRendererParams
  }, {
    headerName: 'Старт',
    field: 'createdAt',
    type: 'timestamp',
    initialWidth: 160,
    sortable: true,
    sort: 'desc'
  }, {
    headerName: 'ID',
    field: 'id',
    initialWidth: 400
  }, {
    headerName: 'Статус',
    field: 'status',
    initialWidth: 200
  }, {
    headerName: 'Статус обновлён',
    field: 'statusUpdatedAt',
    type: 'timestamp',
    initialWidth: 160
  }, {
    headerName: 'Ошибка',
    field: 'error',
    initialWidth: 400
  }, {
    headerName: 'Количество товаров',
    field: 'itemsCount',
    initialWidth: 200
  }, {
    headerName: 'Применено цен',
    field: 'appliedPricesCount',
    initialWidth: 200
  },{
    headerName: 'Источник',
    field: 'source',
    initialWidth: 150
  }, {
    headerName: 'Email отправителя',
    field: 'sourceEmailInfo.fromAddress',
    initialWidth: 150
  }, {
    headerName: 'Тема письма',
    field: 'sourceEmailInfo.subject',
    initialWidth: 150
  }, {
    headerName: 'Дата письма',
    field: 'sourceEmailInfo.date',
    initialWidth: 150
  }, {
    headerName: 'Название вложения',
    field: 'sourceEmailInfo.attachmentName',
    initialWidth: 150
  }];

  /**
   * Grid column definitions.
   */
  columnDefs: ColDef[] = [];

  statusBar = {
    statusPanels: [{
      statusPanel: 'agTotalAndFilteredRowCountComponent',
      align: 'left',
    }, {
      statusPanel: 'agTotalRowCountComponent',
      align: 'center',
    }, {
      statusPanel: 'agFilteredRowCountComponent'
    }, {
      statusPanel: 'agSelectedRowCountComponent'
    }, {
      statusPanel: 'agAggregationComponent'
    }],
  };

  /**
   * ag-Grid API.
   */
  private gridApi: GridApi;

  constructor(
    private readonly store: Store<State>,
    private readonly dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.store.dispatch(supplierPriceListImportTasksActions.getAll())
  }

  /**
   * ag-Grid ready event handler.
   * @param event
   */
  onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    this.columnDefs = [...this.defaultColumnDefs];
    this.gridApi.setColumnDefs(this.columnDefs);
  }

  /**
   * Returns row node ID from the product.
   * @param data Product.
   */
  getRowNodeId(data: SupplierPriceListImportTask): string {
    return data.id as string;
  }

  onAddSupplierPriceList(): void {
    this.dialog.open(SupplierPriceListUploadDialogComponent, {
      width: '100%',
      height: 'auto'
    });
  }
}
