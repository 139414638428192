import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'astrade-login-form',
  template: `
    <div style="height: 100vh; display: flex; justify-content: center; align-items: center">
      <mat-card>
        <mat-card-content>
          Войдите в систему
        </mat-card-content>
        <mat-card-actions align="center">
          <button type="button" mat-raised-button (click)="onLoginClick('google')">
            <mat-icon>login</mat-icon>
            Google
          </button>
          <!--
          <button type="button" mat-raised-button (click)="onLoginClick('oidc.yandex')">
            <mat-icon>login</mat-icon>
            Yandex
          </button>
          <button type="button" mat-raised-button (click)="onLoginClick('apple')">
            Apple
          </button>
          <button type="button" mat-raised-button (click)="onLoginClick('facebook')">
            Facebook
          </button>
          <button type="button" mat-raised-button (click)="onLoginClick('twitter')">
            Twitter
          </button>
          <button type="button" mat-raised-button (click)="onLoginClick('yahoo')">
            Yahoo
          </button>
          -->
        </mat-card-actions>
      </mat-card>
    </div>
  `,
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  @Output() loginClick = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  onLoginClick(provider: string): void {
    this.loginClick.emit(provider);
  }
}
