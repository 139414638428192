<div style="margin-top: 20px; margin-bottom: 20px">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Выгрузка в ozon.ru
      </mat-panel-title>
      <mat-panel-description>
        Статус: {{ (ozonExportTask$ | async)?.status | ozonExportStatus }}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div>
      <div>ID товара Ozon: </div>
      <div fxFlexOffset="2" *ngIf="(ozonExportTask$ | async)?.id as taskId">Задача выгрузки в Ozon: {{ taskId }}</div>
      <div fxFlexOffset="2">Дата и время статуса: {{ (ozonExportTask$ | async)?.statusTimestamp | timestampToDate | date:'yyyy.MM.dd HH:mm:ss'}}</div>
    </div>
    <div *ngIf="(ozonExportTask$ | async)?.ozonProductId && (ozonNativeProduct$ | async)?.errors as errors" style="margin-top: 20px">
      Ошибки выгрузки:
      <ul>
        <li *ngFor="let error of errors">{{ error.level }}: {{ error.field }} - {{ error.code }}</li>
      </ul>
    </div>
    <mat-action-row>
      <button fxFlexOffset="2" type="button" mat-raised-button [disabled]="!product?.id || exporting" (click)="exportProduct()">{{exporting ? 'Выгружается...' : 'Выгрузить в Ozon'}}</button>
      <button fxFlexOffset="2" type="button" mat-raised-button [disabled]="!(ozonExportTask$ | async) || checkingStatus || exporting" (click)="checkExportProductStatus()">{{checkingStatus ? 'Проверяется...' : 'Проверить результат'}}</button>
    </mat-action-row>
  </mat-expansion-panel>
</div>

