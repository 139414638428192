import {
  AbstractControlOptions,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {
  OzonAttribute,
  OzonProductPropertyValue
} from '@astrade/core';

export class OzonPropertiesFormGroup extends FormGroup {
  ozonCategoryAttributes: OzonAttribute[];

  constructor(
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super({}, validatorOrOpts, asyncValidator);
  }

  generateControlsFromCategoryAttributes(ozonCategoryAttributes: OzonAttribute[]) {
    this.ozonCategoryAttributes = ozonCategoryAttributes;
    ozonCategoryAttributes?.forEach(categoryAttribute => {
      const validators = categoryAttribute.isRequired ? Validators.required : [];
      const control = categoryAttribute.dictionaryId ? new FormControl('', validators) : new FormControl('', validators);
      this.addControl(categoryAttribute.id.toString(), control);
    });
  }

  setValuesFromOzonProductPropertyValues(ozonProductPropertyValues: {[key: number]: OzonProductPropertyValue}) {
    this.markAllAsTouched();
    if (!ozonProductPropertyValues) {
      return;
    }

    Object.entries(ozonProductPropertyValues).forEach(([key, ozonProductPropertyValue]) => {
      this.get(key)?.setValue(ozonProductPropertyValue.value, {emitEvent: false});
    });
  }

  applyValuesToOzonProductProperties(productPropertyValues: {[key: number]: OzonProductPropertyValue}): void {
    if (!this.ozonCategoryAttributes) {
      console.log('applyValuesToOzonProductProperties(). Ozon category attributes list is undefined or null.');
      return;
    }

    if (productPropertyValues === undefined || productPropertyValues === null) {
      console.log('applyValuesToOzonProductProperties(). Ozon product properties list is undefined or null.');
      return;
    }

    for (const [id, formControl] of Object.entries(this.controls)) {
      if (!productPropertyValues[id]) {
        let propertyMetadata = this.ozonCategoryAttributes.find(property => property.id === Number(id));
        productPropertyValues[id] = {
          propertyMetadata,
          value: formControl.value
        }
      } else {
        productPropertyValues[id].value = formControl.value;
      }
    }
  }
}
