import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CategoryTree } from '@astrade/core';
import { CategorySelectDialogComponent } from '../category-select-dialog/category-select-dialog.component';
import { Observable } from 'rxjs';
import { GubkaCategoryFormGroup } from '../../form/controls/product-main-properties-form-group/gubka-category-form-group/gubka-category-form-group';

@Component({
  selector: 'astrade-category-form-field-with-dialog',
  template: `
    <astrade-category-form-field
      [categoryFormGroup]="categoryFormGroup"
      (openDialogClick)="openDialog()"
    ></astrade-category-form-field>
  `
})
export class CategoryFormFieldWithDialogComponent implements OnInit {
  @Input() categoryFormGroup: GubkaCategoryFormGroup;
  @Input() categories$: Observable<CategoryTree[]>;

  constructor(private readonly dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CategorySelectDialogComponent, {
      width: '100%',
      height: 'auto',
      data: {
        categories$: this.categories$,
        categoryId: this.categoryFormGroup.controls.id.value,
        categoryName: this.categoryFormGroup.controls.name.value
      }
    });

    dialogRef.afterClosed().subscribe((result: CategoryTree) => {
      const newCategoryId = result.id ?? '';
      if (this.categoryFormGroup.controls.id.value !== newCategoryId) {
        this.categoryFormGroup.controls.id.setValue(newCategoryId);
      }

      const newCategoryName = result.name ?? '';
      if (this.categoryFormGroup.controls.name.value !== newCategoryName) {
        this.categoryFormGroup.controls.name.setValue(newCategoryName);
      }
    });
  }
}
