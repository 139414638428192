import { Component, Input, OnInit } from '@angular/core';
import { ProductAdditionalPropertiesFormGroup } from '../../controls/product-additional-properties-form-group';
import { Product, ProductPropertyMetadata } from '@astrade/core';
import { AdditionalPropertiesService } from './additional-properties.service';

@Component({
  selector: 'astrade-additional-properties-form',
  templateUrl: './additional-properties-form.component.html',
  styleUrls: ['./additional-properties-form.component.scss']
})
export class AdditionalPropertiesFormComponent implements OnInit {
  @Input() additionalPropertiesFormGroup: ProductAdditionalPropertiesFormGroup;
  @Input('product') set setProduct(product: Product) {
    this.product = product;
    this.additionalPropertiesFormGroup.setValuesFromProduct(this.product);
  }

  product: Product;
  additionalProperties: Record<string, ProductPropertyMetadata>;

  constructor(private readonly additionalPropertiesService: AdditionalPropertiesService) { }

  ngOnInit(): void {
    this.additionalPropertiesService.getAll().subscribe(properties => {
      this.additionalPropertiesFormGroup.generateControlsFromAdditionalPropertyValues(properties);
      this.additionalPropertiesFormGroup.setValuesFromProduct(this.product);

      const propertiesMap: Record<string, ProductPropertyMetadata> = {};
      properties.forEach(property => {
        propertiesMap[property.id] = property;
      });
      this.additionalProperties = propertiesMap;
    });
  }
}
