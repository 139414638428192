<form fxFlexOffset="20px" fxLayout="column" [formGroup]="form">
  <h2>Товар: {{ product?.name }}</h2>

  <input type="hidden" [formControl]="form.controls.mainProperties.controls.id"/>

  <mat-form-field fxFlex>
    <mat-label>Полное наименование</mat-label>
    <input matInput type="text" [formControl]="form.controls.mainProperties.controls.fullName" disabled readonly/>
  </mat-form-field>

  <mat-form-field fxFlex>
    <mat-label>Наименование ритейл</mat-label>
    <input matInput type="text" [formControl]="form.controls.mainProperties.controls.name"/>
  </mat-form-field>

  <div fxLayout="row">
    <mat-form-field fxFlex>
      <mat-label>Штрихкод</mat-label>
      <input matInput type="text" [formControl]="form.controls.mainProperties.controls.barcode" disabled readonly/>
    </mat-form-field>

    <mat-form-field fxFlex fxFlexOffset="2">
      <mat-label>Артикул</mat-label>
      <input matInput type="text" [formControl]="form.controls.mainProperties.controls.article" disabled readonly/>
    </mat-form-field>
  </div>

  <h3>Фотографии</h3>
  <astrade-product-photos-list
    style="margin-bottom: 20px"
    [photos]="product?.photos"
    (photosChange)="onPhotosChange($event)"
    fxFlex
  ></astrade-product-photos-list>

  <mat-tab-group style="margin-bottom: 20px">
    <mat-tab>
      <ng-template mat-tab-label>
        Дополнительные свойства
        <mat-icon *ngIf="form.controls.additionalProperties.valid" style="font-size: 16px; color: darkgreen" class="example-tab-icon">check_circle</mat-icon>
        <mat-icon *ngIf="!form.controls.additionalProperties.valid" style="font-size: 16px; color: darkred" class="example-tab-icon">error</mat-icon>
      </ng-template>
      <div class="tab-content">
        <astrade-additional-properties-form [additionalPropertiesFormGroup]="form.controls.additionalProperties" [product]="product"></astrade-additional-properties-form>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        Gubka
        <mat-icon *ngIf="form.controls.gubkaProperties.valid" style="font-size: 16px; color: darkgreen" class="example-tab-icon">check_circle</mat-icon>
        <mat-icon *ngIf="!form.controls.gubkaProperties.valid" style="font-size: 16px; color: darkred" class="example-tab-icon">error</mat-icon>
      </ng-template>
      <div class="tab-content">
        <astrade-category-form-field-with-dialog
          [categoryFormGroup]="form.controls.mainProperties.controls.gubkaCategory"
          [categories$]="categories$"
        ></astrade-category-form-field-with-dialog>
        <astrade-product-property-field
          *ngFor="let property of gubkaCategoryProperties"
          [productProperty]="property"
          [control]="form.controls.gubkaProperties.get(property?.id)"
        ></astrade-product-property-field>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        Ozon
        <mat-icon *ngIf="form.controls.ozonProperties.valid && form.controls.mainProperties.controls.ozonCategory.valid" style="font-size: 16px; color: darkgreen" class="example-tab-icon">check_circle</mat-icon>
        <mat-icon *ngIf="!(form.controls.ozonProperties.valid && form.controls.mainProperties.controls.ozonCategory.valid)" style="font-size: 16px; color: darkred" class="example-tab-icon">error</mat-icon>
      </ng-template>
      <div class="tab-content">
        <astrade-ozon-product-export [product]="product"></astrade-ozon-product-export>
        <astrade-form-field-text
          [control]="form.controls.mainProperties.controls.ozonBarcode"
          label="Штрихкод Ozon"
          hint="Дополнительный штрихкод товара только для внутреннего использования в Ozon. В случае, если оригинальный штрихкод, занят другим товаром в каталоге Ozon."
        ></astrade-form-field-text>
        <astrade-ozon-category-form-field-with-dialog
          [categoryFormGroup]="form.controls.mainProperties.controls.ozonCategory"
        ></astrade-ozon-category-form-field-with-dialog>
        <astrade-ozon-product-edit-form [categoryId]="form.controls.mainProperties.controls.ozonCategory.controls.id.value" [ozonFormGroup]="form.controls.ozonProperties" [product]="product"></astrade-ozon-product-edit-form>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        Ценообразование
        <mat-icon *ngIf="form.controls.priceManagerProperties.valid" style="font-size: 16px; color: darkgreen" class="example-tab-icon">check_circle</mat-icon>
        <mat-icon *ngIf="!form.controls.priceManagerProperties.valid" style="font-size: 16px; color: darkred" class="example-tab-icon">error</mat-icon>
      </ng-template>
      <div class="tab-content">
        <astrade-price-management
          [priceManagerPropertiesFormGroup]="form.controls.priceManagerProperties"
          [product$]="product$"
        ></astrade-price-management>
      </div>
    </mat-tab>
  </mat-tab-group>

  <button type="button" mat-raised-button (click)="onSave()" [disabled]="saveInProgress">{{ saveInProgress ? 'Идет сохранение...' : 'Сохранить'}}</button>
  <div>
    &nbsp;
  </div>
</form>
