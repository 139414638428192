import { Component, Input } from '@angular/core';
import { OzonAttribute, Product } from '@astrade/core';
import { OzonPropertiesFormGroup } from '../../form/controls/ozon-properties-form-group';
import { OzonCategoryService } from '../ozon-category.service';

@Component({
  selector: 'astrade-ozon-product-edit-form',
  template: `
    <astrade-ozon-product-property-field *ngFor="let attribute of categoryAttributes" [categoryId]="categoryId" [productProperty]="attribute" [control]="ozonFormGroup.get(attribute.id?.toString())"></astrade-ozon-product-property-field>
  `
})
export class OzonProductEditFormComponent {

  @Input('categoryId') set setCategoryId(categoryId: number) {
    this.categoryId = categoryId;

    if (!this.categoryId) {
      this.categoryAttributes = [];
      return;
    }

    this.ozonCategoryService.get(this.categoryId).subscribe(ozonCategory => {
      const hideAttributes: number[] = [
        85, // Бренд
        4194, // Изображение
        4180, // Название
        4191, // Аннотация
        4195, // Изображения
        4389, // Страна-изготовитель
        4497, // Вес с упаковкой
        9024, // Артикул
        4082, // Размеры в упаковке
        4382, // Размеры без упаковки
      ];
      this.categoryAttributes = ozonCategory.attributes.filter(attribute => {
        return !hideAttributes.includes(attribute.id);
      });

      this.ozonFormGroup.generateControlsFromCategoryAttributes(this.categoryAttributes);
      if (this.product) {
        this.ozonFormGroup.setValuesFromOzonProductPropertyValues(this.product.ozonProperties);
      }
    });
  };

  @Input() ozonFormGroup: OzonPropertiesFormGroup;

  @Input('product') set setProduct(product: Product) {
    this.product = product;
    this.ozonFormGroup.setValuesFromOzonProductPropertyValues(this.product?.ozonProperties);
  }

  categoryId: number;
  product: Product;
  categoryAttributes: OzonAttribute[];

  constructor(private readonly ozonCategoryService: OzonCategoryService) { }
}
