import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

export interface FileUploadProgress {
  /**
   * Upload status.
   */
  status: 'uploading' | 'done' | 'error';

  /**
   * Upload error message.
   */
  errorMessage?: string;

  /**
   * Uploaded file UUID.
   */
  fileId?: string;

  /**
   * File name = fileId(UUID) + .extension.
   * e.g. 1b37587e-5042-4c01-aa91-621d45dc86ad.xlsx
   */
  filename?: string;

  /**
   * Upload progress percent.
   */
  uploadPercent?: Observable<number>;
}

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor(
    private readonly angularFireStorage: AngularFireStorage,
  ) { }

  public uploadFile(bucket: string, file: File): Observable<FileUploadProgress> {
    return new Observable<FileUploadProgress>(subscriber => {
      // Workaround to change the bucket https://github.com/angular/angularfire/issues/1996
      (this.angularFireStorage as any).storage = this.angularFireStorage.storage.app.storage(bucket);

      const fileId = uuidv4();
      const destinationPath = fileId + '.' + UploadService.getFilenameExtension(file.name);
      const task = this.angularFireStorage.upload(destinationPath, file);
      subscriber.next({
        status: 'uploading',
        uploadPercent: task.percentageChanges(),
        fileId: fileId,
        filename: destinationPath
      });

      task.snapshotChanges().pipe(
        finalize(() => {
          subscriber.next({
            status: 'done',
            fileId: fileId,
            filename: destinationPath
          });
          subscriber.complete();
        })
      ).subscribe(
        () => {},
        error => {
          subscriber.next({
            status: 'error',
            fileId: fileId,
            filename: destinationPath,
            errorMessage: error.message
          });
          subscriber.complete();
        }
      )
    });
  }

  public static getFilenameExtension(filename: string): string {
    return filename ? filename.split('.').pop() : '';
  }
}
