import { Pipe, PipeTransform } from '@angular/core';
import firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;
import { firebaseTimestampToDate } from '@astrade/core';

@Pipe({
  name: 'timestampToDate'
})
export class TimestampToDatePipe implements PipeTransform {
  transform(value: Timestamp | Date | undefined, ...args: unknown[]): Date | undefined {
    return firebaseTimestampToDate(value as unknown as Timestamp) ?? value as Date;
  }
}
