/**
 * Converts column literal name to index starting from 0.
 * @param column Literal column name. E.g. 'A', 'AA'.
 * @returns Column index starting from 0.
 */
export function columnToIndex(column: string): number {
  return column
    .split('')
    .reduce((previousValue, currentValue) => {
      return previousValue * 26 + parseInt(currentValue, 36) - 9
    }, 0) - 1;
}
