import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OzonDictionaryValue } from '@astrade/core';

@Component({
  selector: 'astrade-ozon-dictionary-values-list',
  templateUrl: './ozon-dictionary-values-list.component.html',
  styleUrls: ['./ozon-dictionary-values-list.component.scss']
})
export class OzonDictionaryValuesListComponent implements OnInit {

  @Output() selectionChange = new EventEmitter<OzonDictionaryValue>();

  constructor() { }

  ngOnInit(): void {
  }

}
