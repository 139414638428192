<ng-container *ngIf="additionalProperties">
  <!-- Описание -->
  <astrade-product-property-field
    [productProperty]="additionalProperties['8fd7c301-7409-4e72-abcc-050dfb9cc534']"
    [control]="additionalPropertiesFormGroup?.get(additionalProperties['8fd7c301-7409-4e72-abcc-050dfb9cc534']?.id)"
  ></astrade-product-property-field>

  <div fxLayout="row">
    <!-- Бренд -->
    <astrade-product-property-field fxFlex
      [productProperty]="additionalProperties['5b43b7c7-fd8c-4f77-a5d4-a6553ea1bb42']"
      [control]="additionalPropertiesFormGroup?.get(additionalProperties['5b43b7c7-fd8c-4f77-a5d4-a6553ea1bb42']?.id)"
    ></astrade-product-property-field>

    <!-- Страна производства -->
    <astrade-product-property-field fxFlex fxFlexOffset="2"
      [productProperty]="additionalProperties['861f2ad5-bf9c-47fb-9dc5-809535c5f912']"
      [control]="additionalPropertiesFormGroup?.get(additionalProperties['861f2ad5-bf9c-47fb-9dc5-809535c5f912']?.id)"
    ></astrade-product-property-field>
  </div>

  <!-- Производитель -->
  <astrade-product-property-field
    [productProperty]="additionalProperties['135eae29-fe8d-4200-8203-76ee7549b571']"
    [control]="additionalPropertiesFormGroup?.get(additionalProperties['135eae29-fe8d-4200-8203-76ee7549b571']?.id)"
  ></astrade-product-property-field>

  <!-- Ссылка на доп. фото -->
  <astrade-product-property-field
    [productProperty]="additionalProperties['bc02e738-62ca-4ea9-ae0c-463c5296e688']"
    [control]="additionalPropertiesFormGroup?.get(additionalProperties['bc02e738-62ca-4ea9-ae0c-463c5296e688']?.id)"
  ></astrade-product-property-field>

  <div fxLayout="row">
    <!-- Масса (брутто), г -->
    <astrade-product-property-field fxFlex
      [productProperty]="additionalProperties['804c83dd-8e3f-47c4-b4f1-a8201efd595c']"
      [control]="additionalPropertiesFormGroup?.get(additionalProperties['804c83dd-8e3f-47c4-b4f1-a8201efd595c']?.id)"
    ></astrade-product-property-field>

    <!-- Длина в упаковке, мм -->
    <astrade-product-property-field fxFlex fxFlexOffset="2"
      [productProperty]="additionalProperties['0950022b-8f26-433d-8122-f2856f037822']"
      [control]="additionalPropertiesFormGroup?.get(additionalProperties['0950022b-8f26-433d-8122-f2856f037822']?.id)"
    ></astrade-product-property-field>

    <!-- Ширина в упаковке, мм -->
    <astrade-product-property-field fxFlex fxFlexOffset="2"
      [productProperty]="additionalProperties['2ad79dc1-6337-47e5-a172-63ab4b0a0b9e']"
      [control]="additionalPropertiesFormGroup?.get(additionalProperties['2ad79dc1-6337-47e5-a172-63ab4b0a0b9e']?.id)"
    ></astrade-product-property-field>

    <!-- Высота в упаковке, мм -->
    <astrade-product-property-field fxFlex fxFlexOffset="2"
      [productProperty]="additionalProperties['d331f2e0-0c53-4b3c-b331-f5caed331885']"
      [control]="additionalPropertiesFormGroup?.get(additionalProperties['d331f2e0-0c53-4b3c-b331-f5caed331885']?.id)"
    ></astrade-product-property-field>
  </div>

  <div fxLayout="row">
    <!-- Масса (нетто), г -->
    <astrade-product-property-field fxFlex
      [productProperty]="additionalProperties['59066edd-6f67-4a41-a31d-de2f2d332a71']"
      [control]="additionalPropertiesFormGroup?.get(additionalProperties['59066edd-6f67-4a41-a31d-de2f2d332a71']?.id)"
    ></astrade-product-property-field>

    <!-- Длина, мм -->
    <astrade-product-property-field fxFlex fxFlexOffset="2"
      [productProperty]="additionalProperties['3d540c68-1b9d-46fc-9d47-9f2376aaabc7']"
      [control]="additionalPropertiesFormGroup?.get(additionalProperties['3d540c68-1b9d-46fc-9d47-9f2376aaabc7']?.id)"
    ></astrade-product-property-field>

    <!-- Ширина, мм -->
    <astrade-product-property-field fxFlex fxFlexOffset="2"
      [productProperty]="additionalProperties['3cffd163-0f32-4249-ac9f-deec89d0822a']"
      [control]="additionalPropertiesFormGroup?.get(additionalProperties['3cffd163-0f32-4249-ac9f-deec89d0822a']?.id)"
    ></astrade-product-property-field>

    <!-- Высота (толщина), мм -->
    <astrade-product-property-field fxFlex fxFlexOffset="2"
      [productProperty]="additionalProperties['42f9b2b5-bf1a-4771-b367-4f52b43ef84c']"
      [control]="additionalPropertiesFormGroup?.get(additionalProperties['42f9b2b5-bf1a-4771-b367-4f52b43ef84c']?.id)"
    ></astrade-product-property-field>
  </div>

  <div fxLayout="row">
    <!-- ABC -->
    <astrade-product-property-field
      fxFlex
      [productProperty]="additionalProperties['a44f49f5-65b4-4b46-bdab-5a7d70398ff0']"
      [control]="additionalPropertiesFormGroup?.get(additionalProperties['a44f49f5-65b4-4b46-bdab-5a7d70398ff0']?.id)"
    ></astrade-product-property-field>
  </div>
</ng-container>
