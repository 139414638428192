import { AbstractControlOptions, AsyncValidatorFn, FormGroup, ValidatorFn } from '@angular/forms';
import { ProductMainPropertiesFormGroup } from './product-main-properties-form-group/product-main-properties-form-group';
import { GubkaPropertiesFormGroup } from './gubka-properties-form-group';
import { OzonPropertiesFormGroup } from './ozon-properties-form-group';
import { Product } from '@astrade/core';
import { ProductAdditionalPropertiesFormGroup } from './product-additional-properties-form-group';
import { ProductPriceManagerPropertiesFormGroup } from './product-price-manager-properties-form-group';

export class ProductFormGroup extends FormGroup {
  controls: {
    mainProperties: ProductMainPropertiesFormGroup,
    additionalProperties: ProductAdditionalPropertiesFormGroup,
    gubkaProperties: GubkaPropertiesFormGroup,
    ozonProperties: OzonPropertiesFormGroup,
    priceManagerProperties: ProductPriceManagerPropertiesFormGroup
  }

  constructor(
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    const controls = {
      mainProperties: new ProductMainPropertiesFormGroup(),
      additionalProperties: new ProductAdditionalPropertiesFormGroup(),
      gubkaProperties: new GubkaPropertiesFormGroup(),
      ozonProperties: new OzonPropertiesFormGroup(),
      priceManagerProperties: new ProductPriceManagerPropertiesFormGroup()
    };
    super(controls, validatorOrOpts, asyncValidator);
  }

  applyValuesToProduct(product: Product): Product {
    this.controls.mainProperties.applyValuesToProduct(product);

    if (!product.properties) {
      product.properties = {};
    }
    this.controls.additionalProperties.applyValuesToProductProperties(product.properties);
    this.controls.gubkaProperties.applyValuesToProductProperties(product.properties);
    this.controls.priceManagerProperties.applyValuesToProductProperties(product.properties);

    if (!product.ozonProperties) {
      product.ozonProperties = {};
    }
    this.controls.ozonProperties.applyValuesToOzonProductProperties(product.ozonProperties);

    return product;
  }
}
