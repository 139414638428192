import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Dictionary, DictionaryValue } from '@astrade/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DictionaryService {

  constructor(private readonly firestore: AngularFirestore) { }

  getInfo(dictionaryId: string): Observable<Dictionary> {
    return this.firestore
      .collection<Dictionary>('astrade-dictionary')
      .doc<Dictionary>(dictionaryId)
      .valueChanges();
  }

  getInitialValues(dictionaryId: string): Observable<DictionaryValue[]> {
    return this.firestore
      .collection('astrade-dictionary')
      .doc(dictionaryId)
      .collection<DictionaryValue>('values', ref => ref.limit(100))
      .valueChanges();
  }

  getAllValues(dictionaryId: string): Observable<DictionaryValue[]> {
    return this.firestore
      .collection('astrade-dictionary')
      .doc(dictionaryId)
      .collection<DictionaryValue>('values')
      .valueChanges();
  }

  search(dictionaryId: string, searchTerm: string): Observable<DictionaryValue[]> {
    return this.getAllValues(dictionaryId)
      .pipe(
        map(value => value.filter(
          dictionaryValue => dictionaryValue.name?.toLowerCase().includes(searchTerm.toLowerCase())
        ))
      );
  }

  save(dictionaryId: string, value: DictionaryValue): Promise<void> {
    return this.firestore
      .collection('astrade-dictionary')
      .doc(dictionaryId)
      .collection('values')
      .doc(value.id)
      .set(value, {merge: true});
  }
}
