import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProfileFormGroup} from './profile-form-group';
import {Profile} from './profile';
import {BehaviorSubject} from 'rxjs';
import {ObjectHelper} from '../../../../../../libs/ui/src/lib/object-helper';

@Component({
  selector: 'astrade-dropship-profile',
  templateUrl: './dropship-profile.component.html',
  styleUrls: ['./dropship-profile.component.scss']
})
export class DropshipProfileComponent implements OnInit {
  @Input('profile') set setProfile(profile: Profile) {
    this.profile = profile;
    this.profile$.next(profile);
    this.setFormData(profile);
  }

  @Input() saveInProgress: boolean = false;
  @Output() saveClick = new EventEmitter<Profile>();

  profile: Profile;
  profile$: BehaviorSubject<Profile> = new BehaviorSubject<Profile>(undefined);

  form = new ProfileFormGroup();

  constructor() { }

  ngOnInit(): void {
  }

  private setFormData(profile: Profile): void {
    if (!this.form) {
      return;
    }

    this.form.setValuesFromProfile(profile);
  }

  onSave() {
    this.saveClick.emit(this.form.applyValuesToProfile(ObjectHelper.deepCopy(this.profile)));
  }
}
