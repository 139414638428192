<ng-container *ngIf="user else loginButton">
  <p><button type="button" mat-button>{{user.displayName}}</button></p>
  <p><button type="button" mat-button (click)="logoutClick.emit()">Выйти</button></p>
  <p><button type="button" mat-button routerLink="/profile">Профиль</button></p>
  <ng-container *ngIf="idTokenResult">
    <p *ngIf="idTokenResult?.claims?.manager"><button type="button" mat-button routerLink="/products">Товары</button></p>
  </ng-container>
</ng-container>
<ng-template #loginButton>
  <p><button mat-button routerLink="/login" (click)="loginClick.emit()">Войти</button></p>
</ng-template>
