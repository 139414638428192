import { Component } from '@angular/core';
import { FormFieldAbstractComponent } from '../form-field-abstract/form-field-abstract.component';

@Component({
  selector: 'astrade-form-field-checkbox',
  template: `
    <mat-form-field fxFlex>
      <mat-label>{{ label }}</mat-label>
      <input matInput type="checkbox" [formControl]="control"/>
    </mat-form-field>
  `
})
export class FormFieldCheckboxComponent extends FormFieldAbstractComponent {

}
