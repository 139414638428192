import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { CdkDrag, CdkDragMove, CdkDropList, CdkDropListGroup, moveItemInArray } from '@angular/cdk/drag-drop';
import { ViewportRuler } from '@angular/cdk/overlay';
import { ImageService } from '../../../../../../../apps/console/src/app/services/image.service';

@Component({
  selector: 'astrade-product-photos-list',
  templateUrl: './product-photos-list.component.html',
  styleUrls: ['./product-photos-list.component.scss']
})
export class ProductPhotosListComponent implements OnInit, AfterViewInit {

  @Input() photos: string[];

  /**
   * Photo has been deleted.
   * Parameter = index of the deleted photo in the array and photo URL.
   */
  @Output() photoDeleted = new EventEmitter<{ index: number; photo: string }>();

  /**
   * Photo has been uploaded.
   * Parameter - original photo base filename.
   *
   * Emits only in case photoUploadFn is set and photo has been successfully uploaded.
   */
  @Output() photoUploaded = new EventEmitter<string>();

  /**
   * Photos array have been changed.
   * Parameter = changed photos array.
   */
  @Output() photosChange = new EventEmitter<string[]>();

  @ViewChild('fileUpload') fileUploadElement: ElementRef;
  uploadPercent: Observable<number>;
  uploadStatus: string;

  @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList) placeholder: CdkDropList;

  public target: CdkDropList = null;
  public targetIndex: number;
  public source: CdkDropList = null;
  public sourceIndex: number;
  public activeContainer;

  constructor(
    private readonly viewportRuler: ViewportRuler,
    private readonly imageService: ImageService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const phElement = this.placeholder.element.nativeElement;
    phElement.style.display = 'none';
    phElement.parentElement.removeChild(phElement);
  }

  add(): void {
    if (this.uploadStatus && this.uploadStatus !== 'done' && this.uploadStatus !== 'error') {
      return;
    }

    if (!this.fileUploadElement.nativeElement) {
      return;
    }
    (this.fileUploadElement.nativeElement as HTMLInputElement).click();
  }

  delete(index: number): void {
    this.photos = this.photos?.filter((photo, i) => {
      if (i === index) {
        this.photoDeleted.emit({
          index,
          photo
        });

        return false;
      }
      else {
        return true;
      }
    }) ?? [];
    this.onPhotosChange();
  }

  trackByPhotoUrl(index, item): string {
    return item;
  }

  onFileUploadChange(event: Event): void {
    const {files} = event.target as HTMLInputElement;
    if (!files || files.length === 0) {
      return;
    }

    const file = files.item(0);
    if (!file.name) {
      return;
    }

    this.imageService.upload(file).subscribe(value => {
      this.uploadPercent = value.uploadPercent;
      this.uploadStatus = value.status;
      if (this.uploadStatus === 'done' || this.uploadStatus === 'error') {
        (this.fileUploadElement.nativeElement as HTMLInputElement).value = '';
      }

      if (this.uploadStatus === 'done') {
        this.photos.push(value.originalFilename);
        this.photoUploaded.emit(value.originalFilename);
        this.onPhotosChange();
      }
    });
  }

  dragMoved(e: CdkDragMove): void {
    const point = this.getPointerPositionOnPage(e.event);
    this.listGroup._items.forEach(dropList => {
      if (__isInsideDropListClientRect(dropList, point.x, point.y)) {
        this.activeContainer = dropList;
        return;
      }
    });
  }

  onPhotosChange(): void {
    this.photosChange.emit(this.photos);
  }

  dropListDropped(event): void {
    if (!this.target) {
      return;
    }

    const phElement = this.placeholder.element.nativeElement;
    const parent = phElement.parentElement;

    phElement.style.display = 'none';

    parent.removeChild(phElement);
    parent.appendChild(phElement);
    parent.insertBefore(this.source.element.nativeElement, parent.children[this.sourceIndex]);

    this.target = null;
    this.source = null;

    if (this.sourceIndex !== this.targetIndex) {
      moveItemInArray(this.photos, this.sourceIndex, this.targetIndex);
      this.onPhotosChange();
    }
  }


  dropListEnterPredicate = (drag: CdkDrag, drop: CdkDropList): boolean => {
    if (drop === this.placeholder) {
      return true;
    }

    if (drop !== this.activeContainer) {
      return false;
    }

    const phElement = this.placeholder.element.nativeElement;
    const sourceElement = drag.dropContainer.element.nativeElement;
    const dropElement = drop.element.nativeElement;

    const dragIndex = __indexOf(dropElement.parentElement.children, (this.source ? phElement : sourceElement));
    const dropIndex = __indexOf(dropElement.parentElement.children, dropElement);

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;

      phElement.style.width = sourceElement.clientWidth + 'px';
      phElement.style.height = sourceElement.clientHeight + 'px';

      sourceElement.parentElement.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = drop;

    phElement.style.display = '';
    dropElement.parentElement.insertBefore(phElement, (dropIndex > dragIndex
      ? dropElement.nextSibling : dropElement));

    this.placeholder._dropListRef.enter(drag._dragRef, drag.element.nativeElement.offsetLeft, drag.element.nativeElement.offsetTop);
    return false;
  }

  /** Determines the point of the page that was touched by the user. */
  getPointerPositionOnPage(event: MouseEvent | TouchEvent): {x: number, y: number} {
    // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
    const point = __isTouchEvent(event) ? (event.touches[0] || event.changedTouches[0]) : event;
    const scrollPosition = this.viewportRuler.getViewportScrollPosition();

    return {
      x: point.pageX - scrollPosition.left,
      y: point.pageY - scrollPosition.top
    };
  }
}

function __indexOf(collection, node): number {
  return Array.prototype.indexOf.call(collection, node);
}


/** Determines whether an event is a touch event. */
function __isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  return event.type.startsWith('touch');
}

function __isInsideDropListClientRect(dropList: CdkDropList, x: number, y: number): boolean {
  const {top, bottom, left, right} = dropList.element.nativeElement.getBoundingClientRect();
  return y >= top && y <= bottom && x >= left && x <= right;
}
