<div fxLayout="column" fxFlexFill class="container">
  <div fxLayout="row">
    <mat-form-field fxFlexOffset="1">
      <mat-label>Поиск</mat-label>
      <input matInput type="text" [formControl]="searchQuery"/>
    </mat-form-field>
    <!--
    <astrade-category-form-field-with-dialog
      [categoryFormGroup]="gubkaCategory"
      [categories$]="categories$"
    ></astrade-category-form-field-with-dialog>
    -->
    <!--
    <input type="hidden" [formControl]="ozonCategoryId">
    <mat-form-field fxFlexOffset="1">
      <mat-label>Категория ozon</mat-label>
      <input matInput type="text" [formControl]="ozonCategoryName">
      <button type="button" [disabled]="!ozonCategoryName.value" mat-icon-button matSuffix (click)="ozonCategoryName.setValue('')"><mat-icon>cancel</mat-icon></button>
      <button type="button" mat-icon-button matSuffix (click)="openOzonCategoriesDialog()"><mat-icon>open_in_new</mat-icon></button>
    </mat-form-field>
    -->
  </div>
  <div fxFlex>
    <ag-grid-angular
      rowSelection="single"
      style="height: 100%"
      class="ag-theme-alpine"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [columnTypes]="columnTypes"
      [rowData]="products$ | async"
      [getRowNodeId]="getRowNodeId"
      [quickFilterText]="searchQuery.valueChanges | async"
      [modules]="modules"
      [statusBar]="statusBar"
      (gridReady)="onGridReady($event)"
      editType="fullRow"
      immutableData="true"
      suppressMaintainUnsortedOrder="true"
      suppressDragLeaveHidesColumns="true"
    ></ag-grid-angular>
  </div>
</div>
