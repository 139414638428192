import { Component, OnInit } from '@angular/core';
import { ICellRendererParams, RowNode } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';

export interface IEditButtonCellRendererParams extends ICellRendererParams {
  onClick: (rowNode: RowNode) => {};
}

@Component({
  selector: 'astrade-edit-button-cell-renderer',
  template: `
    <button type="button" mat-icon-button>
      <mat-icon class="mat-icon material-icons mat-icon-no-color" (click)="params.onClick(params.node)">edit</mat-icon>
    </button>
  `
})
export class EditButtonCellRendererComponent implements ICellRendererAngularComp {
  params: IEditButtonCellRendererParams;

  constructor() { }

  agInit(params: IEditButtonCellRendererParams): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }
}
