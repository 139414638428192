import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'astrade-ozon-export-status-cell-renderer',
  template: `
    <span [style.color]="getColorByStatus(params.value)">{{ params.value | ozonExportStatus }}</span>
  `
})
export class OzonExportStatusCellRendererComponent implements ICellRendererAngularComp {

  params: ICellRendererParams;

  constructor() { }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

  getColorByStatus(status: string): string {
    if (['failed_moderation', 'failed_validation', 'failed'].includes(status)) {
      return 'red';
    } else if (['processed', 'imported'].includes(status)) {
      return 'green';
    } else if (['moderating', 'processing', 'pending', 'EXPORT_STARTED'].includes(status)) {
      return 'blue';
    } else {
      return 'black';
    }
  }

}
