import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CategoryTree, Product, ProductPropertyMetadata } from '@astrade/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OzonProductEditFormComponent } from '../../ozon/ozon-product-edit-form/ozon-product-edit-form.component';
import { ProductFormGroup } from '../controls/product-form-group';
import { ObjectHelper } from '../../object-helper';

@Component({
  selector: 'astrade-product-edit-form',
  templateUrl: './product-edit-form.component.html',
  styleUrls: ['./product-edit-form.component.scss']
})
export class ProductEditFormComponent implements OnInit {
  @Input('product') set setProduct(product: Product) {
    this.product = product;
    this.product$.next(product);
    this.setFormData(product);
  }

  @Input('gubkaCategoryProperties') set setGubkaCategoryProperties(gubkaCategoryProperties: ProductPropertyMetadata[]) {
    this.form.controls.gubkaProperties.generateControlsFromCategoryProperties(gubkaCategoryProperties);

    // Set form product properties
    this.gubkaCategoryProperties = gubkaCategoryProperties;
  }

  @Input() categories$: Observable<CategoryTree[]>;

  @Input() saveInProgress: boolean = false;

  @Output() saveClick = new EventEmitter<Product>();
  @Output() photosChange = new EventEmitter<string[]>();
  @Output() gubkaCategoryIdChange = new EventEmitter<string>();

  @ViewChild(OzonProductEditFormComponent) ozonProductEditFormComponent: OzonProductEditFormComponent;

  product: Product;
  product$: BehaviorSubject<Product> = new BehaviorSubject<Product>(undefined);

  gubkaCategoryProperties: ProductPropertyMetadata[];

  form = new ProductFormGroup();

  constructor() { }

  ngOnInit(): void {
    this.form.controls.mainProperties.controls.gubkaCategory.controls.id.valueChanges.subscribe(value => {
      this.gubkaCategoryIdChange.emit(value);
    });
  }

  private setFormData(product: Product): void {
    if (!this.form) {
      return;
    }

    this.form.controls.mainProperties.setValuesFromProduct(product);
  }

  onPhotosChange(photos: string[]): void {
    this.product.photos = photos;
    this.photosChange.emit(photos);
  }

  onSave() {
    // TODO: immutability
    this.saveClick.emit(this.form.applyValuesToProduct(ObjectHelper.deepCopy(this.product)));
  }
}
