import { Component, Input, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OzonDictionaryService } from './ozon-dictionary.service';
import { OzonDictionaryValue } from '@astrade/core';
import { OzonDictionarySearchService } from './ozon-dictionary-search.service';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { FormFieldAbstractComponent } from '../../form/fields/form-field-abstract/form-field-abstract.component';

@Component({
  selector: 'astrade-ozon-autocomplete-field',
  template: `
    <mat-form-field fxFlex>
      <mat-label>{{label}}</mat-label>
      <input type="text" matInput [formControl]="control" [matAutocomplete]="auto"/>
      <button type="button" [disabled]="!control.value" mat-icon-button matSuffix (click)="reset()"><mat-icon>cancel</mat-icon></button>
      <button type="button" *ngIf="(options$ | async).length > 0" mat-icon-button matSuffix (click)="openDropDownList()"><mat-icon>arrow_drop_down</mat-icon></button>
      <button type="button" *ngIf="hint" [matTooltip]="hint" matTooltipClass="hint" mat-icon-button matSuffix><mat-icon>help</mat-icon></button>
      <!--<button type="button" mat-icon-button matSuffix (click)="openDialog()"><mat-icon>open_in_new</mat-icon></button>-->
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="renderValue">
        <mat-option *ngFor="let option of options$ | async" [value]="option">
          <div style="width: 48px; height: 48px; margin-right: 10px; display: inline-block;"><img style="max-width: 50px; max-height: 48px; vertical-align: middle" *ngIf="option.picture" [src]="option.picture"></div>
          {{option.value}}<small> | ID: {{option.id}}</small><small *ngIf="option.info"> | {{option.info}}</small>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  `
})
export class OzonAutocompleteFieldComponent extends FormFieldAbstractComponent {
  @Input() categoryId: number;
  @Input() attributeId: number;

  @ViewChild(MatAutocompleteTrigger, {read: MatAutocompleteTrigger}) inputAutoComplete: MatAutocompleteTrigger;

  options$ = new BehaviorSubject<OzonDictionaryValue[]>([]);

  constructor(
    private readonly ozonDictionaryService: OzonDictionaryService,
    private readonly ozonDictionarySearchService: OzonDictionarySearchService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.categoryId && this.attributeId) {
      this.ozonDictionaryService.getInitialValues(this.categoryId, this.attributeId).subscribe(value => {
        this.options$.next(value);
      });
    }

    this.control.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      filter(value => typeof value === 'string' || value === ''),
      switchMap(searchTerm => {
        console.log(searchTerm);
        if (((searchTerm as string)?.length ?? 0) > 0) {
          return this.ozonDictionarySearchService.search(this.categoryId, this.attributeId, searchTerm);
        }

        return this.ozonDictionaryService.getInitialValues(this.categoryId, this.attributeId);
      })
    ).subscribe(value => {
      this.options$.next(value);
    });
  }

  renderValue(value: any): string {
    return value?.value ?? '';
  }

  reset(): void {
    this.control.setValue('');
  }

  openDropDownList(): void {
    this.inputAutoComplete.openPanel();
  }

  openDialog(): void {
    // TODO: implement
  }
}
