<mat-sidenav-container fxFlexFill>
  <mat-sidenav #sideNav position="start" (click)="sideNav.close()">
    <astrade-dropship-side-menu [user]="user" [idTokenResult]="idTokenResult" (loginClick)="login()" (logoutClick)="logout()"></astrade-dropship-side-menu>
  </mat-sidenav>
  <mat-sidenav-content fxLayout="column" fxFlexFill>
    <astrade-header [title]="title" (menuClick)="sideNav.toggle()"></astrade-header>
    <div fxFlex>
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
