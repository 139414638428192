/**
 * Map month names to month numbers.
 * @internal
 */
const MONTH_NAMES_MAP = {
  ['Январь']: 1,
  ['Февраль']: 2,
  ['Март']: 3,
  ['Апрель']: 4,
  ['Май']: 5,
  ['Июнь']: 6,
  ['Июль']: 7,
  ['Август']: 8,
  ['Сентябрб']: 9,
  ['Октябрь']: 10,
  ['Ноябрь']: 11,
  ['Декабрь']: 12
};

export interface TimePeriodHeaderInfoRecord {
  year: number;
  month: number;
  startColumnIndex: number;
}

/**
 * Report header info.
 * @internal
 */
export interface HeaderInfo {
  /**
   * Index of the first row of the header.
   * Starts from 1.
   */
  firstRow: number;

  /**
   * Index of the last row of the header.
   */
  lastRow: number;

  /**
   * Header rows count.
   */
  rowsCount: number;

  /**
   * Time period (year and month) headers info.
   */
  timePeriods: TimePeriodHeaderInfoRecord[];
}

/**
 * Parses month and year string.
 * @param value Month and year string representation. Example: Январь 2021 г.
 * @returns Month number (starting from 1) and year.
 * @protected
 */
export function parseMonthAndYear(value: string): { month: number; year: number; } | undefined {
  const matches = /([а-яА-Я]+)\s(\d{4})\sг\./g.exec(value);
  if (matches?.length > 1) {
    const monthNumber = MONTH_NAMES_MAP[matches[1]];
    const year = Number(matches[2]);
    if (monthNumber && year) {
      return {
        month: monthNumber,
        year
      };
    }
  }

  return undefined;
}

export function isMonthAndYear(value: string): boolean {
  return parseMonthAndYear(value) !== undefined;
}

export function getStringValueFromRow(
  columnIndex: number,
  row: unknown[]
): string {
  return (row[columnIndex] as string ?? '').trim();
}

export function getNumberValueFromRow(
  columnIndex: number,
  row: unknown[]
): number {
  const cellValue = row[columnIndex] as string ?? '0';
  let filteredCellValue = cellValue
    .trim()
    .replace(/[)]/g, '')
    .replace(/\(/g, '-');

  // Thousands delimiter = ' ' and decimal delimiter = ','
  if (filteredCellValue.includes(' ')) {
    filteredCellValue = filteredCellValue
      .replace(/\s/g, '')
      .replace(/,/g, '.');
  } else {
    // Thousands delimiter = ',' and decimal delimiter = '.'
    filteredCellValue = filteredCellValue.replace(/,/g, '');
  }

  const result = Number(filteredCellValue);
  if (isNaN(result)) {
    throw new Error(`Can't parse number value from row '${JSON.stringify(row)}', column index '${columnIndex}', cellValue: ${cellValue}`);
  }

  return result;
}

export function findAllTimePeriods(row: unknown[]): TimePeriodHeaderInfoRecord[] {
  const result: TimePeriodHeaderInfoRecord[] = [];
  row?.forEach((value, index) => {
    const parseResult = parseMonthAndYear(value as string);
    if (parseResult !== undefined) {
      result.push({
        month: parseResult.month,
        year: parseResult.year,
        startColumnIndex: index
      });
    }
  });

  return result;
}

export function findFirstTimePeriodColumnIndex(row: unknown[]): number | undefined {
  const arrayIndex = row.findIndex(value => isMonthAndYear(value as string));
  return arrayIndex < 0 ? undefined : arrayIndex;
}
