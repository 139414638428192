import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OzonCategoryTree } from '@astrade/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'astrade-ozon-category-select-dialog',
  template: `
    <mat-dialog-content style="height: 100vh" fxLayout="column">
      <div>
        <h2 mat-dialog-title>Категории товаров ozon.ru</h2>
      </div>
      <mat-form-field fxFlexOffset="1">
        <mat-label>Поиск</mat-label>
        <input matInput type="text" [formControl]="searchTerm"/>
      </mat-form-field>
      <astrade-ozon-categories-list
        fxFlex
        [categories$]="categories$"
        [searchTerm$]="searchTerm.valueChanges"
        [originalSelectedCategoryId]="originalSelectedCategoryId"
        (selectionChange)="onSelectionChange($event)"
      ></astrade-ozon-categories-list>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button type="button" mat-button [mat-dialog-close]="undefined">Отмена</button>
      <button type="button" mat-raised-button color="primary" cdkFocusInitial
              [disabled]="!selectedCategory || selectedCategory?.children?.length > 0"
              [mat-dialog-close]="selectedCategory">Выбрать
      </button>
    </mat-dialog-actions>
  `
})
export class OzonCategorySelectDialogComponent implements OnInit {

  categories$: Observable<OzonCategoryTree[]>;
  originalSelectedCategoryId?: number;

  selectedCategory?: OzonCategoryTree;

  searchTerm = new FormControl('');

  constructor(@Inject(MAT_DIALOG_DATA) data) {
    this.categories$ = data.categories$;
    this.originalSelectedCategoryId = data.categoryId;
  }

  ngOnInit(): void {
  }

  onSelectionChange(category: OzonCategoryTree): void {
    this.selectedCategory = category;
  }
}
