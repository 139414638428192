import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from '@astrade/core';
import { BehaviorSubject } from 'rxjs';
import { ObjectHelper } from '../../object-helper';
import { ProductPriceManagerPropertiesFormGroup } from '../../form/controls/product-price-manager-properties-form-group';

@Component({
  selector: 'astrade-price-edit-form',
  templateUrl: './price-edit-form.component.html',
  styleUrls: ['./price-edit-form.component.scss']
})
export class PriceEditFormComponent implements OnInit {
  @Input('product') set setProduct(product: Product) {
    this.product = product;
    this.product$.next(product);
    //this.setFormData(product);
  }

  product: Product;
  product$: BehaviorSubject<Product> = new BehaviorSubject<Product>(undefined);

  form = new ProductPriceManagerPropertiesFormGroup();

  @Input() saveInProgress: boolean = false;

  @Output() saveClick = new EventEmitter<Product>();

  constructor() { }

  ngOnInit(): void {
  }

  onSave(): void {
    const copyOfProduct = ObjectHelper.deepCopy(this.product);
    this.form.applyValuesToProductProperties(copyOfProduct.properties)
    this.saveClick.emit(copyOfProduct);
  }
}
