import {ColDef} from 'ag-grid-community';
import {CheckboxCellRendererComponent} from './checkbox-cell-renderer/checkbox-cell-renderer.component';
import {CheckboxCellEditorComponent} from './checkbox-cell-editor/checkbox-cell-editor.component';
import {AstradeDictionaryCellRendererComponent} from './astrade-dictionary-cell-renderer/astrade-dictionary-cell-renderer.component';
import {DictionaryValue, firebaseTimestampToDate} from '@astrade/core';
import {OzonExportStatusCellRendererComponent} from './ozon-export-status-cell-renderer/ozon-export-status-cell-renderer.component';
import {OzonErrorsCellRendererComponent} from './ozon-errors-cell-renderer/ozon-errors-cell-renderer.component';
import {YesNoCellRendererComponent} from './yes-no-cell-renderer/yes-no-cell-renderer.component';
import {TimestampCellRendererComponent} from './timestamp-cell-renderer/timestamp-cell-renderer.component';

export const AG_GRID_COLUMN_TYPES: Record<string, ColDef> = {
  nonEditable: {
    editable: false
  },
  checkbox: {
    cellRendererFramework: CheckboxCellRendererComponent,
    editable: true,
    cellEditorFramework: CheckboxCellEditorComponent
  },
  astradeDictionary: {
    cellRendererFramework: AstradeDictionaryCellRendererComponent,
    filterValueGetter: params => (params.data as DictionaryValue)?.name
  },
  ozonExportTaskStatus: {
    cellRendererFramework: OzonExportStatusCellRendererComponent,
    filterParams: {
      cellRendererFramework: OzonExportStatusCellRendererComponent
    },
    editable: false
  },
  ozonNativeProductStatus: {
    cellRendererFramework: OzonExportStatusCellRendererComponent,
    filterParams: {
      cellRendererFramework: OzonExportStatusCellRendererComponent
    },
    editable: false
  },
  ozonExportErrors: {
    cellRendererFramework: OzonErrorsCellRendererComponent,
    editable: false
  },
  yesNo: {
    cellRendererFramework: YesNoCellRendererComponent,
    filterParams: {
      cellRendererFramework: YesNoCellRendererComponent
    }
  },
  timestamp: {
    cellRendererFramework: TimestampCellRendererComponent,
    filterValueGetter: params => {
      const value = params.getValue(params.colDef.field);
      const dateValue = firebaseTimestampToDate(value);
      if (dateValue) {
        dateValue.setHours(0, 0, 0, 0);
      }
      return dateValue ?? value;
    },
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        if (cellValue < filterLocalDateAtMidnight) {
          return -1;
        } else if (cellValue > filterLocalDateAtMidnight) {
          return 1;
        } else {
          return 0;
        }
      }
    }
  }
}
