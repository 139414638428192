import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'astrade-image-cell-renderer',
  template: `
    <img border="0" style="height: 100%" src="{{ params.value }}">
  `
})
export class ImageCellRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  constructor() { }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }
}
