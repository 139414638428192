import {Component, Input, OnInit} from '@angular/core';
import {Profile} from '../dropship-profile/profile';
import {ProfileService} from './profile.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FirebaseUser} from '@astrade/core';
import {AngularFireAuth} from '@angular/fire/auth';

@Component({
  selector: 'astrade-dropship-profile-container',
  template:`
    <astrade-dropship-profile
      [profile]="profile"
      [saveInProgress]="saveInProgress"
      (saveClick)="onSave($event)"
      style="width: 350px"
    ></astrade-dropship-profile>
  `
})
export class DropshipProfileContainerComponent implements OnInit {
  profile: Profile;
  user: FirebaseUser;
  saveInProgress: boolean = false;

  constructor(
    private readonly auth: AngularFireAuth,
    private readonly profileService: ProfileService,
    private readonly snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.auth.user.subscribe(user => {
      this.user = user;
      this.profileService.get(user.uid).subscribe(value => this.profile = value);
    });
  }

  onSave(profile: Profile): void {
    this.saveInProgress = true;
    this.profileService.save(this.user.uid, profile)
      .then(value => {
        console.log('Profile saved.');
        this.saveInProgress = false;
        this.snackBar.open('Профиль сохранён.', '', {
          duration: 5000
        });
      })
      .catch(reason => {
        console.log('Error saving profile:', reason);
        this.saveInProgress = false;
        this.snackBar.open('Ошибочка вышла... ' + reason?.toString() ?? '', 'Закрыть');
      });
  }

}
