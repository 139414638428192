import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UploadService } from '../../services/upload.service';
import { Observable } from 'rxjs';
import { SupplierPriceListImportTaskService } from '../../../../../../apps/console/src/app/services/supplier-price-list-import-task.service';

@Component({
  selector: 'astrade-supplier-price-upload-dialog',
  template: `
    <mat-dialog-content style="height: 100vh" fxLayout="column">
      <h2>Загрузка прайс-листа поставщика</h2>
      <div fxFlex style="display: flex; align-items: center; justify-content: center;">
        <input
          #fileUpload
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          [style]="!uploadStatus || uploadStatus === 'done' || uploadStatus === 'error' ? 'display: block' : 'display: none'"
          (change)="onFileUploadChange($event)"
        >
        <div *ngIf="uploadStatus === 'uploading'" style="height: auto; width: 100%">
          <mat-progress-bar mode="determinate" [value]="uploadPercent | async"></mat-progress-bar>
          <div style="font-size: 10pt; text-align: center">Загрузка...</div>
        </div>
        <div *ngIf="uploadStatus === 'error'">
          <div style="font-size: 10pt; text-align: center">Ошибка</div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button type="button" mat-button (click)="onClose()">Закрыть</button>
    </mat-dialog-actions>
  `
})
export class SupplierPriceListUploadDialogComponent implements OnInit {

  @ViewChild('fileUpload') fileUploadElement: ElementRef;
  uploadPercent: Observable<number>;
  uploadStatus: string;

  form: FormGroup = new FormGroup({
    name: new FormControl('')
  });

  constructor(
    private readonly dialogRef: MatDialogRef<SupplierPriceListUploadDialogComponent>,
    private readonly snackBar: MatSnackBar,
    private readonly uploadService: UploadService,
    private readonly supplierPriceListImportTaskService: SupplierPriceListImportTaskService
  ) { }

  ngOnInit(): void {
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onAddClick(): void {
    if (this.uploadStatus && this.uploadStatus !== 'done' && this.uploadStatus !== 'error') {
      return;
    }

    if (!this.fileUploadElement.nativeElement) {
      return;
    }
    (this.fileUploadElement.nativeElement as HTMLInputElement).click();
  }

  onFileUploadChange(event: Event): void {
    const {files} = event.target as HTMLInputElement;
    if (!files || files.length === 0) {
      return;
    }

    const file = files.item(0);
    if (!file.name) {
      return;
    }

    this.uploadService.uploadFile('supplier-prices-astrade-co-jp', file).subscribe(value => {
      this.uploadPercent = value.uploadPercent;
      this.uploadStatus = value.status;
      if (this.uploadStatus === 'done' || this.uploadStatus === 'error') {
        (this.fileUploadElement.nativeElement as HTMLInputElement).value = '';
      }

      if (this.uploadStatus === 'done') {
        this.supplierPriceListImportTaskService.add(value.fileId).then(() => console.log(`Added import task ${value.fileId}.`));
        this.dialogRef.close();
      }
    });
  }
}
