import {AbstractControlOptions, AsyncValidatorFn, FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import {Profile} from './profile';

export class ProfileFormGroup extends FormGroup {
  controls: {
    lastName: FormControl,
    firstName: FormControl,
    patronymic: FormControl,
    phone: FormControl,
    zip: FormControl,
    region: FormControl,
    city: FormControl,
    street: FormControl,
    house: FormControl,
    building: FormControl,
    housing: FormControl,
    flat: FormControl
  };

  constructor(
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    const controls = {
      lastName: new FormControl(''),
      firstName: new FormControl(''),
      patronymic: new FormControl(''),
      phone: new FormControl(''),
      zip: new FormControl(''),
      region: new FormControl(''),
      city: new FormControl(''),
      street: new FormControl(''),
      house: new FormControl(''),
      building: new FormControl(''),
      housing: new FormControl(''),
      flat: new FormControl('')
    };
    super(controls, validatorOrOpts, asyncValidator);
  }

  setValuesFromProfile(profile: Profile): void {
    if (!profile) {
      return;
    }

    this.controls.lastName.setValue(profile.lastName);
    this.controls.firstName.setValue(profile.firstName);
    this.controls.patronymic.setValue(profile.patronymic);
    this.controls.phone.setValue(profile.phone);
    this.controls.zip.setValue(profile.zip);
    this.controls.region.setValue(profile.region);
    this.controls.city.setValue(profile.city);
    this.controls.street.setValue(profile.street);
    this.controls.house.setValue(profile.house);
    this.controls.building.setValue(profile.building);
    this.controls.housing.setValue(profile.housing);
    this.controls.flat.setValue(profile.flat);
  }

  applyValuesToProfile(profile: Profile): Profile {
    if (!profile) {
      profile = {} as Profile;
    }

    profile.lastName = (this.controls.lastName.value as string).trim();
    profile.firstName = (this.controls.firstName.value as string).trim();
    profile.patronymic = (this.controls.patronymic.value as string).trim();
    profile.phone = (this.controls.phone.value as string).trim();
    profile.zip = (this.controls.zip.value as string).trim();
    profile.city = (this.controls.city.value as string).trim();
    profile.street = (this.controls.street.value as string).trim();
    profile.house = (this.controls.house.value as string).trim();
    profile.building = (this.controls.building.value as string).trim();
    profile.housing = (this.controls.housing.value as string).trim();
    profile.flat = (this.controls.flat.value as string).trim();
    return profile;
  }
}
