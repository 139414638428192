import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { UserListComponent } from './user-list/user-list.component';
import { AgGridModule } from 'ag-grid-angular';
import { ImageCellRendererComponent } from './ag-grid/image-cell-renderer/image-cell-renderer.component';
import { CheckboxCellRendererComponent } from './ag-grid/checkbox-cell-renderer/checkbox-cell-renderer.component';
import { CheckboxCellEditorComponent } from './ag-grid/checkbox-cell-editor/checkbox-cell-editor.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginFormComponent } from './login/login-form/login-form.component';
import { RouterModule } from '@angular/router';
import { CategoriesListComponent } from './gubka/categories-list/categories-list.component';
import { EditButtonCellRendererComponent } from './ag-grid/edit-button-cell-renderer/edit-button-cell-renderer.component';
import { CategoryEditFormComponent } from './gubka/category-edit-form/category-edit-form.component';
import { CategoryEditComponent } from './gubka/category-edit/category-edit.component';
import { CategoryFormFieldComponent } from './gubka/category-form-field/category-form-field.component';
import { CategorySelectDialogComponent } from './gubka/category-select-dialog/category-select-dialog.component';
import { CategoryFormFieldWithDialogComponent } from './gubka/category-form-field-with-dialog/category-form-field-with-dialog.component';
import { ProductEditFormComponent } from './form/product-edit-form/product-edit-form.component';
import { ProductPhotosListComponent } from './form/fields/product-photos-list/product-photos-list.component';
import { ProductPropertyFieldComponent } from './form/fields/product-property-field/product-property-field.component';
import { ProductsListComponent } from './gubka/products-list/products-list.component';
import { PhotoSmallPipe } from './pipes/photo-small.pipe';
import { FormFieldYesNoComponent } from './form/fields/form-field-yes-no/form-field-yes-no.component';
import { FormFieldTextComponent } from './form/fields/form-field-text/form-field-text.component';
import { FormFieldTextareaComponent } from './form/fields/form-field-textarea/form-field-textarea.component';
import { FormFieldAbstractComponent } from './form/fields/form-field-abstract/form-field-abstract.component';
import { FormFieldNumberComponent } from './form/fields/form-field-number/form-field-number.component';
import { FormFieldCheckboxComponent } from './form/fields/form-field-checkbox/form-field-checkbox.component';
import { OzonAutocompleteFieldComponent } from './ozon/ozon-autocomplete-field/ozon-autocomplete-field.component';
import { OzonProductPropertyFieldComponent } from './ozon/ozon-product-property-field/ozon-product-property-field.component';
import { OzonProductEditFormComponent } from './ozon/ozon-product-edit-form/ozon-product-edit-form.component';
import { OzonCategoryFormFieldComponent } from './ozon/ozon-category-form-field/ozon-category-form-field.component';
import { OzonCategorySelectDialogComponent } from './ozon/ozon-category-select-dialog/ozon-category-select-dialog.component';
import { OzonCategoriesListComponent } from './ozon/ozon-categories-list/ozon-categories-list.component';
import { OzonCategoryFormFieldWithDialogComponent } from './ozon/ozon-category-form-field-with-dialog/ozon-category-form-field-with-dialog.component';
import { OzonAutocompleteDialogComponent } from './ozon/ozon-autocomplete-field/ozon-autocomplete-dialog/ozon-autocomplete-dialog.component';
import { OzonDictionaryValuesListComponent } from './ozon/ozon-dictionary-values-list/ozon-dictionary-values-list.component';
import { OzonCategoryService } from './ozon/ozon-category.service';
import { AdditionalPropertiesFormComponent } from './form/product-edit-form/additional-properties-form/additional-properties-form.component';
import { FormFieldAutocompleteComponent } from './form/fields/form-field-autocomplete/form-field-autocomplete.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { AstradeDictionaryCellRendererComponent } from './ag-grid/astrade-dictionary-cell-renderer/astrade-dictionary-cell-renderer.component';
import { FormFieldVatComponent } from './form/fields/form-field-vat/form-field-vat.component';
import { DictionaryListComponent } from './dictionary-list/dictionary-list.component';
import { OzonDictionaryCellRendererComponent } from './ag-grid/ozon-dictionary-cell-renderer/ozon-dictionary-cell-renderer.component';
import { OzonDictionaryCellEditorComponent } from './ag-grid/ozon-dictionary-cell-editor/ozon-dictionary-cell-editor.component';
import { OzonExportProductComponent } from './ozon/ozon-export-product/ozon-export-product.component';
import { OzonExportStatusCellRendererComponent } from './ag-grid/ozon-export-status-cell-renderer/ozon-export-status-cell-renderer.component';
import { OzonExportStatusPipe } from './pipes/ozon-export-status.pipe';
import { YesNoCellRendererComponent } from './ag-grid/yes-no-cell-renderer/yes-no-cell-renderer.component';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { OzonErrorsCellRendererComponent } from './ag-grid/ozon-errors-cell-renderer/ozon-errors-cell-renderer.component';
import { TimestampToDatePipe } from './pipes/timestamp-to-date.pipe';
import { PriceManagementComponent } from './form/product-edit-form/price-management/price-management.component';
import { PriceMonitoringComponent } from './form/product-edit-form/price-management/price-monitoring/price-monitoring.component';
import { PriceEditDialogComponent } from './form/product-edit-form/price-management/price-monitoring/price-edit-dialog/price-edit-dialog.component';
import { FormFieldPriceTypeForOzonComponent } from './form/fields/form-field-price-type-for-ozon/form-field-price-type-for-ozon.component';
import { PricesListComponent } from './astrade/prices-list/prices-list.component';
import { PriceEditFormComponent } from './astrade/price-edit-form/price-edit-form.component';
import { UploadService } from './services/upload.service';
import { SupplierPriceListImportTasksListComponent } from './astrade/supplier-price-list-import-tasks-list/supplier-price-list-import-tasks-list.component';
import { SupplierPriceListUploadDialogComponent } from './astrade/supplier-price-list-upload-dialog/supplier-price-list-upload-dialog.component';
import { UiCommonModule } from '@astrade/ui-common';
import { TimestampCellRendererComponent } from './ag-grid/timestamp-cell-renderer/timestamp-cell-renderer.component';
import { OneSDictionaryCellEditorComponent } from './ag-grid/one-s-dictionary-cell-editor/one-sdictionary-cell-editor.component';
import { OneSDictionaryCellRendererComponent } from './ag-grid/one-s-dictionary-cell-renderer/one-s-dictionary-cell-renderer.component';
import { FormFieldOneSAutocompleteComponent } from './form/fields/form-field-one-s-autocomplete/form-field-one-s-autocomplete.component';

@NgModule({
  imports: [
    CommonModule,
    UiCommonModule,
    RouterModule,
    ReactiveFormsModule,
    AgGridModule.withComponents([
      ImageCellRendererComponent,
      CheckboxCellRendererComponent,
      CheckboxCellEditorComponent,
      OzonExportStatusCellRendererComponent,
      YesNoCellRendererComponent,
      OzonErrorsCellRendererComponent,
      TimestampCellRendererComponent,
      OneSDictionaryCellEditorComponent,
      OneSDictionaryCellRendererComponent
    ])
  ],
  exports: [
    UiCommonModule,
    UserListComponent,
    SideMenuComponent,
    HeaderComponent,
    CategoriesListComponent,
    PricesListComponent,
    PriceEditFormComponent,
    ProductsListComponent,
    ProductEditFormComponent,
    TruncatePipe,
    DictionaryListComponent,
    SupplierPriceListImportTasksListComponent,
    LoginFormComponent
  ],
  declarations: [
    HeaderComponent,
    SideMenuComponent,
    UserListComponent,
    ImageCellRendererComponent,
    CheckboxCellRendererComponent,
    CheckboxCellEditorComponent,
    LoginFormComponent,
    CategoriesListComponent,
    EditButtonCellRendererComponent,
    CategoryEditFormComponent,
    CategoryEditComponent,
    CategoryFormFieldComponent,
    CategorySelectDialogComponent,
    CategoryFormFieldWithDialogComponent,
    ProductEditFormComponent,
    ProductPhotosListComponent,
    ProductPropertyFieldComponent,
    ProductsListComponent,
    PhotoSmallPipe,
    FormFieldYesNoComponent,
    FormFieldTextComponent,
    FormFieldTextareaComponent,
    FormFieldAbstractComponent,
    FormFieldNumberComponent,
    FormFieldCheckboxComponent,
    OzonAutocompleteFieldComponent,
    OzonProductPropertyFieldComponent,
    OzonProductEditFormComponent,
    OzonCategoryFormFieldComponent,
    OzonCategorySelectDialogComponent,
    OzonCategoriesListComponent,
    OzonCategoryFormFieldWithDialogComponent,
    OzonAutocompleteDialogComponent,
    OzonDictionaryValuesListComponent,
    AdditionalPropertiesFormComponent,
    FormFieldAutocompleteComponent,
    TruncatePipe,
    AstradeDictionaryCellRendererComponent,
    FormFieldVatComponent,
    DictionaryListComponent,
    OzonDictionaryCellRendererComponent,
    OzonDictionaryCellEditorComponent,
    OzonExportProductComponent,
    OzonExportStatusCellRendererComponent,
    OzonExportStatusPipe,
    YesNoCellRendererComponent,
    YesNoPipe,
    OzonErrorsCellRendererComponent,
    TimestampToDatePipe,
    PriceManagementComponent,
    PriceMonitoringComponent,
    PriceEditDialogComponent,
    FormFieldPriceTypeForOzonComponent,
    PricesListComponent,
    PriceEditFormComponent,
    SupplierPriceListImportTasksListComponent,
    SupplierPriceListUploadDialogComponent,
    TimestampCellRendererComponent,
    OneSDictionaryCellEditorComponent,
    OneSDictionaryCellRendererComponent,
    FormFieldOneSAutocompleteComponent
  ],
  providers: [
    OzonCategoryService,
    UploadService
  ]
})
export class UiModule {}
