import { Component, Input, OnInit } from '@angular/core';
import { RivalPriceService } from '../../../../services/rival-price.service';
import { Observable } from 'rxjs';
import { RivalPrice } from '@astrade/core';
import { switchMap, tap } from 'rxjs/operators';
import { ColDef, GridApi, GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { CheckboxCellRendererComponent } from '../../../../ag-grid/checkbox-cell-renderer/checkbox-cell-renderer.component';
import { CheckboxCellEditorComponent } from '../../../../ag-grid/checkbox-cell-editor/checkbox-cell-editor.component';
import { YesNoCellRendererComponent } from '../../../../ag-grid/yes-no-cell-renderer/yes-no-cell-renderer.component';
import { v4 as uuidv4 } from 'uuid';
import { MatDialog } from '@angular/material/dialog';
import { PriceEditDialogComponent } from './price-edit-dialog/price-edit-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'astrade-price-monitoring',
  templateUrl: './price-monitoring.component.html',
  styleUrls: ['./price-monitoring.component.scss']
})
export class PriceMonitoringComponent implements OnInit {

  @Input() productId$: Observable<string>;

  rivalPrices$: Observable<RivalPrice[]>;

  private productId?: string;

  defaultColDef: ColDef = {
    sortable: true,
    filter: 'agSetColumnFilter',
    resizable: true,
    editable: false
  };

  columnTypes: Record<string, ColDef> = {
    nonEditable: {
      editable: false
    },
    checkbox: {
      cellRendererFramework: CheckboxCellRendererComponent,
      editable: true,
      cellEditorFramework: CheckboxCellEditorComponent
    },
    yesNo: {
      cellRendererFramework: YesNoCellRendererComponent,
      filterParams: {
        cellRendererFramework: YesNoCellRendererComponent
      }
    }
  };

  /**
   * Grid column definitions.
   */
  columnDefs: ColDef[] = [{
    headerName: 'Название',
    field: 'name',
    initialWidth: 130
  }, {
    headerName: 'URL',
    field: 'url',
    initialWidth: 100
  }, {
    headerName: 'Цена',
    field: 'price',
    initialWidth: 100
  }, {
    headerName: 'В наличии',
    field: 'available',
    initialWidth: 120,
    type: 'yesNo'
  }, {
    headerName: 'Не найден',
    field: 'notFound',
    initialWidth: 120,
    type: 'yesNo'
  }/*, {
    headerName: 'Источник',
    field: 'source',
    editable: true,
    initialWidth: 250
  }, {
    headerName: 'Статус',
    field: 'status',
    editable: true,
    initialWidth: 250
  }, {
    headerName: 'Обновление',
    field: 'updateTimestamp',
    editable: true,
    initialWidth: 250
  }*/];

  private gridApi: GridApi;

  constructor(
    private readonly rivalPriceService: RivalPriceService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.rivalPrices$ = this.productId$?.pipe(
      tap(productId => this.productId = productId),
      switchMap(productId => this.rivalPriceService.getRivalPricesByProductId(productId)),
    );
  }

  onAddButtonClick(): void {
    const rivalPrice: RivalPrice = {
      id: uuidv4(),
      productId: this.productId,
      name: '',
      url: '',
      price: 0,
      updateTimestamp: undefined,
      status: 'approved',
      source: 'manual'
    }

    const dialogRef = this.dialog.open(PriceEditDialogComponent, {
      width: '100%',
      height: 'auto',
      data: rivalPrice
    });

    dialogRef.afterClosed().subscribe((result: RivalPrice) => {
      console.log('Rival price saved:', result);
    });
  }

  onDeleteButtonClick(): void {
    const selectedPrices: RivalPrice[] = this.gridApi.getSelectedNodes()?.map(value => value.data);
    if (!selectedPrices?.length) {
      return;
    }

    this.rivalPriceService.deleteByIds(selectedPrices.map(price => price.id))
      .then(() => {
        this.snackBar.open('Удаление выполнено', '', {
          duration: 5000
        });
      })
      .catch(reason => {
        console.error('Save dictionary value', reason);
        this.snackBar.open('Ошибочка вышла... ' + reason?.toString() ?? '', 'Закрыть');
      });
  }

  getRowNodeId(data: RivalPrice): string {
    return data.id as string;
  }

  onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
  }

  onRowDoubleClicked(event: RowDoubleClickedEvent): void {
    const rivalPrice = event.node.data as RivalPrice;
    if (!rivalPrice) {
      return;
    }

    const dialogRef = this.dialog.open(PriceEditDialogComponent, {
      width: '100%',
      height: 'auto',
      data: rivalPrice
    });

    dialogRef.afterClosed().subscribe((result: RivalPrice) => {
      console.log('Rival price saved:', result);
    });
  }
}
