<ng-container *ngIf="user else loginButton">
  <p><button type="button" mat-button>{{user.displayName}}</button></p>
  <p><button type="button" mat-button (click)="logoutClick.emit()">Выйти</button></p>
  <ng-container *ngIf="idTokenResult">
    <p *ngIf="idTokenResult?.claims?.admin === true"><button type="button" mat-button routerLink="/admin">Администрирование</button></p>
    <p *ngIf="idTokenResult?.claims?.manager"><button type="button" mat-button routerLink="/categories">Категории</button></p>
    <p *ngIf="idTokenResult?.claims?.manager"><button type="button" mat-button routerLink="/products">Товары</button></p>
    <p *ngIf="idTokenResult?.claims?.manager"><button type="button" mat-button routerLink="/prices">Цены</button></p>
    <p *ngIf="idTokenResult?.claims?.manager"><button type="button" mat-button routerLink="/supplier-price-lists">Прайсы поставщиков</button></p>
    <h4>Справочники:</h4>
    <p *ngIf="idTokenResult?.claims?.manager"><button type="button" mat-button routerLink="/dictionary/04b6d88b-36f2-43e8-a53b-01d974cce610">Бренд</button></p>
    <p *ngIf="idTokenResult?.claims?.manager"><button type="button" mat-button routerLink="/dictionary/41c95ec3-0e89-471f-9114-bec8f8126937">Производитель</button></p>
    <p *ngIf="idTokenResult?.claims?.manager"><button type="button" mat-button routerLink="/dictionary/55ba445c-29c4-48ac-80de-9ad45ae53741">Страна производства</button></p>
    <!--<h4>Отчеты:</h4>
    <p *ngIf="idTokenResult?.claims?.manager"><button type="button" mat-button routerLink="/ozon-export-report">Выгрузка в Ozon</button></p>
    -->
  </ng-container>
</ng-container>
<ng-template #loginButton>
  <p><button mat-button routerLink="/login" (click)="loginClick.emit()">Войти</button></p>
</ng-template>
