import { OzonNativeProductPriceUpdateResultError } from './ozon-native-product-price-update-result-error';
import { OzonV1ProductImportPricesResponse_ResultItem } from '@astrade/ozon-seller-api';

/**
 * Ozon product update price result.
 */
export class OzonNativeProductPriceUpdateResult {
  /**
   * Ozon Product ID.
   */
  productId: number;

  /**
   * AStrade product ID.
   */
  offerId: string;

  /**
   * Price update result.
   * Success or not.
   */
  updated: boolean;

  /**
   * Price update errors.
   * In case of updated === false.
   */
  errors: OzonNativeProductPriceUpdateResultError[];

  public static fromOzonApi(apiResult: OzonV1ProductImportPricesResponse_ResultItem): OzonNativeProductPriceUpdateResult {
    if (!apiResult) {
      return undefined;
    }

    return {
      productId: apiResult.product_id,
      offerId: apiResult.offer_id,
      updated: apiResult.updated,
      errors: apiResult.errors.map(error  => ({
        code: error.code,
        message: error.message
      }))
    }
  }
}
