/**
 * Ozon Error.
 */
export class OzonNativeProductPriceUpdateResultError {
  /**
   * Ozon Error code.
   */
  code: string;

  /**
   * Ozon Error description.
   */
  message: string;
}
