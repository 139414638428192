import { Component, Inject, OnInit } from '@angular/core';
import { CategoryTree } from '@astrade/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'astrade-category-select-dialog',
  template: `
    <mat-dialog-content style="height: 100vh">
      <astrade-categories-list [categories$]="categories$" (selectionChange)="onSelectionChange($event)"></astrade-categories-list>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button type="button" mat-button [mat-dialog-close]="{id: originalCategoryId, name: originalCategoryName}">Отмена</button>
      <button type="button" mat-button [mat-dialog-close]="{id: selectedCategory?.id, name: selectedCategory?.name}" cdkFocusInitial>Выбрать</button>
    </mat-dialog-actions>
  `
})
export class CategorySelectDialogComponent implements OnInit {

  categories$: Observable<CategoryTree[]>;
  originalCategoryId?: string;
  originalCategoryName?: string;

  selectedCategory?: CategoryTree;

  constructor(@Inject(MAT_DIALOG_DATA) data) {
    this.categories$ = data.categories$;
    this.originalCategoryId = data.categoryId;
    this.originalCategoryName = data.categoryName;
  }

  ngOnInit(): void {
  }

  onSelectionChange(category: CategoryTree): void {
    this.selectedCategory = category;
  }
}
