import { Component, Input, OnInit } from '@angular/core';
import { OzonExportTask, OzonNativeProduct, Product } from '@astrade/core';
import { OzonExportProductService } from './ozon-export-product.service';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'astrade-ozon-product-export',
  templateUrl: './ozon-export-product.component.html',
  styleUrls: ['./ozon-export-product.component.scss']
})
export class OzonExportProductComponent implements OnInit {

  @Input('product') set setProduct(product: Product) {
    this.product = product;
    console.log('Product:', product);
    this.ozonExportTask$.next(this.product?.ozonExportTask);
    this.ozonNativeProduct$.next(this.product?.ozonNativeProduct);
  }

  product: Product;

  exporting = false;
  checkingStatus = false;

  ozonExportTask$ = new BehaviorSubject<OzonExportTask>(undefined);
  ozonNativeProduct$ = new BehaviorSubject<OzonNativeProduct>(undefined);

  constructor(
    private readonly ozonProductExportService: OzonExportProductService,
    private readonly snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {

  }

  exportProduct(): void {
    if (!this.product?.id) {
      return;
    }

    this.exporting = true;
    this.ozonProductExportService.export(this.product.id).subscribe(result => {
      this.exporting = false;
      this.ozonExportTask$.next(result);
      console.log('Product export to ozon started.');
      this.snackBar.open('Выгрузка товара в ozon.ru начата', '', {
        duration: 5000
      });
    }, error => {
      this.exporting = false;
      console.error('Export to ozon', error);
      this.snackBar.open('Ошибочка вышла... ' + error?.toString() ?? '', 'Закрыть');
    });
  }

  checkExportProductStatus(): void {
    const ozonTaskId = this.ozonExportTask$.getValue()?.id;
    if (!ozonTaskId) {
      return;
    }

    this.checkingStatus = true;
    this.ozonProductExportService.checkStatus(ozonTaskId).subscribe(result => {
      this.checkingStatus = false;
      if (result) {
        this.ozonExportTask$.next(result.ozonExportTask);
        this.ozonNativeProduct$.next(result.ozonNativeProduct);
      } else {
        console.error('Check export status is null');
        this.snackBar.open('Ошибочка вышла... Пустой статус.', 'Закрыть');
      }
    }, error => {
      this.checkingStatus = false;
      console.error('Check export status', error);
      this.snackBar.open('Ошибочка вышла... ' + error?.toString() ?? '', 'Закрыть');
    });
  }
}
