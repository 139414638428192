import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { OzonNativeProductPriceUpdateResult, ProductPropertyMetadata } from '@astrade/core';
import { map } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/functions';
import {OzonExportProductPriceMessage} from '../../../../../../../apps/functions/src/app/messages/ozon-export-product-price.message';

@Injectable({
  providedIn: 'root'
})
export class PriceManagerService {

  private ozonExportProductPrice: (
    data: OzonExportProductPriceMessage
  ) => Observable<OzonNativeProductPriceUpdateResult> = this.angularFireFunctions.httpsCallable('ozonExportProductPrice');

  constructor(
    private readonly firestore: AngularFirestore,
    private readonly angularFireFunctions: AngularFireFunctions
  ) { }

  getAllProperties(): Observable<ProductPropertyMetadata[]> {
    return this.firestore.collection<ProductPropertyMetadata>('catalog-product-property', ref => ref.where(
      'catalogSectionId', '==', 'da8250f4-d58a-4ffe-b157-67a09ecbf7f5'
    ))
    .get()
    .pipe(
      map(value => value.docs.map(doc => doc.data() as unknown as ProductPropertyMetadata))
    );
  }

  updateOzonPrice(astradeProductId: string): Observable<OzonNativeProductPriceUpdateResult> {
    return this.ozonExportProductPrice({
      astradeProductId
    });
  }
}
