import { Component, ViewChild } from '@angular/core';
import { of } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { FormFieldAbstractComponent } from '../form-field-abstract/form-field-abstract.component';

@Component({
  selector: 'astrade-form-field-vat',
  template: `
    <mat-form-field fxFlex>
      <mat-label>{{label}}</mat-label>
      <input type="text" matInput [formControl]="control" [matAutocomplete]="auto" />
      <button type="button" [disabled]="control.value === ''" mat-icon-button matSuffix (click)="reset()"><mat-icon>cancel</mat-icon></button>
      <button type="button" *ngIf="(options$ | async).length > 0" mat-icon-button matSuffix (click)="openDropDownList()"><mat-icon>arrow_drop_down</mat-icon></button>
      <button type="button" *ngIf="hint" [matTooltip]="hint" matTooltipClass="hint" mat-icon-button matSuffix><mat-icon>help</mat-icon></button>
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="renderValue">
        <mat-option *ngFor="let option of options$ | async" [value]="option.value">
          <span>{{option.name}}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  `
})
export class FormFieldVatComponent extends FormFieldAbstractComponent {

  @ViewChild(MatAutocompleteTrigger, {read: MatAutocompleteTrigger}) inputAutoComplete: MatAutocompleteTrigger;

  options$ = of([{
    name: 'Отсутствует',
    value: 0
  }, {
    name: '10%',
    value: 10
  }, {
    name: '20%',
    value: 20
  }]);

  constructor() {
    super();
  }

  reset(): void {
    this.control.setValue('');
  }

  openDropDownList(): void {
    this.inputAutoComplete.openPanel();
  }

  renderValue(value: number | ''): string {
    if (typeof value === 'number') {
      return value === 0 ? 'Отсутствует' : value + '%'
    }

    return '';
  }
}
