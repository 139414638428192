import { Pipe, PipeTransform } from '@angular/core';

/**
 * Transforms URL of the original photo into resized small photo URL.
 */
@Pipe({
  name: 'photoSmall'
})
export class PhotoSmallPipe implements PipeTransform {
  transform(url: string | undefined, ...args: unknown[]): string {
    if (!url) {
      return url;
    }

    return `https://download.astrade.co.jp/gubka.shop/photos/${url}_250x250.${url.split('.').pop()}`;
  }
}
