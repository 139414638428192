<form fxFlexFill fxFlexOffset="20px" fxLayout="column" [formGroup]="form">
  <h2>Профиль</h2>
  <mat-form-field fxFlex>
    <mat-label>Фамилия</mat-label>
    <input matInput type="text" [formControl]="form.controls.lastName"/>
  </mat-form-field>
  <mat-form-field fxFlex>
    <mat-label>Имя</mat-label>
    <input matInput type="text" [formControl]="form.controls.firstName"/>
  </mat-form-field>
  <mat-form-field fxFlex>
    <mat-label>Отчество</mat-label>
    <input matInput type="text" [formControl]="form.controls.patronymic"/>
  </mat-form-field>
  <mat-form-field fxFlex>
    <mat-label>Телефон</mat-label>
    <input matInput type="text" [formControl]="form.controls.phone"/>
  </mat-form-field>
  <mat-form-field fxFlex>
    <mat-label>Индекс</mat-label>
    <input matInput type="text" [formControl]="form.controls.zip"/>
  </mat-form-field>
  <mat-form-field fxFlex>
    <mat-label>Регион</mat-label>
    <input matInput type="text" [formControl]="form.controls.region"/>
  </mat-form-field>
  <mat-form-field fxFlex>
    <mat-label>Населенный пункт</mat-label>
    <input matInput type="text" [formControl]="form.controls.city"/>
  </mat-form-field>
  <mat-form-field fxFlex>
    <mat-label>Улица</mat-label>
    <input matInput type="text" [formControl]="form.controls.street"/>
  </mat-form-field>
  <mat-form-field fxFlex>
    <mat-label>Дом</mat-label>
    <input matInput type="text" [formControl]="form.controls.house"/>
  </mat-form-field>
  <mat-form-field fxFlex>
    <mat-label>Корпус</mat-label>
    <input matInput type="text" [formControl]="form.controls.building"/>
  </mat-form-field>
  <mat-form-field fxFlex>
    <mat-label>Строение</mat-label>
    <input matInput type="text" [formControl]="form.controls.housing"/>
  </mat-form-field>
  <mat-form-field fxFlex>
    <mat-label>Квартира/Офис</mat-label>
    <input matInput type="text" [formControl]="form.controls.flat"/>
  </mat-form-field>

  <button type="button" mat-raised-button (click)="onSave()" [disabled]="saveInProgress">{{ saveInProgress ? 'Идет сохранение...' : 'Сохранить'}}</button>
</form>
