<div fxLayout="column" fxFlexFill>
  <div style="margin-top: 20px">
    <div fxLayout="row">
      <astrade-form-field-number fxFlex label="Оптовая цена" [control]="wholesalePriceFormControl"></astrade-form-field-number>
      <astrade-form-field-number fxFlex fxFlexOffset="2" label="Цена gubka.shop" onlyPositiveInteger="true" [control]="gubkaPriceFormControl"></astrade-form-field-number>
      <astrade-form-field-vat fxFlex fxFlexOffset="2" label="НДС" [control]="vatFormControl"></astrade-form-field-vat>
    </div>
    <div fxLayout="row">
      <astrade-form-field-number fxFlex onlyPositiveInteger="true" label="Цена для озон" [control]="ozonPriceFormControl"></astrade-form-field-number>
      <astrade-form-field-number fxFlex fxFlexOffset="2" onlyPositiveInteger="true" label="Наша минимальная цена" [control]="ourMinPriceFormControl"></astrade-form-field-number>
      <astrade-form-field-number fxFlex fxFlexOffset="2" onlyPositiveInteger="true" label="Минимальная цена парсера" [control]="parserMinPriceFormControl"></astrade-form-field-number>
      <astrade-form-field-price-type-for-ozon fxFlex fxFlexOffset="2" label="Выбранная цена для Ozon" [control]="selectedPriceTypeForOzonFormControl"></astrade-form-field-price-type-for-ozon>
    </div>
    <div style="margin-top: 5px; margin-bottom: 20px;">
      <b>Остаток:</b> {{ (componentState$ | async)?.fboStocks }} шт,
      <b>Комиссия:</b> {{ (componentState$ | async)?.fboCommissions?.amount + (componentState$ | async)?.fboCommissions?.deliveryAmount }}р
      ({{ (componentState$ | async)?.fboCommissions?.percent}}% / min {{ (componentState$ | async)?.fboCommissions?.minAmount }}р + {{ (componentState$ | async)?.fboCommissions?.deliveryAmount }}р),
      <b>Индекс цены:</b> {{ (componentState$ | async)?.priceIndex ? (componentState$ | async)?.priceIndex : 'Отсутствует' }},
      <b>Рекомендованная цена Ozon:</b> {{ (componentState$ | async)?.recommendedOzonPrice ? (componentState$ | async)?.recommendedOzonPrice : 'Отсутствует' }}
    </div>
    <div>
      <b>Прибыль: </b>
      <span>{{ getActualSellPrice() }} - {{ wholesalePriceFormControl.value }} - {{ (componentState$ | async)?.fboCommissions?.amount + (componentState$ | async)?.fboCommissions?.deliveryAmount }} = {{ (getActualSellPrice() - wholesalePriceFormControl.value - ((componentState$ | async)?.fboCommissions?.amount + (componentState$ | async)?.fboCommissions?.deliveryAmount)) | number:'1.0-2' }}р</span>
      <span *ngIf="!wholesalePriceFormControl.value else t1" style="color: darkred"> ! Не задана оптовая цена</span>
      <ng-template #t1><span *ngIf="!(componentState$ | async)?.fboCommissions?.amount" style="color: darkred"> ! Не задана комиссия Ozon</span></ng-template>
      <ng-template #t2><span *ngIf="!getActualSellPrice()" style="color: darkred"> ! Не задана цена продажи</span></ng-template>
    </div>
  </div>
  <mat-expansion-panel style="margin-top: 20px;">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <b>Цены конкурентов</b>
      </mat-panel-title>
      <mat-panel-description></mat-panel-description>
    </mat-expansion-panel-header>
    <astrade-price-monitoring
      style="height: 400px"
      fxFlex
      [productId$]="getProductId()"
    ></astrade-price-monitoring>
  </mat-expansion-panel>
  <button type="button" mat-raised-button style="margin-top: 20px; margin-bottom: 10px;" [disabled]="updatingOzonPrice" (click)="onOzonUpdatePriceButtonClick()">Обновить цену на Ozon</button>
</div>
