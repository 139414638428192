import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CategoryWithProductProperties, ProductPropertyMetadata } from '@astrade/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'astrade-category-edit-form',
  templateUrl: './category-edit-form.component.html',
  styleUrls: ['./category-edit-form.component.scss']
})
export class CategoryEditFormComponent implements OnInit {
  @Input('category') set setCategory(category: CategoryWithProductProperties) {
    this.category = category;
    this.form.get('id').setValue(category?.id);
    this.form.get('name').setValue(category?.name);
    this.selectedProductPropertiesArray = category?.productProperties;
    this.availableProductPropertiesArray = this.availableProductPropertiesArray?.filter(
      productProperty => !this.category?.productPropertyIds?.includes(productProperty.id)
    );
  }

  @Input('availableProductPropertiesArray') set setAvailableProductPropertiesArray(availableProductPropertiesArray: ProductPropertyMetadata[]) {
    this.availableProductPropertiesArray = availableProductPropertiesArray?.filter(
      productProperty => !this.category?.productPropertyIds?.includes(productProperty.id)
    );
  }

  @Output() saveClick = new EventEmitter<{id: string; name: string; productProperties: ProductPropertyMetadata[]}>();

  category: CategoryWithProductProperties;
  availableProductPropertiesArray: ProductPropertyMetadata[] = [];
  selectedProductPropertiesArray: ProductPropertyMetadata[] = [];

  form = new FormGroup({
    id: new FormControl(''),
    name: new FormControl('')
  });

  constructor() { }

  ngOnInit(): void {

  }

  onSave(): void {
    const id = this.form.get('id')?.value;
    if (!id) {
      return;
    }

    const name = this.form.get('name')?.value?.trim();
    if (!name) {
      return;
    }

    this.saveClick.emit({
      id,
      name,
      productProperties: this.selectedProductPropertiesArray
    })
  }

  drop(event: CdkDragDrop<ProductPropertyMetadata[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }
}
