import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ColDef, GridApi, GridReadyEvent, RowValueChangedEvent } from 'ag-grid-community';
import { ImageCellRendererComponent } from '../ag-grid/image-cell-renderer/image-cell-renderer.component';
import { CheckboxCellRendererComponent } from '../ag-grid/checkbox-cell-renderer/checkbox-cell-renderer.component';
import { CheckboxCellEditorComponent } from '../ag-grid/checkbox-cell-editor/checkbox-cell-editor.component';
import { FirebaseUser } from '@astrade/core';

@Component({
  selector: 'astrade-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  /**
   * List of Firebase users.
   */
  @Input() users: FirebaseUser[] = [];

  /**
   * User changed event.
   * Emits on grid row change event.
   */
  @Output() userChanged = new EventEmitter<FirebaseUser>();

  /**
   * ag-Grid API.
   */
  private gridApi: GridApi;

  /**
   * Quick search term.
   */
  searchQuery = new FormControl('');

  defaultColDef: ColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    lockPosition: true,
    resizable: true
  };

  columnTypes: Record<string, ColDef> = {
    nonEditable: {
      editable: false
    },
    userPhoto: {
      editable: false,
      cellRendererFramework: ImageCellRendererComponent,
      width: 75,
      filter: false,
      sortable: false,
      resizable: false
    },
    checkbox: {
      cellRendererFramework: CheckboxCellRendererComponent,
      editable: true,
      cellEditorFramework: CheckboxCellEditorComponent
    }
  };

  /**
   * Grid column definitions.
   */
  columnDefs: ColDef[] = [
    {
      headerName: 'Фото',
      field: 'photoURL',
      type: ['nonEditable', 'userPhoto']
    },
    {headerName: 'E-mail', field: 'email', type: 'nonEditable', minWidth: 250},
    {headerName: 'Имя', field: 'displayName', type: 'nonEditable', minWidth: 250},
    {
      headerName: 'Администратор',
      field: 'customClaims.admin',
      type: 'checkbox'
    }, {
      headerName: 'Менеджер',
      field: 'customClaims.manager',
      type: 'checkbox'
    }, {
      headerName: 'Дропшиппер',
      field: 'customClaims.dropshipper',
      type: 'checkbox'
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * ag-Grid ready event handler.
   * @param event ag-Grid ready event.
   */
  onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    this.gridApi.sizeColumnsToFit();
  }

  getRowNodeId(data: FirebaseUser): string {
    return data.uid;
  }

  onRowValueChanged(event: RowValueChangedEvent): void {
    const user: FirebaseUser = event.data;
    this.userChanged.emit(user);
  }
}
