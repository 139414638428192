import { createAction, props } from '@ngrx/store';
import { SupplierPriceListImportTask } from '@astrade/core';

export const getAll = createAction(
  '[Supplier Price List Import Tasks] Get All'
);

export const getAllSuccess = createAction(
  '[Supplier Price List Import Tasks] Get All Success',
  props<{supplierPriceListImportTasks: SupplierPriceListImportTask[]}>()
);

export const getAllError = createAction(
  '[Supplier Price List Import Tasks] Get All Error',
  props<{error: Error}>()
);
