import { _DEFAULT_CORE_ERROR_FACTORY, CoreErrorCode, CustomErrorData } from './errors';

export function _assert(
  assertion: unknown,
  errorCode: CoreErrorCode,
  customErrorData?: CustomErrorData
): asserts assertion {
  if (!assertion) {
    throw _DEFAULT_CORE_ERROR_FACTORY.create(errorCode, customErrorData);
  }
}
